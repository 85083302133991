import React from 'react';
import DashboardContext from 'context/dashboardContext';
import ReportDashboard from 'components/ReportDashboard';
import dashboardConfig from 'data/dashboardConfig/explorads';

const DashboardPage = () => {
    return (
        <DashboardContext.Provider value={dashboardConfig}>
            <ReportDashboard />
        </DashboardContext.Provider>
    );
};

export default DashboardPage;
