import React, { useContext } from 'react';
import _ from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { Col, Spin, Divider } from 'antd';
import SelectDimensions from 'components/SelectDimensions';
import {
    selectMetrics,
    selectDimensions,
    removeFilters,
    changeHidden,
    disableMetrics,
} from 'store/currentSelection/actionCreators';
import adsetIdMetrics from 'data/adsetMetrics';
import { HIDE_TYPE } from 'data/hideTypes';
import DashboardContext from 'context/dashboardContext';

import './index.css';

const BlockWrapper = (props) => {
    return (
        <div className="dashboard-dimensions">
            <Spin spinning={props.loading}>
                <div className="dashboard-dimensions-block">{props.children}</div>
            </Spin>
        </div>
    );
};

const DashboardDimensionsMetrics = () => {
    const dashboardContext = useContext(DashboardContext);
    const isDataFetching = useSelector((store) => store.dashboard.isDataFetching);
    const {
        dimensions: selectedDimensions,
        metrics: selectedMetrics,
        disabledMetrics,
        isLocked: locked,
    } = useSelector((store) => store.currentSelection);
    const dispatch = useDispatch();

    const onChangeDimensions = (dimensions = []) => {
        const unchecked = _.difference(selectedDimensions, dimensions);

        if (unchecked.length) {
            // dispatch(removeFilters({ fields: unchecked }));
            //если отключили adsetId нам также нужно отключить его метрики
            if (unchecked.includes('adsetId')) {
                const newMetrics = selectedMetrics.filter((m) => !adsetIdMetrics.includes(m));
                dispatch(disableMetrics(selectedMetrics.filter((m) => adsetIdMetrics.includes(m))));
                onChangeMetrics(newMetrics);
            }
        }

        // После добавления adsetId активируем метрики
        if (dimensions.includes('adsetId')) {
            dispatch(disableMetrics(adsetIdMetrics, false));
        }

        const toHide = unchecked.map((field) => {
            return {
                field,
                hideType: HIDE_TYPE.MAIN,
            };
        });
        const toShow = dimensions.map((field) => {
            return {
                field,
                hideType: HIDE_TYPE.MAIN,
            };
        });
        dispatch(changeHidden(toShow, toHide));
        dispatch(selectDimensions(dimensions));
    };

    const onChangeMetrics = (metrics = []) => {
        const unchecked = _.difference(selectedMetrics, metrics);
        if (unchecked.length) {
            dispatch(removeFilters({ fields: unchecked.filter((m) => !adsetIdMetrics.includes(m)) }));
        }
        const toHide = unchecked.map((field) => {
            return {
                field,
                hideType: HIDE_TYPE.MAIN,
            };
        });
        const toShow = metrics.map((field) => {
            return {
                field,
                hideType: HIDE_TYPE.MAIN,
            };
        });
        dispatch(changeHidden(toShow, toHide));
        dispatch(selectMetrics(metrics));
    };

    return (
        <>
            <Col xs={24} sm={24} md={24} lg={24}>
                <BlockWrapper loading={isDataFetching}>
                    <div className="multiselect-separator">
                        <Divider orientation="left">Dimensions</Divider>
                        <SelectDimensions
                            isLocked={locked}
                            dimensions={dashboardContext.dimensionsList}
                            onChange={onChangeDimensions}
                            selected={selectedDimensions}
                            disabled={disabledMetrics}
                        />
                    </div>

                    <div className="multiselect-separator">
                        <Divider orientation="left">Metrics</Divider>
                        <SelectDimensions
                            isLocked={locked}
                            dimensions={dashboardContext.metricsList}
                            onChange={onChangeMetrics}
                            selected={selectedMetrics}
                            disabled={disabledMetrics}
                        />
                    </div>
                </BlockWrapper>
            </Col>
        </>
    );
};

export default DashboardDimensionsMetrics;
