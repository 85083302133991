import _ from 'lodash';
import formatNumber from 'util/formatNumber';

const getCellValues = (val, metric = {}) => {
    const { prefix, postfix, precision, isNumber } = metric;

    const formatter = formatNumber({ prefix, postfix }, precision, true);
    const filterFormatter = formatNumber({}, 2, false);

    let value = val;
    let filterValue = val;
    if (isNumber) {
        value = formatter(val);
        filterValue = parseFloat(filterFormatter(val));
        if (_.isNaN(filterValue) || _.isNil(filterValue)) filterValue = false;
    } else if (_.isNil(val)) {
        value = '';
        filterValue = false;
    }

    return { value, filterValue };
};

export default getCellValues;
