import * as actions from 'store/actions';

const ACTION_HANDLERS = {
    [actions.CHANGE_TAGS_MODAL_VISIBILITY]: (state, { tagsModalVisible }) => {
        return {
            ...state,
            tagsModalVisible,
        };
    },
    [actions.SET_TAG_TARGET_ADSET_NAME]: (state, { targetAdset = {} }) => {
        return {
            ...state,
            targetAdset,
        };
    },
    [actions.SET_TAGS_LIST]: (state, { tags = [] }) => {
        return {
            ...state,
            tags,
        };
    },
};

const initialState = {
    tags: [],
    tagsModalVisible: false,
    targetAdset: {},
};

export default function (state = initialState, action) {
    const handler = ACTION_HANDLERS[action.type];

    return handler ? handler(state, action) : state;
}
