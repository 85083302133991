import React from 'react';
import { useSelector } from 'react-redux';
import { hasUserPermissions } from 'util/permissions';

/** render children components depending on user role */
const AccessByRole = (props) => {
    const { role, companies } = props;
    const { user } = useSelector((store) => store.auth);

    if (!hasUserPermissions(role, user, companies)) return null;

    return <>{props.children}</>;
};

export default AccessByRole;
