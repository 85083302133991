import dayjs from 'dayjs';
import moment from 'moment';
import * as actions from 'store/actions';

const ACTION_HANDLERS = {
    [actions.SET_TABOOLA_CAMPAIGN_ID]: (state, { selectedCampaignId }) => {
        return {
            ...state,
            selectedCampaignId,
        };
    },

    [actions.SET_TABOOLA_CAMPAIGN_SITE_DATA]: (state, { campaignSitesData }) => {
        return {
            ...state,
            campaignSitesData,
        };
    },
    [actions.SET_TABOOLA_CAMPAIGN_SITE_DATA_LOADING]: (state, { campaignSitesDataLoading }) => {
        return {
            ...state,
            campaignSitesDataLoading,
        };
    },
    [actions.SET_TABOOLA_CAMPAIGN_SITE_POPUP_VISIBLE]: (state) => {
        return {
            ...state,
            campaignSitesPopupVisible: !state.campaignSitesPopupVisible,
        };
    },

    [actions.SET_TABOOLA_CAMPAIGN_ADS_DATA]: (state, { campaignAdsData }) => {
        return {
            ...state,
            campaignAdsData,
        };
    },
    [actions.SET_TABOOLA_CAMPAIGN_ADS_DATA_LOADING]: (state, { campaignAdsDataLoading }) => {
        return {
            ...state,
            campaignAdsDataLoading,
        };
    },
    [actions.SET_TABOOLA_CAMPAIGN_ADS_POPUP_VISIBLE]: (state) => {
        return {
            ...state,
            campaignAdsPopupVisible: !state.campaignAdsPopupVisible,
        };
    },

    [actions.SET_TABOOLA_CAMPAIGN_PER_DAY_DATA]: (state, { campaignPerDayData }) => {
        return {
            ...state,
            campaignPerDayData,
        };
    },
    [actions.SET_TABOOLA_CAMPAIGN_PER_DAY_DATA_LOADING]: (state, { campaignPerDayDataLoading }) => {
        return {
            ...state,
            campaignPerDayDataLoading,
        };
    },
    [actions.SET_TABOOLA_CAMPAIGN_PER_DAY_POPUP_VISIBLE]: (state) => {
        return {
            ...state,
            campaignPerDayPopupVisible: !state.campaignPerDayPopupVisible,
        };
    },
    [actions.SET_TABOOLA_CAMPAIGN_SITE_DATA_RANGE]: (state, { newRange }) => {
        return {
            ...state,
            campaignSitesDataSelectedRange: newRange,
        };
    },
    [actions.SET_TABOOLA_CAMPAIGN_ADS_DATA_RANGE]: (state, { newRange }) => {
        return {
            ...state,
            campaignAdsDataSelectedRange: newRange,
        };
    },
    [actions.SET_TABOOLA_ALL_ACTIVITY_SITE_DATA_VISIBLE]: (state) => {
        return {
            ...state,
            allActivitySitesDataVisibile: !state.allActivitySitesDataVisibile,
        };
    },
    [actions.SET_TABOOLA_ALL_ACTIVITY_SITE_DATA_RANGE]: (state, { newRange }) => {
        return {
            ...state,
            allActivitySitesDataRange: newRange,
        };
    },
    [actions.SET_TABOOLA_ALL_ACTIVITY_SITE_DATA_DATA]: (state, { allActivitySitesData }) => {
        return {
            ...state,
            allActivitySitesData,
        };
    },
    [actions.SET_TABOOLA_ALL_ACTIVITY_SITE_DATA_LOADING]: (state, { allActivitySitesDataLoading }) => {
        return {
            ...state,
            allActivitySitesDataLoading,
        };
    },
};

const initialState = {
    selectedCampaignId: '',

    campaignSitesData: [],
    campaignSitesPopupVisible: false,
    campaignSitesDataLoading: false,
    campaignSitesDataSelectedRange: [moment().subtract(1, 'day'), moment().subtract(1, 'day')],

    campaignAdsData: [],
    campaignAdsPopupVisible: false,
    campaignAdsDataLoading: false,
    campaignAdsDataSelectedRange: [moment().subtract(1, 'day'), moment().subtract(1, 'day')],

    campaignPerDayData: [],
    campaignPerDayPopupVisible: false,
    campaignPerDayDataLoading: false,

    allActivitySitesData: [],
    allActivitySitesDataVisibile: false,
    allActivitySitesDataLoading: false,
    allActivitySitesDataRange: [moment().subtract(1, 'day'), moment().subtract(1, 'day')],
};

export default function (state = initialState, action) {
    const handler = ACTION_HANDLERS[action.type];

    return handler ? handler(state, action) : state;
}
