import * as actions from 'store/actions';
import { notification } from 'antd';
import TaboolaService from 'service/taboola.service';
import store from 'store/store';

export const setSelectedCampaignId = (selectedCampaignId) => {
    return {
        type: actions.SET_TABOOLA_CAMPAIGN_ID,
        selectedCampaignId,
    };
};
export const setCampaignSiteDataRange = (campaignSiteDataRange) => {
    return {
        type: actions.SET_TABOOLA_CAMPAIGN_SITE_DATA_RANGE,
        newRange: campaignSiteDataRange,
    };
};
export const setAllActivitySitesDataRange = (allActivitySitesDataRange) => {
    return {
        type: actions.SET_TABOOLA_ALL_ACTIVITY_SITE_DATA_RANGE,
        newRange: allActivitySitesDataRange,
    };
};
const setAllActivitySitesDataLoading = (allActivitySitesDataLoading) => {
    return {
        type: actions.SET_TABOOLA_ALL_ACTIVITY_SITE_DATA_LOADING,
        allActivitySitesDataLoading,
    };
};

export const setAllActivitySitesDataVisible = () => {
    return {
        type: actions.SET_TABOOLA_ALL_ACTIVITY_SITE_DATA_VISIBLE,
    };
};

export const setAllActivitySitesData = (allActivitySitesData) => {
    return {
        type: actions.SET_TABOOLA_ALL_ACTIVITY_SITE_DATA_DATA,
        allActivitySitesData,
    };
};

export const setCampaignAdsDateRange = (campaignAdsDataRAnge) => {
    return {
        type: actions.SET_TABOOLA_CAMPAIGN_ADS_DATA_RANGE,
        newRange: campaignAdsDataRAnge,
    };
};

const setCampaignSiteData = (campaignSitesData) => {
    return {
        type: actions.SET_TABOOLA_CAMPAIGN_SITE_DATA,
        campaignSitesData,
    };
};

const setCampaignSiteDataLoading = (campaignSitesDataLoading) => {
    return {
        type: actions.SET_TABOOLA_CAMPAIGN_SITE_DATA_LOADING,
        campaignSitesDataLoading,
    };
};

export const setCampaignSitePopupVisible = () => {
    return {
        type: actions.SET_TABOOLA_CAMPAIGN_SITE_POPUP_VISIBLE,
    };
};

const setCampaignAdsData = (campaignAdsData) => {
    return {
        type: actions.SET_TABOOLA_CAMPAIGN_ADS_DATA,
        campaignAdsData,
    };
};

const setCampaignAdsDataLoading = (campaignAdsDataLoading) => {
    return {
        type: actions.SET_TABOOLA_CAMPAIGN_ADS_DATA_LOADING,
        campaignAdsDataLoading,
    };
};

export const setCampaignAdsPopupVisible = () => {
    return {
        type: actions.SET_TABOOLA_CAMPAIGN_ADS_POPUP_VISIBLE,
    };
};

const setCampaignPerDayData = (campaignPerDayData) => {
    return {
        type: actions.SET_TABOOLA_CAMPAIGN_PER_DAY_DATA,
        campaignPerDayData,
    };
};

const setCampaignPerDayLoagind = (campaignPerDayDataLoading) => {
    return {
        type: actions.SET_TABOOLA_CAMPAIGN_PER_DAY_DATA_LOADING,
        campaignPerDayDataLoading,
    };
};

export const setCampaignPerDayPopupVisible = () => {
    return {
        type: actions.SET_TABOOLA_CAMPAIGN_PER_DAY_POPUP_VISIBLE,
    };
};

export const fetchCampaignSiteData = (campaignId, date) => async (dispatch) => {
    try {
        // Set data loading
        dispatch(setCampaignSiteDataLoading(true));
        // Request to server to get data by campaignId per site
        const campaignSitesData = await TaboolaService.fetchCampaignSitesData({ campaignId, date });

        console.log('Sites Data:', campaignSitesData);

        dispatch(setCampaignSiteData(campaignSitesData.data));
    } catch (err) {
        console.error('fetchCampaignSitesData error', err);
        notification.error({
            message: 'Failed to fetch campaign site data',
            description: err.message,
        });
    } finally {
        dispatch(setCampaignSiteDataLoading(false));
    }
};

export const fetchTaboolaCampaignLast7DaysData = (campaignId) => async (dispatch) => {
    try {
        // Show popup
        dispatch(setCampaignPerDayPopupVisible());
        // Set data loading
        dispatch(setCampaignPerDayLoagind(true));
        // Request to server to get data by campaignId per site
        const campaignPerDayData = await TaboolaService.fetchCampaignPerDayData({ campaignId });

        console.log('Sites Data:', campaignPerDayData);

        dispatch(setCampaignPerDayData(campaignPerDayData.data));
    } catch (error) {
        console.error('fetchCampaignPerDayData error', error);
        notification.error({
            message: 'Failed to fetch campaign per day data',
            description: error.message,
        });
    } finally {
        dispatch(setCampaignPerDayLoagind(false));
    }
};

export const fetchTaboolaCampaignAdsData = (campaignId, date) => async (dispatch) => {
    try {
        // Set data loading
        dispatch(setCampaignAdsDataLoading(true));
        // Show popup
        // dispatch(setCampaignAdsPopupVisible());
        // Request to server to get data by campaignId per site
        const campaignAdsdata = await TaboolaService.fetchCampaignAdsData({ campaignId, date });

        console.log('Ads Data:', campaignAdsdata);

        dispatch(setCampaignAdsData(campaignAdsdata.data));
    } catch (error) {
        console.error('fetchCampaignAdsData error', error);
        notification.error({
            message: 'Failed to fetch ads data',
            description: error.message,
        });
    } finally {
        dispatch(setCampaignAdsDataLoading(false));
    }
};

export const fetchTaboolaAllActivitySitesDataModal = () => async (dispatch) => {
    try {
        const date = store.getState().taboola.allActivitySitesDataRange;

        //set data loading
        dispatch(setAllActivitySitesDataLoading(true));
        //show popup
        const allActivitySitesData = await TaboolaService.fetchAllActivitySitesData({ date });

        console.log('All Activity Sites Data', allActivitySitesData);

        dispatch(setAllActivitySitesData(allActivitySitesData.data));
    } catch (error) {
        console.error('fetchTaboolaAllActivitySitesDataModal error', error);
        notification.error({
            message: 'Failed to fetch all activite sites data',
            description: error.message,
        });
    } finally {
        dispatch(setAllActivitySitesDataLoading(false));
    }
};
