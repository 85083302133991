import { applyMiddleware, compose, createStore as createReduxStore } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './rootReducer';

const createStore = (initialState = {}) => {
    const middleware = [thunk];
    const enhancers = [];
    let composeEnhancers = compose;

    // activate redux-devtools-extension for debugging https://github.com/zalmoxisus/redux-devtools-extension
    if (process.env.NODE_ENV === 'development') {
        if (typeof window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ === 'function') {
            composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
        }
    }

    const store = createReduxStore(
        rootReducer,
        initialState,
        composeEnhancers(applyMiddleware(...middleware), ...enhancers)
    );

    return store;
};

export default createStore;
