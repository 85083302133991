import React, { useState, useContext } from 'react';
import dayjs from 'dayjs';
import { Modal, Table, Select, DatePicker, Row, Col } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { changeAdsetHistoryVisibility } from 'store/userActions/actionCreators';
import DashboardContext from 'context/dashboardContext';

const { RangePicker } = DatePicker;

const ActionsTable = (props) => {
    const { data = [], timezone, loading } = props;

    const columns = [
        {
            title: 'Action',
            dataIndex: 'actionName',
            key: 'actionName',
        },
        {
            title: 'Action Details',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Changed By',
            dataIndex: 'user',
            key: 'user',
        },
        {
            title: 'Date and Time',
            dataIndex: 'actionTime',
            key: 'actionTime',
            render: (date) => dayjs.tz(date, 'EST').tz(timezone).format('YYYY-MM-DD HH:mm:ss'),
        },
    ];

    return <Table dataSource={data} rowKey={(row) => row.id} columns={columns} loading={loading} />;
};

const AdsetHistoryModal = () => {
    const dispatch = useDispatch();
    const dashboardContext = useContext(DashboardContext);
    const { adsetHistoryModalVisible, adsetHistory, loadingHistory } = useSelector((state) => state.userActions);

    const [activityTypeFilter, setActivityTypeFilter] = useState('');
    const [changedByFilter, setChangedByFilter] = useState('');
    const [dateRange, setDateRange] = useState([null, null]);

    // uniq values for filters
    const uniqueActivityTypes = Array.from(new Set(adsetHistory.map((item) => item.actionName)));
    const uniqueChangedBy = Array.from(new Set(adsetHistory.map((item) => item.user)));

    const filteredData = adsetHistory.filter((item) => {
        const matchesType = activityTypeFilter ? item.actionName === activityTypeFilter : true;
        const matchesUser = changedByFilter ? item.user === changedByFilter : true;
        const matchesDateRange =
            dateRange[0] && dateRange[1]
                ? dayjs(item.actionTime).isBetween(dateRange[0], dateRange[1], 'day', '[]')
                : true;
        return matchesType && matchesUser && matchesDateRange;
    });

    const onDateRangeChange = (dates) => {
        if (!dates || dates.length === 0) {
            setDateRange([null, null]);
        } else {
            setDateRange(dates);
        }
    };

    const onClose = () => {
        dispatch(changeAdsetHistoryVisibility());
    };

    return (
        <Modal title="Adset History" visible={adsetHistoryModalVisible} onCancel={onClose} footer={null} width={'85vw'}>
            <Row justify="space-between" style={{ marginBottom: 16 }}>
                <Col>
                    <Select
                        style={{ width: 200, marginRight: 10 }}
                        placeholder="Filter by Activity Type"
                        onChange={(value) => setActivityTypeFilter(value)}
                        allowClear
                    >
                        {uniqueActivityTypes.map((type) => (
                            <Select.Option key={type} value={type}>
                                {type}
                            </Select.Option>
                        ))}
                    </Select>
                    <Select
                        style={{ width: 200 }}
                        placeholder="Filter by Changed By"
                        onChange={(value) => setChangedByFilter(value)}
                        allowClear
                    >
                        {uniqueChangedBy.map((user) => (
                            <Select.Option key={user} value={user}>
                                {user}
                            </Select.Option>
                        ))}
                    </Select>
                </Col>
                <Col>
                    <RangePicker onChange={onDateRangeChange} style={{ width: 300 }} />
                </Col>
            </Row>
            <ActionsTable data={filteredData} timezone={dashboardContext.defaultTimezone} loading={loadingHistory} />
        </Modal>
    );
};

export default AdsetHistoryModal;
