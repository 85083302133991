import React from 'react';
import BulkSelectCell from './BulkSelectCell';

const useBulkSelectColumn = (isTotalRow = false) => {
    return {
        title: 'Bulk',
        className: 'result-action-column service-column',
        fixed: 'left',
        width: 50,
        render: (v, row) => (isTotalRow ? null : <BulkSelectCell row={row} />),
        isServiceColumn: true,
    };
};

export default useBulkSelectColumn;
