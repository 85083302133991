import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setModalVisible, saveNewTag, setAdsetTags, fetchTagsList } from 'store/tags/actionCreator';
import { CirclePicker } from 'react-color';
import _ from 'lodash';

import { Modal, Input, Typography, Tabs, Button, Space, Select, Tag, notification } from 'antd';

const { Text } = Typography;
const { TextArea } = Input;

const colorMapping = {
    '#c41d7f': 'magenta',
    '#cf1322': 'red',
    '#d4380d': 'volcano',
    '#d46b08': 'orange',
    '#d48806': 'gold',
    '#7cb305': 'lime',
    '#389e0d': 'green',
    '#08979c': 'cyan',
    '#096dd9': 'blue',
    '#1d39c4': 'geekblue',
    '#531dab': 'purple',
};

const NewTagForm = () => {
    const dispatch = useDispatch();

    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [tagColor, setTagColor] = useState('');

    const onNameChange = (event) => {
        setName(event.target.value);
    };

    const onDescriptionChange = (event) => {
        setDescription(event.target.value);
    };

    const onColorChange = (value) => {
        setTagColor(colorMapping[value.hex]);
    };

    const onSaveTag = () => {
        dispatch(saveNewTag(name.trim(), description.trim(), tagColor));
        setName('');
        setDescription('');
        setTagColor('');
    };

    const saveDisabled = !(name && description);

    return (
        <Space direction="vertical" style={{ width: '100%' }}>
            <Text>Tag name</Text>
            <Input value={name} onChange={onNameChange} />

            <CirclePicker width="100%" onChangeComplete={onColorChange} colors={Object.keys(colorMapping)} />

            <Text>Description</Text>
            <TextArea value={description} onChange={onDescriptionChange} />
            <Button disabled={saveDisabled} type="primary" onClick={onSaveTag}>
                Save
            </Button>
        </Space>
    );
};

const AdsetTagsForm = () => {
    const dispatch = useDispatch();
    const { tags, targetAdset = {} } = useSelector((store) => store.tags);
    const tagIds = _.keyBy(tags, 'id');
    const options = tags.map((tag) => ({ label: tag.name, value: tag.id, color: tag.color }));
    const [selectedTags, setSelectedTags] = useState(targetAdset?.tags?.map((tag) => tag.id) || []);
    // setSelectedTags(targetAdset?.tags?.map(tag => tag.id) || [])
    // useEffect(() => {
    //     setSelectedTags(targetAdset?.tags?.map(tag => tag.id) || [])
    // }, [targetAdset.tags])

    const tagRender = (option) => {
        return <Tag color={tagIds[option.value].color}>{option.label}</Tag>;
    };

    const onTagsChange = (value, options) => {
        let opt = options.filter((option) => option?.value);

        if (opt.length > 3) {
            notification.warning({
                message: "You can't select more than 3 tags",
            });

            opt = opt.slice(0, 3);
            setSelectedTags(opt);
        }

        setSelectedTags(opt);
    };

    const onTagsSave = () => {
        dispatch(setAdsetTags(selectedTags.map((tag) => tag.value)));
        dispatch(setModalVisible(false));
    };

    const onTagsRefresh = () => {
        dispatch(fetchTagsList());
    };

    return (
        <Space direction="vertical" style={{ width: '100%' }}>
            <Select
                style={{ width: '100%' }}
                mode="multiple"
                defaultValue={selectedTags}
                value={selectedTags}
                options={options}
                tagRender={tagRender}
                onChange={onTagsChange}
                optionFilterProp={'label'}
            />
            <Button type="primary" onClick={onTagsSave}>
                Save
            </Button>
        </Space>
    );
};

const TagsModal = () => {
    const { tagsModalVisible } = useSelector((store) => store.tags);
    const dispatch = useDispatch();

    const onModalClose = () => {
        dispatch(setModalVisible(false));
    };

    return (
        <Modal
            title={'Tags'}
            maskClosable={true}
            footer={null}
            visible={tagsModalVisible}
            onCancel={onModalClose}
            okText={'Save'}
        >
            <Tabs>
                <Tabs.TabPane tab="Add tag to adset" key="item-1">
                    <AdsetTagsForm />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Create new tag" key="item-2">
                    <NewTagForm />
                </Tabs.TabPane>
            </Tabs>
        </Modal>
    );
};

export default TagsModal;
