import React from 'react';
import { Select } from 'antd';

const intervals = [
    { label: 'None', value: 'none' },
    { label: 'Hour', value: 'hour' },
    { label: 'Day', value: 'day' },
    { label: 'Week', value: 'week' },
    { label: 'Month', value: 'month' },
];

const DateInterval = (props) => {
    const { value } = props;

    const onChangeInterval = (interval) => {
        props.onChange(interval);
    };

    return (
        <Select className="compass-select" style={{ width: '100%' }} value={value} onChange={onChangeInterval}>
            {intervals.map((opt) => (
                <Select.Option key={opt.value} value={opt.value}>
                    {opt.label}
                </Select.Option>
            ))}
        </Select>
    );
};

export default DateInterval;
