import request from 'util/request';
import dayjs from 'dayjs';

const formatDate = (date = {}) => {
    return {
        ...date,
        range: [
            dayjs(date[0]).startOf('day').format('YYYY-MM-DD HH:mm:ss'),
            dayjs(date[1]).endOf('day').format('YYYY-MM-DD HH:mm:ss'),
        ],
    };
};

const fetchCampaignSitesData = (params) => {
    const data = {
        date: formatDate(params.date),
        campaignId: params.campaignId,
    };

    return request.post('/taboola/sites-data', { data });
};

const fetchCampaignPerDayData = (params) => {
    const data = {
        //date: formatDate(params.date),
        campaignId: params.campaignId,
    };
    return request.post('/taboola/campaign-7days-data', { data });
};

const fetchCampaignAdsData = (params) => {
    const data = {
        date: formatDate(params.date),
        campaignId: params.campaignId,
    };
    return request.post('/taboola/campaign-ads-data', { data });
};

const fetchAllActivitySitesData = (params) => {
    const data = {
        date: formatDate(params.date),
    };
    return request.post('/taboola/all-activity-sites-data', { data });
};

export default {
    fetchCampaignSitesData,
    fetchCampaignPerDayData,
    fetchCampaignAdsData,
    fetchAllActivitySitesData,
};
