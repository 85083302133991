import React, { useContext } from 'react';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import moveArray from 'array-move';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import { Tooltip, Checkbox } from 'antd';
import { MenuOutlined } from '@ant-design/icons';

import { setDimensionOrder } from 'store/currentSelection/actionCreators';
import { HIDE_TYPE } from 'data/hideTypes';
import sortDimensions from 'util/sortDimensions';
import DashboardContext from 'context/dashboardContext';

import './index.css';

const OptionHide = (props) => {
    return (
        <Tooltip>
            <Checkbox checked={!props.hidden} onChange={props.onHide} />
        </Tooltip>
    );
};

const DragHandle = sortableHandle(() => <MenuOutlined style={{ cursor: 'pointer', color: '#999' }} />);

const SortableItem = sortableElement((props) => {
    const { hidden = {}, rowKey } = props;
    return (
        <div className="selected-item-draggable-row">
            <div className="item-row-eyes">
                {Object.keys(HIDE_TYPE).map((k) => {
                    const hideType = HIDE_TYPE[k];
                    const isRowHidden = !!_.get(hidden, [hideType, rowKey]);
                    return (
                        <OptionHide
                            key={k}
                            hideType={hideType}
                            onHide={() => props.onHide(rowKey, hideType)}
                            hidden={isRowHidden}
                        />
                    );
                })}
            </div>
            <div className="item-row-left">
                <div style={{ width: '100%', textAlign: 'left', paddingLeft: 10 }}>
                    <div className="item-row-label">{props.title}</div>
                </div>
                <div className="item-row-drag">
                    <DragHandle />
                </div>
            </div>
        </div>
    );
});

const SortableContainer = sortableContainer((props) => {
    const { options = [], onRemoveDimensions } = props;
    return (
        <div className="items-block">
            {options.map((row, ind) => {
                return (
                    <SortableItem
                        index={ind}
                        key={row.key}
                        rowKey={row.key}
                        hidden={props.hidden}
                        title={row.title}
                        onRemove={() => onRemoveDimensions([row])}
                        onHide={props.onHide}
                    />
                );
            })}
        </div>
    );
});

const CurrentSelectedDimensions = (props) => {
    const dashboardContext = useContext(DashboardContext);
    const {
        selectedDimensions = [],
        selectedMetrics = [],
        onChangeDimensions,
        onChangeMetrics,
        dimensionsOrder = [],
        onHide,
        hidden,
    } = props;
    const dispatch = useDispatch();

    const orderedDimensions = sortDimensions(selectedDimensions.concat(selectedMetrics), dimensionsOrder);

    const onRemoveDimensions = (rows = []) => {
        const removedDimensions = [];
        const removedMetrics = [];
        for (const row of rows) {
            if (row.isDimension) {
                removedDimensions.push(row.key);
            } else {
                removedMetrics.push(row.key);
            }
        }
        if (removedDimensions.length) {
            const newDimensions = _.difference(selectedDimensions, removedDimensions);
            onChangeDimensions(newDimensions);
        }
        if (removedMetrics.length) {
            const newMetrics = _.difference(selectedMetrics, removedMetrics);
            onChangeMetrics(newMetrics);
        }
    };

    const onSortEnd = ({ oldIndex, newIndex }) => {
        if (oldIndex !== newIndex) {
            const order = moveArray(orderedDimensions, oldIndex, newIndex);
            dispatch(setDimensionOrder(order));
        }
    };

    return (
        <div className="selected-dimensions">
            <div className="selected-dimensions-items">
                <div className="items-scroller">
                    <SortableContainer
                        useDragHandle
                        helperClass="dragging"
                        onSortEnd={onSortEnd}
                        onRemoveDimensions={onRemoveDimensions}
                        options={orderedDimensions.map((k) => dashboardContext.allDimensionsHash[k])}
                        onHide={onHide}
                        hidden={hidden}
                    />
                </div>
            </div>
        </div>
    );
};

export default CurrentSelectedDimensions;
