import _ from 'lodash';
import * as actions from 'store/actions';
import { HIDE_TYPE } from 'data/hideTypes';
import defaultDate from 'data/defaultDate';

const ACTION_HANDLERS = {
    [actions.DATE_SELECT]: (state, { date }) => {
        return { ...state, date: { ...state.date, ...date } };
    },
    [actions.DIMENSIONS_HIDE]: (state, { dimensionsToHide = {} }) => {
        const newHidden = _.cloneDeep(state.hidden);
        for (const hideType in dimensionsToHide) {
            const fieldsArr = _.uniq(dimensionsToHide[hideType]);
            for (const field of fieldsArr) {
                newHidden[hideType][field] = !newHidden[hideType][field];
            }
        }
        return {
            ...state,
            hidden: newHidden,
        };
    },
    [actions.CHANGE_HIDDEN]: (state, { toHide = [], toShow = [] }) => {
        const newHidden = _.cloneDeep(state.hidden);
        for (const itemToHide of toHide) {
            newHidden[itemToHide.hideType][itemToHide.field] = true;
        }
        for (const itemToShow of toShow) {
            newHidden[itemToShow.hideType][itemToShow.field] = false;
        }
        return {
            ...state,
            hidden: newHidden,
        };
    },
    [actions.DIMENSIONS_SELECT]: (state, { dimensions }) => {
        return {
            ...state,
            dimensions,
        };
    },
    [actions.METRICS_SELECT]: (state, { metrics = [] }) => {
        return {
            ...state,
            metrics,
        };
    },

    // disable dimensions/metrics
    [actions.METRICS_DISABLE]: (state, { metrics, disableStatus }) => {
        const disabledMetrics = {};
        for (const key of metrics) {
            disabledMetrics[key] = disableStatus;
        }
        return {
            ...state,
            disabledMetrics: {
                ...state.disabledMetrics,
                ...disabledMetrics,
            },
        };
    },

    // filters
    [actions.FILTER_ADD]: (state, { key, newFilter }) => {
        if (state.filters[key]) return state;
        // let newFilters = _.cloneDeep(state.filters);
        // newFilters = _.omitBy(newFilters, (r) => r.field === newFilter.field);
        // newFilters[key] = newFilter;
        return {
            ...state,
            filters: {
                ...state.filters,
                [key]: newFilter,
            },
        };
    },
    [actions.FILTERS_ADD]: (state, { filters = {} }) => {
        const newFilters = _.cloneDeep(state.filters);
        for (const k in filters) {
            const { field, items = [] } = filters[k];
            // find existing filter by field
            const existingKey = Object.keys(_.pickBy(newFilters, (r) => r.field === field))[0];
            // if field already exists
            if (existingKey) {
                // add items to existing filter
                newFilters[existingKey] = {
                    ...newFilters[existingKey],
                    items: _.get(newFilters[existingKey], 'items', []).concat(items),
                };
            } else {
                // add new filter
                newFilters[k] = filters[k];
            }
        }
        return {
            ...state,
            filters: newFilters,
        };
    },
    [actions.FILTER_CHANGE]: (state, { key, newFilter }) => {
        if (!state.filters[key]) return state;
        return {
            ...state,
            filters: {
                ...state.filters,
                [key]: newFilter,
            },
        };
    },
    [actions.FILTER_REMOVE]: (state, { keys = [], fields = [], all = false }) => {
        let filters = {};
        if (!all) {
            const oldFilters = state.filters;
            const fieldsSet = new Set(fields);
            const omitKeys = fieldsSet.size
                ? Object.keys(oldFilters).filter((k) => fieldsSet.has(oldFilters[k].field))
                : [];
            filters = _.omit(oldFilters, omitKeys.concat(keys));
        }
        return {
            ...state,
            filters,
        };
    },
    [actions.FILTER_REMOVE_EMPTY]: (state) => {
        let filters = {};
        const currentFilters = state.filters;
        for (const key in currentFilters) {
            if (currentFilters[key].field) {
                filters[key] = currentFilters[key];
            }
        }
        return {
            ...state,
            filters,
        };
    },

    // dimensions order
    [actions.DIMENSIONS_ORDER_SET]: (state, { order }) => {
        return {
            ...state,
            dimensionsOrder: order,
        };
    },

    // filter options
    [actions.FILTER_OPTIONS_SET]: (state, { filterOptions = {}, force = false }) => {
        const { filterOptions: prevFilterOptions } = state;
        const newFilterOptions = force
            ? filterOptions
            : Object.keys(filterOptions).reduce(
                  (acc, key) => ({
                      ...acc,
                      [key]: _.union(prevFilterOptions[key] || [], filterOptions[key] || []),
                  }),
                  {}
              );
        return {
            ...state,
            filterOptions: newFilterOptions,
        };
    },

    // PRESETS
    [actions.CHANGE_PRESETS_MODAL_VISIBILITY]: (state) => {
        return {
            ...state,
            presetModalVisible: !state.presetModalVisible,
        };
    },

    [actions.SET_PRESETS]: (state, { presets }) => {
        return {
            ...state,
            presets,
        };
    },

    [actions.SET_SELECTED_PRESET]: (state, { selectedPreset }) => {
        return {
            ...state,
            selectedPreset,
        };
    },

    [actions.SET_PRESETS_LOADING]: (state, { presetsLoading }) => {
        return {
            ...state,
            presetsLoading,
        };
    },

    [actions.LOCK_PRESET_EDITING]: (state, { isLocked }) => {
        return {
            ...state,
            isLocked,
        };
    },

    [actions.USER_COLUMNS_WIDTH_SET]: (state, { userColumnsWidth }) => {
        return {
            ...state,
            userColumnsWidth,
        };
    },
};

const initialState = {
    date: defaultDate,
    dimensions: [],
    metrics: [],
    hidden: {
        [HIDE_TYPE.MAIN]: {},
        [HIDE_TYPE.LASTDAYS]: {},
        [HIDE_TYPE.HOURLY]: {},
    },
    disabledMetrics: {},
    filters: {},
    dimensionsOrder: [],
    /** column widths changed by user, for these columns width will not be calculated dynamically */
    userColumnsWidth: {},

    filterOptions: {},

    presetModalVisible: false,
    presets: [],
    isLocked: false,
    selectedPreset: null,
    presetsLoading: false,
};

export default function (state = initialState, action) {
    const handler = ACTION_HANDLERS[action.type];

    return handler ? handler(state, action) : state;
}
