import _ from 'lodash';
import DashboardService from 'service/ko-dashboard.service';
import { HIDE_TYPE } from 'data/hideTypes';
import * as FIELD from 'data/fields';
import * as Calculate from 'util/calculations';

const format = {
    int: '#,##0',
    float: '#,##0.00',
    percentage: '#,##0.00%',
    currency: '$#,##0.00',
    currency3: '$#,##0.000',
};

const dimensionsList = [
    {
        key: 'date',
        title: 'Date',
        isDate: true,
        isDimension: true,
    },
    {
        key: FIELD.ACCOUNT_ID_KEY,
        title: 'AccountId',
        isDimension: true,
    },
    {
        key: FIELD.CAMPAIGN_ID_KEY,
        title: 'CampaignId',
        isDimension: true,
    },
    {
        key: FIELD.CAMPAIGN_NAME_KEY,
        title: 'CampaignName',
        isDimension: true,
    },
    {
        key: 'campaignCreationType',
        title: 'CampaignCreationType',
        isDimension: true,
    },
    {
        key: 'campaignCreationDate',
        title: 'CampaignCreationDate',
        isDimension: true,
        isDate: true,
    },
    {
        key: FIELD.ADSET_ID_KEY,
        title: 'AdsetId',
        isDimension: true,
    },
    {
        key: 'adsetName',
        title: 'AdsetName',
        isDimension: true,
    },
    {
        key: 'adId',
        title: 'AdId',
        isDimension: true,
    },
    {
        key: 'adName',
        title: 'AdName',
        isDimension: true,
    },
    {
        key: FIELD.SITE_KEY,
        title: 'Site',
        isDimension: true,
        isMultipleSelect: true,
    },
    {
        key: 'cm',
        title: 'CM',
        isDimension: true,
    },
    {
        key: 'placement',
        title: 'Placement',
        isDimension: true,
    },
    {
        key: 'article',
        title: 'Article',
        isDimension: true,
    },
    {
        key: 'vv',
        title: 'VV',
        isDimension: true,
    },
    {
        key: FIELD.BUSINESS_KEY,
        title: 'Business',
        isDimension: true,
        isMultipleSelect: true,
    },
    {
        key: 'geo',
        title: 'geo',
        isDimension: true,
    },
    {
        key: 'device',
        title: 'device',
        isDimension: true,
    },
    {
        key: 'os',
        title: 'os',
        isDimension: true,
    },
    {
        key: 'layoutNum',
        title: 'LayoutNum',
        isDimension: true,
    },
    {
        key: 'lang',
        title: 'Lang',
        isDimension: true,
        isMultipleSelect: true,
    },
    {
        key: 'tags',
        title: 'Tags',
        isDimension: true,
        isMultipleSelect: true,
    },
    {
        key: 'bidStrategy',
        title: 'Strategy',
        isDimension: true,
        isMultipleSelect: true,
    },
];

const metricsList = [
    {
        key: FIELD.CLICKS_KEY,
        title: 'Clicks',
        fullTitle: 'Clicks',
        precision: 0,
        isNumber: true,
        showTotal: true,
        format: format.int,
    },
    {
        key: FIELD.CLICKS_1H_KEY,
        title: 'clicksLastHour',
        fullTitle: 'clicksLastHour',
        precision: 0,
        isNumber: true,
        showTotal: true,
        format: format.int,
    },
    {
        key: FIELD.CLICKS_3H_KEY,
        title: 'clicksLast3Hours',
        fullTitle: 'clicksLast3Hours',
        precision: 0,
        isNumber: true,
        showTotal: true,
        format: format.int,
    },
    {
        key: FIELD.SESSIONS_KEY,
        title: 'Sessions',
        fullTitle: 'Sessions',
        precision: 0,
        isNumber: true,
        showTotal: true,
        format: format.int,
    },
    {
        key: FIELD.SESSIONS_1H_KEY,
        title: 'sessionsLastHour',
        fullTitle: 'sessionsLastHour',
        precision: 0,
        isNumber: true,
        showTotal: true,
        format: format.int,
    },
    {
        key: FIELD.SESSIONS_3H_KEY,
        title: 'sessionsLast3Hours',
        fullTitle: 'sessionsLast3Hours',
        precision: 0,
        isNumber: true,
        showTotal: true,
        format: format.int,
    },
    {
        key: 'sessionsLast15min',
        title: 'Users',
        fullTitle: 'SessionsLast15min',
        precision: 0,
        isNumber: true,
        showTotal: true,
        format: format.int,
    },
    {
        key: 'firstPageSessions',
        title: 'FirstPageSessions',
        fullTitle: 'FirstPageSessions',
        precision: 0,
        isNumber: true,
        showTotal: true,
        format: format.int,
    },
    {
        key: FIELD.IOS_SESSIONS_KEY,
        title: 'IosSessions',
        fullTitle: 'IosSessions',
        precision: 0,
        isNumber: true,
        showTotal: true,
        format: format.int,
    },
    {
        key: 'iosPercentage',
        title: '%-IOS',
        fullTitle: 'IOSPerc',
        postfix: '%',
        precision: 0,
        isNumber: true,
        showTotal: true,
        total: (row) => Calculate.iosPercentage(row[FIELD.IOS_SESSIONS_KEY], row[FIELD.SESSIONS_KEY]),
        dependsOn: [FIELD.IOS_SESSIONS_KEY, FIELD.SESSIONS_KEY],
        format: format.percentage,
        clintSideFiltered: true,
    },
    {
        key: FIELD.NON_US_SESSIONS_KEY,
        title: 'NonUSSessions',
        fullTitle: 'NonUSSessions',
        precision: 0,
        isNumber: true,
        showTotal: true,
        format: format.int,
    },
    {
        key: FIELD.MOBILE_SESSIONS_KEY,
        title: 'MobileSessions',
        fullTitle: 'MobileSessions',
        precision: 0,
        isNumber: true,
        showTotal: true,
        format: format.int,
    },
    {
        key: FIELD.IMPRESSIONS_KEY,
        title: 'Impressions',
        fullTitle: 'Impressions',
        precision: 0,
        isNumber: true,
        showTotal: true,
        format: format.int,
    },
    {
        key: FIELD.DISPLAY_IMPS_KEY,
        title: 'DisplayImpressions',
        fullTitle: 'DisplayImpressions',
        precision: 0,
        isNumber: true,
        showTotal: true,
        format: format.int,
    },
    {
        key: 'videoImpressions',
        title: 'VideoImpressions',
        fullTitle: 'VideoImpressions',
        precision: 0,
        isNumber: true,
        showTotal: true,
        format: format.int,
    },
    {
        key: 'dfpImpressions',
        title: 'DFPImpressions',
        fullTitle: 'DFPImpressions',
        precision: 0,
        isNumber: true,
        showTotal: true,
        format: format.int,
    },
    {
        key: 'fbConversions',
        title: 'FBConv',
        fullTitle: 'FBConversions',
        precision: 0,
        isNumber: true,
        showTotal: true,
        format: format.int,
    },
    {
        key: FIELD.SPEND_KEY,
        title: 'Cost',
        fullTitle: 'Cost',
        prefix: '$',
        precision: 2,
        isNumber: true,
        showTotal: true,
        format: format.currency,
    },
    {
        key: 'displayRevenue',
        title: 'D-Rev',
        fullTitle: 'DisplayRevenue',
        prefix: '$',
        precision: 2,
        isNumber: true,
        showTotal: true,
        format: format.currency,
    },
    {
        key: 'videoRevenue',
        title: 'V-Rev',
        fullTitle: 'VideoRevenue',
        prefix: '$',
        precision: 2,
        isNumber: true,
        showTotal: true,
        format: format.currency,
    },
    {
        key: 'dfpRevenue',
        title: 'DFPRevenue',
        fullTitle: 'DFPRevenue',
        prefix: '$',
        precision: 2,
        isNumber: true,
        showTotal: true,
        format: format.currency,
    },
    {
        key: 'displayMinRevenue',
        title: 'DisplayMinRevenue',
        fullTitle: 'DisplayMinRevenue',
        prefix: '$',
        precision: 2,
        isNumber: true,
        showTotal: true,
        format: format.currency,
    },
    {
        key: 'displayPredictedRevenue',
        title: 'DisplayPredictedRevenue',
        fullTitle: 'DisplayPredictedRevenue',
        prefix: '$',
        precision: 2,
        isNumber: true,
        showTotal: true,
        format: format.currency,
    },
    {
        key: FIELD.PPS_KEY,
        title: 'Pages in session',
        fullTitle: 'PPS',
        precision: 2,
        isNumber: true,
        showTotal: true,
        format: format.float,
    },
    {
        key: 'ppsCalc',
        title: 'PPS',
        fullTitle: 'PPS',
        precision: 2,
        isNumber: true,
        showTotal: true,
        total: (row) => Calculate.pps(row[FIELD.PPS_KEY], row[FIELD.SESSIONS_REV_KEY]),
        dependsOn: [FIELD.PPS_KEY, FIELD.SESSIONS_REV_KEY],
        format: format.float,
        clintSideFiltered: true,
    },
    {
        key: 'cpr',
        title: 'CPR',
        fullTitle: 'CPR',
        precision: 2,
        isNumber: true,
        showTotal: true,
        total: (row) => Calculate.cpr(row[FIELD.SPEND_KEY], row['fbConversions']),
        format: format.float,
        clintSideFiltered: true,
    },
    {
        key: 'budgetUsage',
        title: 'Budget Usage',
        fullTitle: 'Budget Usage',
        isNumber: true,
        postfix: '%',
        precision: 0,
        format: format.percentage,
        clintSideFiltered: true,
    },
    {
        key: 'impsPerSession',
        title: 'IPS',
        fullTitle: 'IPS',
        precision: 2,
        isNumber: true,
        showTotal: true,
        total: (row) => Calculate.impsPerSession(row[FIELD.DISPLAY_IMPS_KEY], row[FIELD.SESSIONS_KEY]),
        dependsOn: [FIELD.DISPLAY_IMPS_KEY, FIELD.SESSIONS_KEY],
        format: format.float,
        clintSideFiltered: true,
    },
    {
        key: 'conversions',
        title: 'Conv',
        fullTitle: 'Conversions',
        precision: 0,
        isNumber: true,
        showTotal: true,
        format: format.int,
    },
    {
        key: FIELD.SESSIONS_REV_KEY,
        title: 'sessionsWithRevenue',
        fullTitle: 'sessionsWithRevenue',
        precision: 0,
        isNumber: true,
        showTotal: true,
        format: format.int,
    },
    {
        key: FIELD.REVENUE_KEY,
        title: 'REV',
        fullTitle: 'Revenue',
        prefix: '$',
        precision: 2,
        isNumber: true,
        showTotal: true,
        format: format.currency,
    },
    {
        key: FIELD.ROI_KEY,
        title: 'ROI',
        fullTitle: 'ROI',
        postfix: '%',
        precision: 0,
        isNumber: true,
        showTotal: true,
        total: (row) => Calculate.roi(row[FIELD.REVENUE_KEY], row[FIELD.SPEND_KEY]),
        dependsOn: [FIELD.REVENUE_KEY, FIELD.SPEND_KEY],
        format: format.percentage,
        clintSideFiltered: true,
    },
    {
        key: FIELD.ROI_1H_KEY,
        title: 'Roi-1H',
        fullTitle: 'RoiLastHour',
        postfix: '%',
        precision: 0,
        isNumber: true,
        showTotal: true,
        total: (row) => Calculate.roi(row[FIELD.REV_1H_KEY], row[FIELD.SPEND_1H_KEY]),
        dependsOn: [FIELD.REV_1H_KEY, FIELD.SPEND_1H_KEY],
        format: format.percentage,
        clintSideFiltered: true,
    },
    {
        key: FIELD.REV_3H_KEY,
        title: 'REV-3H',
        fullTitle: 'revenueLast3Hours',
        prefix: '$',
        precision: 2,
        isNumber: true,
        showTotal: true,
        format: format.currency,
    },
    {
        key: FIELD.REV_1H_KEY,
        title: 'REV-1H',
        fullTitle: 'revenueLastHour',
        prefix: '$',
        precision: 2,
        isNumber: true,
        showTotal: true,
        format: format.currency,
    },
    {
        key: FIELD.SPEND_3H_KEY,
        title: 'spendLast3Hours',
        fullTitle: 'spendLast3Hours',
        prefix: '$',
        precision: 2,
        isNumber: true,
        showTotal: true,
        format: format.currency,
    },
    {
        key: FIELD.SPEND_1H_KEY,
        title: 'spendLastHour',
        fullTitle: 'spendLastHour',
        prefix: '$',
        precision: 2,
        isNumber: true,
        showTotal: true,
        format: format.currency,
    },
    {
        key: FIELD.ROI_3H_KEY,
        title: 'Roi-3H',
        fullTitle: 'RoiLast3Hours',
        postfix: '%',
        precision: 0,
        isNumber: true,
        showTotal: true,
        total: (row) => Calculate.roi(row[FIELD.REV_3H_KEY], row[FIELD.SPEND_3H_KEY]),
        dependsOn: [FIELD.REV_3H_KEY, FIELD.SPEND_3H_KEY],
        format: format.percentage,
        clintSideFiltered: true,
    },
    {
        key: FIELD.REV_YESTERDAY_KEY,
        title: 'revenueYesterday',
        fullTitle: 'revenueYesterday',
        prefix: '$',
        precision: 2,
        isNumber: true,
        showTotal: true,
        format: format.currency,
    },
    {
        key: FIELD.SPEND_YESTERDAY_KEY,
        title: 'spendYesterday',
        fullTitle: 'spendYesterday',
        prefix: '$',
        precision: 2,
        isNumber: true,
        showTotal: true,
        format: format.currency,
    },
    {
        key: FIELD.ROI_YESTERDAY_KEY,
        title: 'Roi-Yes',
        fullTitle: 'RoiYesterday',
        postfix: '%',
        precision: 0,
        isNumber: true,
        showTotal: true,
        total: (row) => Calculate.roi(row[FIELD.REV_YESTERDAY_KEY], row[FIELD.SPEND_YESTERDAY_KEY]),
        dependsOn: [FIELD.REV_YESTERDAY_KEY, FIELD.SPEND_YESTERDAY_KEY],
        format: format.percentage,
        clintSideFiltered: true,
    },
    {
        key: 'missingClicks',
        title: 'Missing clicks%',
        fullTitle: 'Missing Clicks',
        postfix: '%',
        precision: 2,
        isNumber: true,
        showTotal: false,
        format: format.percentage,
    },
    {
        key: 'ctr',
        title: 'CTR',
        fullTitle: 'CTR',
        postfix: '%',
        precision: 0,
        isNumber: true,
        showTotal: true,
        total: (row) => Calculate.ctr(row[FIELD.CLICKS_KEY], row[FIELD.IMPRESSIONS_KEY]),
        dependsOn: [FIELD.CLICKS_KEY, FIELD.IMPRESSIONS_KEY],
        format: format.percentage,
        clintSideFiltered: true,
    },
    {
        key: 'cpc',
        title: 'CPC',
        fullTitle: 'CPC',
        prefix: '$',
        precision: 3,
        isNumber: true,
        showTotal: true,
        total: (row) => Calculate.cpc(row[FIELD.SPEND_KEY], row[FIELD.CLICKS_KEY]),
        dependsOn: [FIELD.SPEND_KEY, FIELD.CLICKS_KEY],
        format: format.currency,
        clintSideFiltered: true,
    },
    {
        key: 'cpcLastHour',
        title: 'CPC-1H',
        fullTitle: 'cpcLastHour',
        prefix: '$',
        precision: 3,
        isNumber: true,
        showTotal: true,
        total: (row) => Calculate.cpc(row[FIELD.SPEND_1H_KEY], row[FIELD.CLICKS_1H_KEY]),
        dependsOn: [FIELD.SPEND_1H_KEY, FIELD.CLICKS_1H_KEY],
        format: format.currency,
        clintSideFiltered: true,
    },
    {
        key: 'cpcLast3Hours',
        title: 'CPC-3H',
        fullTitle: 'CPCLast3Hours',
        prefix: '$',
        precision: 3,
        isNumber: true,
        showTotal: true,
        total: (row) => Calculate.cpc(row[FIELD.SPEND_3H_KEY], row[FIELD.CLICKS_3H_KEY]),
        dependsOn: [FIELD.SPEND_3H_KEY, FIELD.CLICKS_3H_KEY],
        format: format.currency,
        clintSideFiltered: true,
    },
    {
        key: 'rps',
        title: 'RPS',
        fullTitle: 'RPS',
        prefix: '$',
        precision: 3,
        isNumber: true,
        showTotal: true,
        total: (row) => Calculate.rps(row[FIELD.REVENUE_KEY], row[FIELD.SESSIONS_KEY]),
        dependsOn: [FIELD.REVENUE_KEY, FIELD.SESSIONS_KEY],
        format: format.currency,
        clintSideFiltered: true,
    },
    {
        key: 'rpsLastHour',
        title: 'RPS-1H',
        fullTitle: 'rpsLastHour',
        prefix: '$',
        precision: 3,
        isNumber: true,
        showTotal: true,
        total: (row) => Calculate.rps(row[FIELD.REV_1H_KEY], row[FIELD.SESSIONS_1H_KEY]),
        dependsOn: [FIELD.REV_1H_KEY, FIELD.SESSIONS_1H_KEY],
        format: format.currency,
        clintSideFiltered: true,
    },
    {
        key: 'rpsLast3Hours',
        title: 'RPS-3H',
        fullTitle: 'RPSLast3Hours',
        prefix: '$',
        precision: 3,
        isNumber: true,
        showTotal: true,
        total: (row) => Calculate.rps(row[FIELD.REV_3H_KEY], row[FIELD.SESSIONS_3H_KEY]),
        dependsOn: [FIELD.REV_3H_KEY, FIELD.SESSIONS_3H_KEY],
        format: format.currency,
        clintSideFiltered: true,
    },
    {
        key: 'rpm',
        title: 'RPM',
        fullTitle: 'RPM',
        precision: 3,
        isNumber: true,
        showTotal: false,
        dependsOn: [FIELD.REVENUE_KEY, FIELD.IMPRESSIONS_KEY],
        format: format.float,
        clintSideFiltered: true,
    },
    {
        key: 'nonUSPercentage',
        title: 'Non US%',
        fillTime: 'Non US Sessions %',
        precision: 0,
        postfix: '%',
        isNumber: true,
        format: format.percentage,
        dependsOn: [FIELD.SESSIONS_KEY, FIELD.NON_US_SESSIONS_KEY],
        showTotal: true,
        total: (row) => Calculate.nonUSPercentage(row[FIELD.NON_US_SESSIONS_KEY], row[FIELD.SESSIONS_KEY]),
        clintSideFiltered: true,
    },
    {
        key: 'mobilePercentage',
        title: 'Mobile %',
        fillTime: 'Mobile %',
        precision: 0,
        postfix: '%',
        isNumber: true,
        format: format.percentage,
        dependsOn: [FIELD.SESSIONS_KEY, FIELD.MOBILE_SESSIONS_KEY],
        showTotal: true,
        total: (row) => Calculate.nonUSPercentage(row[FIELD.MOBILE_SESSIONS_KEY], row[FIELD.SESSIONS_KEY]),
        clintSideFiltered: true,
    },

    // CVR - conversion cpc/cpr in percentage p4
    {
        key: 'cvrContent',
        title: 'CVR %',
        fillTime: 'CVR %',
        precision: 0,
        postfix: '%',
        isNumber: true,
        format: format.percentage,
        dependsOn: [FIELD.FB_CONVERSIONS_KEY, FIELD.CLICKS_KEY],
        showTotal: true,
        total: (row) => Calculate.cvrContent(row[FIELD.FB_CONVERSIONS_KEY], row[FIELD.CLICKS_KEY]),
        clintSideFiltered: true,
    },

    //IPP - impressions per page p4
    {
        key: 'ipp',
        title: 'IPP',
        fullTitle: 'IPP',
        precision: 2,
        isNumber: true,
        showTotal: true,
        total: (row) => Calculate.ipp(row[FIELD.DISPLAY_IMPS_KEY], row[FIELD.PAGE_VIEWS_KEY]),
        dependsOn: [FIELD.DISPLAY_IMPS_KEY, FIELD.PAGE_VIEWS_KEY],
        format: format.float,
        clintSideFiltered: true,
    },

    //FB CPM metric Facebook Cost Per Mille (FB CPM) p4
    {
        key: 'fbCpm',
        title: 'Fb-Cpm',
        fullTitle: 'FB CPM',
        precision: 2,
        isNumber: true,
        showTotal: true,
        prefix: '$',
        total: (row) => Calculate.fbCpm(row[FIELD.SPEND_KEY], row[FIELD.IMPRESSIONS_KEY]),
        dependsOn: [FIELD.SPEND_KEY, FIELD.IMPRESSIONS_KEY],
        format: format.currency,
        clintSideFiltered: true,
    },
    {
        key: FIELD.PAGE_VIEWS_KEY,
        title: 'PageViews',
        fullTitle: 'PageViews',
        precision: 0,
        isNumber: true,
        showTotal: true,
        format: format.int,
    },
    {
        key: 'profit',
        title: 'Profit',
        fullTitle: 'Profit',
        prefix: '$',
        precision: 2,
        isNumber: true,
        showTotal: true,
        format: format.currency,
    },

    //Profit Yesterday p4
    {
        key: 'profitYesterday',
        title: 'Profit-Yes',
        fullTitle: 'Profit Yesterday',
        prefix: '$',
        precision: 2,
        isNumber: true,
        showTotal: true,
        format: format.currency,
    },

    {
        key: 'hbDisplayRevenue',
        title: 'HB-Rev',
        fullTitle: 'HBRevenue',
        prefix: '$',
        precision: 2,
        isNumber: true,
        showTotal: true,
        format: format.currency,
    },
    {
        key: 'hbDisplayImpression',
        title: 'HBDisplayImpression',
        fullTitle: 'HBDisplayImpression',
        prefix: '$',
        precision: 2,
        isNumber: true,
        showTotal: true,
        format: format.currency,
    },
    {
        key: 'dfpMinRevenue',
        title: 'Adx-Min',
        fullTitle: 'AdxMin',
        prefix: '$',
        precision: 2,
        isNumber: true,
        showTotal: true,
        format: format.currency,
    },
    {
        key: 'dfpAllignedRevenue',
        title: 'Adx-All',
        fullTitle: 'AdxAlligned',
        prefix: '$',
        precision: 2,
        isNumber: true,
        showTotal: true,
        format: format.currency,
    },
    {
        key: 'predictedDFPRevenue',
        title: 'Adx-Pred',
        fullTitle: 'AdxPredictedRevenue',
        prefix: '$',
        precision: 2,
        isNumber: true,
        showTotal: true,
        format: format.currency,
    },
    {
        key: 'predictedDFPRevenue1',
        title: 'Adx-Pred1',
        fullTitle: 'AdxPredictedRevenueNew',
        prefix: '$',
        precision: 2,
        isNumber: true,
        showTotal: true,
        format: format.currency,
    },

    {
        key: FIELD.BUDGET_KEY,
        title: 'Budget',
        fullTitle: 'DailyBudget',
        prefix: '$',
        precision: 2,
        isNumber: true,
        format: format.currency,
    },
    {
        key: FIELD.BID_KEY,
        title: 'Bid',
        fullTitle: 'BidAmount',
        prefix: '$',
        precision: 2,
        isNumber: true,
        format: format.currency,
    },
    {
        key: 'campaignEffectiveStatus',
        title: 'CampaignEffectiveStatus',
    },
    {
        key: FIELD.ADSET_STATUS_KEY,
        title: 'Status',
        isMultipleSelect: true,
    },
    // {
    //     key: 'adStatus',
    //     'title': 'AdStatus',
    // },
    {
        key: 'lastUpdate',
        title: 'Last Update',
        isDate: true,
    },
];

export default {
    name: 'KO',
    defaultTimezone: 'EST',
    service: DashboardService,

    metricsList,
    dimensionsList,
    allDimensionsList: dimensionsList.concat(metricsList),
    allDimensionsHash: _.keyBy(dimensionsList.concat(metricsList), 'key'),
    metricsListHash: _.keyBy(metricsList, 'key'),
    dimensionsListHash: _.keyBy(dimensionsList, 'key'),

    selectedDimensions: [
        FIELD.CAMPAIGN_ID_KEY,
        FIELD.ADSET_ID_KEY,
        'adsetName',
        'campaignCreationDate',
        'accountId',
        'site',
        'cm',
        'article',
        'vv',
        FIELD.BUSINESS_KEY,
        'lang',
        'bidStrategy',
    ],
    selectedMetrics: [
        'spend',
        'spendYesterday',
        'spendLast3Hours',
        'spendLastHour',
        'revenue',
        'revenueYesterday',
        'revenueLast3Hours',
        'revenueLastHour',
        'roi',
        'roiLastHour',
        'roiLast3Hours',
        'roiYesterday',
        'clicks',
        'clicksLastHour',
        'clicksLast3Hours',
        'sessions',
        'sessionsWithRevenue',
        'sessionsLastHour',
        'sessionsLast3Hours',
        'sessionsLast15min',
        'ctr',
        'fbConversions',
        'conversions',
        'cpc',
        'cpr',
        'cpcLastHour',
        'cpcLast3Hours',
        'rps',
        'rpsLastHour',
        'rpsLast3Hours',
        'rpm',
        'nonUSPercentage',
        'mobilePercentage',
        'profit',
        'missingClicks',
        'pps',
        'ppsCalc',
        'impsPerSession',
        'adsetEffectiveStatus',
        FIELD.BUDGET_KEY,
        FIELD.BID_KEY,
        'displayRevenue',
        'videoRevenue',
        'hbDisplayRevenue',
        'dfpAllignedRevenue',
        'predictedDFPRevenue',
        'predictedDFPRevenue1',
        'dfpMinRevenue',
        'iosPercentage',
        FIELD.DISPLAY_IMPS_KEY,
        FIELD.LAST_UPDATE_KEY,
    ],
    hidden: {
        [HIDE_TYPE.MAIN]: {
            [FIELD.CAMPAIGN_ID_KEY]: true,
            campaignCreationDate: true,
            [FIELD.ADSET_ID_KEY]: true,
            accountId: true,
            site: true,
            cm: true,
            article: true,
            vv: true,
            [FIELD.BUSINESS_KEY]: true,
            [FIELD.DISPLAY_IMPS_KEY]: true,
            spendYesterday: true,
            spendLast3Hours: true,
            spendLastHour: true,
            revenueYesterday: true,
            revenueLastHour: true,
            revenueLast3Hours: true,
            clicksLastHour: true,
            clicksLast3Hours: true,
            clicks: true,
            displayRevenue: true,
            videoRevenue: true,
            hbDisplayRevenue: true,
            dfpAllignedRevenue: true,
            predictedDFPRevenue: true,
            predictedDFPRevenue1: true,
            dfpMinRevenue: true,
            conversions: true,
            sessionsLastHour: true,
            sessionsLast3Hours: true,
            sessionsWithRevenue: true,
            pps: true,
            lang: true,
        },
    },
    dimensionsOrder: [
        'adsetEffectiveStatus',
        'adsetName',
        'iosPercentage',
        'nonUSPercentage',
        'mobilePercentage',
        'sessionsLast15min',
        'spend',
        'revenue',
        'profit',
        'roi',
        'roiYesterday',
        'cpcLastHour',
        'rpsLastHour',
        'roiLastHour',
        'cpcLast3Hours',
        'rpsLast3Hours',
        'roiLast3Hours',
        'sessions',
        'missingClicks',
        'fbConversions',
        'ppsCalc',
        'impsPerSession',
        'cpc',
        'rps',
        'rpm',
        'cpr',
        'ctr',
        FIELD.BID_KEY,
        FIELD.BUDGET_KEY,
        FIELD.LAST_UPDATE_KEY,
        'bidStrategy',
        'clicks',
        'revenueLastHour',
        'displayRevenue',
        'videoRevenue',
        'hbDisplayRevenue',
        'dfpAllignedRevenue',
        'predictedDFPRevenue',
        'predictedDFPRevenue1',
        'dfpMinRevenue',
        'conversions',
        'revenueLast3Hours',
        'campaignId',
        'adsetId',
        'campaignCreationDate',
        'accountId',
        'site',
        'cm',
        'article',
        'vv',
        FIELD.BUSINESS_KEY,
        'lang',
        'spendYesterday',
        'spendLast3Hours',
        'spendLastHour',
        'revenueYesterday',
        'clicksLastHour',
        'clicksLast3Hours',
        'sessionsWithRevenue',
        'sessionsLastHour',
        'sessionsLast3Hours',
        'pps',
        'displayImpressions',
    ],

    status: {
        ACTIVE: 'ACTIVE',
        PAUSED: 'PAUSED',
    },
};
