import _ from 'lodash';
import store from 'store/store';
import formatNumber from 'util/formatNumber';
import * as FIELD from 'data/fields';
import { HIDE_TYPE } from 'data/hideTypes';

const cellPadding = 8;

const getRealWidth = (field = '', maxValue = '', columnKey = '') => {
    maxValue = maxValue.length < field.length ? field : maxValue;
    const span = document.createElement('span');
    span.innerText = maxValue;
    span.style.opacity = 0;
    span.style.position = 'fixed';
    span.style.fontSize = '16px';
    document.body.append(span);
    let width = span.offsetWidth;
    width += 25;
    width += cellPadding * 2;
    if (['adsetName', FIELD.CAMPAIGN_NAME_KEY].includes(columnKey)) {
        width -= 225;
        span.style.textOverflow = 'ellipsis';
        span.style.overflow = 'hidden';
        span.style.whiteSpace = 'nowrap';
    }
    if (columnKey === 'tags') {
        width = 300;
    }
    // console.info('width', {maxValue, width})
    if (width < 100) return 100;
    if (width > 700) return 700;
    return width;
};

const getDateColumnMaxValue = ({ interval }) => {
    if (interval === 'hour') return '9999-99-99 99:99:99';
    if (interval === 'day') return '9999-99-99';
    if (interval === 'week') return '999, 9999';
    if (interval === 'month') return 'September, 9999';
};

const calculateColumnsWidth = (data = [], columns = [], columnsHash = {}, userColumnsWidth = {}) => {
    const maxByNumber = (key, formatter) => (row) => _.get(formatter(row[key]), 'length', 0);
    const maxByString = (key) => (row) => _.get(row, [key, 'length'], 0);

    const columnWidths = columns.reduce((acc, key) => {
        // get user's width instead of calculating dynamically
        if (userColumnsWidth[key]) return { ...acc, [key]: userColumnsWidth[key] };

        const { prefix, postfix, precision, title, isNumber } = columnsHash[key] || {};
        const formatter = isNumber ? formatNumber({ prefix, postfix }, precision, true) : null;
        const maxByMapper = isNumber ? maxByNumber(key, formatter) : maxByString(key);
        const maxValueRow = _.maxBy(data, maxByMapper);
        const maxValue = maxValueRow
            ? ((isNumber ? formatter(maxValueRow[key]) : maxValueRow[key]) || ' ').toString()
            : '';
        return { ...acc, [key]: getRealWidth(title, maxValue, key) };
    }, {});
    return columnWidths;
};

export const calculateReportColumns = (data, allDimensionsHash) => {
    const {
        metrics = [],
        dimensions = [],
        hidden = {},
        date = {},
        userColumnsWidth = {},
    } = store.getState().currentSelection;

    const columns = dimensions.concat(metrics).filter((k) => !_.get(hidden, [HIDE_TYPE.MAIN, k]));

    const columnWidths = calculateColumnsWidth(data, columns, allDimensionsHash, userColumnsWidth);
    columnWidths.date = getRealWidth('date', getDateColumnMaxValue(date));

    return columnWidths;
};
