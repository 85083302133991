import * as actions from 'store/actions';

const ACTION_HANDLERS = {
    [actions.SET_CURRENT_CAMPAIGN_ID]: (state, { campaignId }) => {
        return { ...state, selectedCampaignId: campaignId, selectedAdsetId: '' };
    },
    [actions.SET_CURRENT_ADSET_ID]: (state, { adsetId }) => {
        return { ...state, selectedAdsetId: adsetId, selectedCampaignId: '' };
    },
    [actions.CHANGE_SEARCH_CAMPAIGN_STATISTICS_MODAL_VISIBILITY]: (state) => {
        return { ...state, modalVisible: !state.modalVisible };
    },
    [actions.SET_CAMPAIGN_LAST_7_DAYS_DATA_LOADING]: (state, { loading }) => {
        return { ...state, last7DaysDataLoading: loading };
    },
    [actions.SET_CAMPAIGN_PER_COUNTRY_DATA_LOADING]: (state, { loading }) => {
        return { ...state, perCountryDataLoading: loading };
    },
    [actions.SET_CAMPAIGN_LAST_7_DAYS_DATA]: (state, { data }) => {
        return { ...state, last7DaysData: data };
    },
    [actions.SET_CAMPAIGN_PER_COUNTRY_DATA]: (state, { data }) => {
        return { ...state, perCountryData: data };
    },
    [actions.RESET_SEARCH_CAMPAIGN_STATISTICS]: (state) => {
        return { ...state, last7DaysData: [], perCountryData: [], selectedCampaignId: '' };
    },
};

const initialState = {
    last7DaysDataLoading: false,
    perCountryDataLoading: false,
    perCountryData: [],
    last7DaysData: [],
    modalVisible: false,
    selectedCampaignId: '',
    selectedAdsetId: '',
};

export default function (state = initialState, action) {
    const handler = ACTION_HANDLERS[action.type];

    return handler ? handler(state, action) : state;
}
