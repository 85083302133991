import React, { useContext } from 'react';
import _ from 'lodash';
import { Modal, Button, Empty, Tooltip } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { removeActions, runActions } from 'store/userActions/actionCreators';
import { bulkSelectionSetRows } from 'store/userActions/actionCreators';
import { DeleteOutlined } from '@ant-design/icons';
import DashboardContext from 'context/dashboardContext';

import './index.css';

const title_mapper = {
    campaign: 'Campaign actions',
    adset: 'Adset actions',
    ad: 'Ad actions',
};

const getItemParams = (itemType, item) => {
    if (itemType === 'adset') {
        const params = {
            itemId: item.ad_set_id,
            itemName: item.ad_set_name,
            action: item.action,
            accountId: item.account_id,
        };
        if (item.action === 'bid') {
            params.fromValue = item.bid;
            params.toValue = item.new_bid;
            params.tooltip = `From $${params.fromValue} to $${params.toValue}`;
        } else if (item.action === 'budget') {
            params.fromValue = item.budget;
            params.toValue = item.new_budget;
            params.tooltip = `From $${params.fromValue} to $${params.toValue}`;
        } else if (item.action === 'status') {
            params.fromValue = item.oldAdsetStatus;
            params.toValue = item.newAdsetStatus;
        }

        return params;
    }

    if (itemType === 'campaign') {
        const params = {
            itemId: item.campaign_id,
            itemName: item.campaign_name,
            action: item.action,
            accountId: item.account_id,
        };
        if (item.action === 'budget') {
            params.fromValue = item.budget;
            params.toValue = item.new_budget;
            params.tooltip = `From $${params.fromValue} to $${params.toValue}`;
        }
        return params;
    }

    if (itemType === 'ad') {
        const params = {
            itemId: item.ad_id,
            itemName: item.ad_name,
            action: item.action,
            accountId: item.account_id,
        };

        if (item.action === 'status') {
            params.fromValue = item.oldAdStatus;
            params.toValue = item.newAdStatus;
        }

        return params;
    }
};

const ActionListItemAction = (props) => {
    const { action, tooltip, toValue } = props;
    const dashboardContext = useContext(DashboardContext);
    const showBudgetDescription = action === 'bid' || action === 'budget';
    const showOID = action === 'pasteCreative';
    let actionTitle = action;
    if (action === 'pasteCreative') actionTitle = 'Copy Creative';
    if (action === 'status') {
        if (toValue === dashboardContext.status.ACTIVE) actionTitle = 'Activate';
        if (toValue === dashboardContext.status.PAUSED) actionTitle = 'Pause';
    }
    return (
        <div>
            <div className={'action-item-action ' + action + (showBudgetDescription ? ' top-offset' : '')}>
                {actionTitle}
            </div>
            {showBudgetDescription && (
                <Tooltip title={tooltip} placement="topLeft">
                    <div className="action-item-budget-description">{tooltip}</div>
                </Tooltip>
            )}
            {showOID && (
                <Tooltip title={tooltip} placement="topLeft">
                    <div className="action-item-budget-description">{tooltip}</div>
                </Tooltip>
            )}
        </div>
    );
};

const onLinkClick = (e) => {
    console.log(e.ctrlKey, e.metaKey, e.altKey);
    if (e.ctrlKey || e.metaKey || e.altKey) {
        e.preventDefault();
    }
};

const CampaignLink = (props) => {
    const { row = {}, value } = props;
    const { accountId, adsetId } = row;
    if (accountId && adsetId) {
        const url = `https://business.facebook.com/adsmanager/manage/adsets/edit?act=${accountId}&columns=name%2Cdelivery%2Ccampaign_name%2Cbid%2Cbudget%2Clast_significant_edit%2Cresults%2Creach%2Cimpressions%2Ccost_per_result%2Cquality_score_organic%2Cquality_score_ectr%2Cquality_score_ecvr%2Cspend%2Cend_time%2Cschedule%2Cactions%3Alink_click%2Cactions%3Aoffsite_conversion.fb_pixel_purchase%2Cactions%3Aonsite_conversion.purchase&attribution_windows=default&selected_adset_ids=${adsetId}`;
        return (
            <a href={url} rel="noopener noreferrer" target="_blank" onClick={onLinkClick}>
                {value}
            </a>
        );
    }

    return value;
};

const ActionListItem = (props) => {
    return (
        <div className="action-list-item">
            <div className="action-list-item-left">
                <Tooltip title={props.itemName} placement="top">
                    <div className="action-item-name">
                        <CampaignLink
                            row={{ adsetId: props.itemId, accountId: props.accountId }}
                            value={props.itemName}
                        ></CampaignLink>
                        ({props.itemId})
                    </div>
                </Tooltip>
                <ActionListItemAction {...props} />
            </div>
            <div>
                <Button onClick={props.onDelete} icon={<DeleteOutlined />} />
            </div>
        </div>
    );
};

export const RunModalContent = () => {
    const { actions = [] } = useSelector((store) => store.userActions);
    const dispatch = useDispatch();
    const grouped = _.groupBy(actions, 'item');

    const onDelete = (key) => {
        dispatch(removeActions({ keys: [key] }));
    };

    if (!actions.length) return <Empty style={{ margin: '30px' }} description="No actions selected" />;

    return Object.keys(grouped).map((k) => {
        const title = title_mapper[k];
        return (
            <div key={k} className="action-group">
                <div className="action-group-title">{title}</div>
                <div className="action-list">
                    {_.orderBy(grouped[k], (r) => getItemParams(k, r).itemName).map((act) => (
                        <ActionListItem key={act.key} onDelete={() => onDelete(act.key)} {...getItemParams(k, act)} />
                    ))}
                </div>
            </div>
        );
    });
};

const RunActionsModal = (props) => {
    const { isActionsRunning } = useSelector((store) => store.userActions);
    const dispatch = useDispatch();
    const dashboardContext = useContext(DashboardContext);

    const isTaboola = dashboardContext.name === 'Taboola';
    const onRunActions = async () => {
        const done = await dispatch(runActions(isTaboola));
        dispatch(bulkSelectionSetRows([]));
        if (done) props.onClose(true);
    };

    const onClearAll = () => {
        dispatch(removeActions({ all: true }));
    };

    return (
        <Modal
            title="Run selected actions"
            onCancel={() => props.onClose()}
            maskClosable={false}
            className="run-actions-modal"
            footer={
                <div>
                    <Button type="default" onClick={onClearAll} disabled={isActionsRunning}>
                        Clear all
                    </Button>
                    <Button type="default" onClick={() => props.onClose()} disabled={isActionsRunning}>
                        Cancel
                    </Button>
                    <Button type="primary" onClick={onRunActions} loading={isActionsRunning}>
                        Run
                    </Button>
                </div>
            }
            visible
        >
            <RunModalContent />
        </Modal>
    );
};

export default RunActionsModal;
