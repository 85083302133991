import * as actions from 'store/actions';
import { notification } from 'antd';
import TagsService from 'service/tags.service';
import store from 'store/store';

export const setModalVisible = (visible) => {
    return {
        type: actions.CHANGE_TAGS_MODAL_VISIBILITY,
        tagsModalVisible: visible,
    };
};

export const setCurrentAdset = (setCurrentAdset) => {
    return {
        type: actions.SET_TAG_TARGET_ADSET_NAME,
        targetAdset: setCurrentAdset,
    };
};

export const setTagsList = (tags) => {
    return {
        type: actions.SET_TAGS_LIST,
        tags,
    };
};

export const setAdset = (adsetData) => async (dispatch) => {
    console.log('Target adset', adsetData);
    dispatch(fetchTagsList());
    dispatch(setCurrentAdset(adsetData));
    dispatch(setModalVisible(true));
};

export const saveNewTag =
    (name, description, color = '') =>
    async (dispatch) => {
        try {
            const result = await TagsService.saveNewTag({ name, description, color });
            if (result?.error) throw new Error(result.error);

            notification.success({
                message: 'New tag successfully saved',
            });

            dispatch(fetchTagsList());
        } catch (err) {
            console.error('saveNewTag error', err);
            notification.error({
                message: 'Failed to save tag',
                description: err.message,
            });
        }
    };

export const setAdsetTags = (tags) => async (dispatch) => {
    try {
        const { targetAdset } = store.getState().tags;
        if (!targetAdset?.adsetId) throw new Error("Can't save adset tag without adset id");
        if (!targetAdset?.accountId) throw new Error("Can't save adset tag without account id");

        const result = await TagsService.setAdsetTags(targetAdset.adsetId, targetAdset.accountId, tags);
        if (result?.error) throw new Error(result.error);
    } catch (err) {
        console.error('setAdsetTags error', err);
        notification.error({
            message: 'Failed to add tag to adset',
            description: err.message,
        });
    }
};

export const fetchTagsList = () => async (dispatch) => {
    try {
        const tags = await TagsService.fetchTagsList();
        dispatch(setTagsList(tags.data));
    } catch (err) {
        console.error('fetchTagsList error', err);
        notification.error({
            message: 'Failed to fetch tags list',
            description: err.message,
        });
    }
};
