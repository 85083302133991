export const ID_KEY = 'Id';
export const ADSET_ID_KEY = 'adsetId';

export const AD_ID_KEY = 'adId';
export const AD_STATUS_KEY = 'adStatus';
export const AD_NAME_KEY = 'adName';

export const ADSET_STATUS_KEY = 'adsetEffectiveStatus';
export const ADSET_NAME_KEY = 'adsetName';
export const CAMPAIGN_NAME_KEY = 'campaignName';
export const CAMPAIGN_ID_KEY = 'campaignId';
export const CAMPAIGN_STATUS_KEY = 'campaignEffectiveStatus';
export const ACCOUNT_ID_KEY = 'accountId';
export const BUSINESS_KEY = 'business';
export const SITE_KEY = 'site';

export const BID_KEY = 'bidAmount';
export const BUDGET_KEY = 'dailyBudget';
export const BID_STRATEGY_KEY = 'bidStrategy';

export const IOS_SESSIONS_KEY = 'iosSessions';
export const NON_US_SESSIONS_KEY = 'nonUSSessions';
export const MOBILE_SESSIONS_KEY = 'mobileSessions';
export const SESSIONS_KEY = 'sessions';
export const ROI_KEY = 'roi';
export const SPEND_KEY = 'spend';
export const DISPLAY_IMPS_KEY = 'displayImpressions';
export const REVENUE_KEY = 'revenue';
export const CLICKS_KEY = 'clicks';
export const IMPRESSIONS_KEY = 'impressions';
export const PPS_KEY = 'pps';
export const SESSIONS_REV_KEY = 'sessionsWithRevenue';

export const REV_YESTERDAY_KEY = 'revenueYesterday';
export const SPEND_YESTERDAY_KEY = 'spendYesterday';
export const ROI_YESTERDAY_KEY = 'roiYesterday';

export const REV_1H_KEY = 'revenueLastHour';
export const SPEND_1H_KEY = 'spendLastHour';
export const CLICKS_1H_KEY = 'clicksLastHour';
export const SESSIONS_1H_KEY = 'sessionsLastHour';
export const ROI_1H_KEY = 'roiLastHour';

export const REV_3H_KEY = 'revenueLast3Hours';
export const SPEND_3H_KEY = 'spendLast3Hours';
export const CLICKS_3H_KEY = 'clicksLast3Hours';
export const SESSIONS_3H_KEY = 'sessionsLast3Hours';
export const ROI_3H_KEY = 'roiLast3Hours';

export const LAST_UPDATE_KEY = 'lastUpdate';
export const FB_CONVERSIONS_KEY = 'fbConversions';
export const PAGE_VIEWS_KEY = 'pageViews';

// Explorads

export const ACTUAL_ROI_KEY = 'actualRoi';
export const ACTUAL_ROI_1H_KEY = 'actualRoi1H';
export const ACTUAL_ROI_3H_KEY = 'actualRoi3H';
export const ACTUAL_ROI_YESTERDAY_KEY = 'actualRoiYesterday';

export const ACTUAL_REVENUE_3H_KEY = 'actualRevenueLast3Hours';
export const ACTUAL_REVENUE_1H_KEY = 'actualRevenueLast1Hours';
export const ACTUAL_REVENUE_YESTERDAY_KEY = 'actualRevenueYesterday';
