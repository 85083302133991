import React, { useEffect } from 'react';
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import { useDispatch, useSelector } from 'react-redux';

import { setAccessTokenSilently } from 'util/auth';
import { authenticate } from 'store/auth/actionCreators';

const queryParams = new URLSearchParams(window.location.search);

const providerConfig = {
    domain: process.env.REACT_APP_AUTH0_DOMAIN || '',
    clientId: process.env.REACT_APP_AUTH0_CLIENT_ID || '',
    redirectUri: encodeURI(window.location.origin),
    cacheLocation: 'localstorage',
    audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    organization: queryParams.get('organization') || undefined,
    invitation: queryParams.get('invitation') || undefined,
};

const CheckAuth = (props) => {
    const { user, isLoading: isAuthorizeLoading, isFinished: isAuthorizeFinished } = useSelector((store) => store.auth);
    const { isAuthenticated, getAccessTokenSilently, isLoading: isAuth0Loading } = useAuth0();
    const dispatch = useDispatch();

    setAccessTokenSilently(getAccessTokenSilently);

    useEffect(() => {
        if (
            isAuthenticated &&
            !isAuth0Loading && // auth0 - authorized
            !isAuthorizeFinished && // our server - not checked yet
            !isAuthorizeLoading &&
            !user // our server - not authorized
        ) {
            dispatch(authenticate());
        }
    }, [isAuthenticated, isAuth0Loading, isAuthorizeFinished, isAuthorizeLoading, !!user]);

    return <>{props.children}</>;
};

export const useAuth = () => {
    const { user, isLoading: isAuthorizeLoading } = useSelector((store) => store.auth);
    const { isAuthenticated, isLoading: isAuth0Loading, loginWithRedirect, logout: auth0Logout } = useAuth0();

    const isLoading = isAuthorizeLoading || isAuth0Loading;
    const isAuthorized = isAuthenticated && !!user;

    const logout = () => {
        auth0Logout({ returnTo: window.location.origin });
    };

    return {
        isLoading,
        isAuthorized,
        noAccess: !user?.authorized,
        user,
        login: loginWithRedirect,
        logout,
    };
};

export const AuthProvider = (props) => {
    return (
        <Auth0Provider {...providerConfig}>
            <CheckAuth>{props.children}</CheckAuth>
        </Auth0Provider>
    );
};
