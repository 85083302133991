// * New campaign name: company-sitecode-article-CM-Ver-type-date
// ? Example: kom-jsf-americanfood-v3906847972-lucia-a-02.3.23
// ! 7 parts, == 0 underscores

// * Old campaign name:
// ? Example: ftn-ftn-us-mtd-wrongparents-rpm20_lucia_v3737501167_all_allp_a_5E8C0_01.4.23
// ! 6 parts, > 0 underscores

// * Search Campaign Name:
// ? Example: kom-fb-shf-health_06.8.23
// !

const isNewCampaign = (campaignName) => {
    const parts = campaignName.split('_');
    if (parts.length === 1) return true;

    return false;
};

const isSearchCampaign = (campaignName) => {
    const parts = campaignName.split('_');
    if (parts.length === 2) return true;

    return false;
};

module.exports = (campaignName) => {
    if (!campaignName) return false;

    if (isSearchCampaign(campaignName)) return true;

    if (isNewCampaign(campaignName)) return true;

    return false;
};
