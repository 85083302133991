import _ from 'lodash';
import * as FIELD from 'data/fields';

const ACTION_KEY = {
    BID: 'bid',
    STATUS: 'status',
    BUDGET: 'budget',
};

const getActionItemKey = (action) => {
    // if (action.action === 'bid' && action.item === 'adset') return action.ad_set_id;
    // if (action.action === 'status' && action.item === 'adset') return action.ad_set_id;
    // if (action.action === 'budget' && action.item === 'adset') return action.ad_set_id;

    // if (action.action === 'status' && action.item === 'campaign') return action.campaign_id;
    // if (action.action === 'budget' && action.item === 'campaign') return action.campaign_id;

    // if (action.action === 'status' && action.item === 'ad') return action.ad_id;

    if (action.item === 'campaign') return action.campaign_id;
    if (action.item === 'adset') return action.ad_set_id;
    if (action.item === 'ad') return action.ad_id;
};

const getActionKey = (action) => {
    if (action === 'bid') return ACTION_KEY.BID;
    if (action === 'status') return ACTION_KEY.STATUS;
    if (action === 'budget') return ACTION_KEY.BUDGET;
};

const getRowIdByActionKey = (actionKey, row, action) => {
    if (actionKey === ACTION_KEY.BID) return row[FIELD.ADSET_ID_KEY];
    if (actionKey === ACTION_KEY.STATUS && action.item === 'adset') return row[FIELD.ADSET_ID_KEY];
    if (actionKey === ACTION_KEY.STATUS && action.item === 'campaign') return row[FIELD.CAMPAIGN_ID_KEY];
    if (actionKey === ACTION_KEY.STATUS && action.item === 'ad') return row[FIELD.AD_ID_KEY];
    if (actionKey === ACTION_KEY.BUDGET) return row[FIELD.CAMPAIGN_ID_KEY];
};

const generateObject = (actions = [], mapItemKey, mapActionKey, mapActionValue) => {
    // generate object: {
    //     [adsetId]: {
    //         bid: { newValue, oldValue },
    //         status: { newValue, oldValue },
    //     },
    //     [campaignId]: {
    //         budget: { newValue, oldValue }
    //     }
    // }
    return _(actions)
        .groupBy(mapItemKey) // group by adset/campaign id
        .mapValues((arr) =>
            _(arr)
                .keyBy((act) => mapActionKey(act.action)) // key every item by action name
                .mapValues(mapActionValue) // map every item
                .value()
        )
        .value();
};

export const getCurrentActionsObject = (actions = []) => {
    const getActionValue = (action) => {
        if (action.action === 'bid') return { newValue: action.new_bid, oldValue: action.bid };

        if (action.action === 'status' && action.item === 'adset')
            return { newValue: action.newAdsetStatus, oldValue: action.oldAdsetStatus };

        if (action.action === 'status' && action.item === 'campaign')
            return { newValue: action.newCampaignStatus, oldValue: action.oldCampaignStatus };

        if (action.action === 'status' && action.item === 'ad')
            return { newValue: action.newAdStatus, oldValue: action.oldAdStatus };

        if (action.action === 'budget') return { newValue: action.new_budget, oldValue: action.budget };
    };

    return generateObject(actions, getActionItemKey, getActionKey, getActionValue);
};

export const getPendingActionsObject = (actions = []) => {
    const getActionValue = (action) => {
        if (action.action === 'bid') return { newValue: action.bid, oldValue: action.old_bid };
        if (action.action === 'status' && action.item === 'adset')
            return { newValue: action.newAdsetStatus, oldValue: action.oldAdsetStatus };
        if (action.action === 'status' && action.item === 'campaign')
            return { newValue: action.newCampaignStatus, oldValue: action.oldCampaignStatus };

        if (action.action === 'status' && action.item === 'ad')
            return { newValue: action.newAdStatus, oldValue: action.oldAdStatus };

        if (action.action === 'budget') return { newValue: action.budget, oldValue: action.old_budget };
    };

    return generateObject(actions, getActionItemKey, getActionKey, getActionValue);
};

export const getActionFromObject = (actionsObj = {}, metricKey, row = {}) => {
    if (metricKey === FIELD.BID_KEY)
        return (
            actionsObj?.[row[FIELD.ADSET_ID_KEY]]?.[ACTION_KEY.BID] ||
            actionsObj?.[row[FIELD.CAMPAIGN_ID_KEY]]?.[ACTION_KEY.BID]
        );
    if (metricKey === FIELD.ADSET_STATUS_KEY) return actionsObj?.[row[FIELD.ADSET_ID_KEY]]?.[ACTION_KEY.STATUS];
    if (metricKey === FIELD.BUDGET_KEY)
        return (
            actionsObj?.[row[FIELD.ADSET_ID_KEY]]?.[ACTION_KEY.BUDGET] ||
            actionsObj?.[row[FIELD.CAMPAIGN_ID_KEY]]?.[ACTION_KEY.BUDGET]
        );
    if (metricKey === 'campaignEffectiveStatus') return actionsObj?.[row[FIELD.CAMPAIGN_ID_KEY]]?.[ACTION_KEY.STATUS];
    if (metricKey === FIELD.AD_STATUS_KEY) return actionsObj?.[row[FIELD.AD_ID_KEY]]?.[ACTION_KEY.STATUS];
};

export const rowPendingActionSelector = (row, action) => (pendingActions) => {
    const selectByActionKey = (actionKey, action) => pendingActions[getRowIdByActionKey(actionKey, row, action)];

    // if action name is provided - try to find pending action for this name
    if (action) return selectByActionKey(getActionKey(action), action);

    // try to find first pending action for any action key
    return Object.values(ACTION_KEY).map(selectByActionKey).find(Boolean);
};
