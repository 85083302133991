import _ from 'lodash';
import DashboardService from 'service/explorads-dashboard.service';
import { HIDE_TYPE } from 'data/hideTypes';
import * as FIELD from 'data/fields';
import * as Calculate from 'util/calculations';

const format = {
    int: '#,##0',
    float: '#,##0.00',
    percentage: '#,##0.00%',
    currency: '€#,##0.00',
    currency3: '€#,##0.000',
};

const dimensionsList = [
    {
        key: 'date',
        title: 'Date',
        isDate: true,
        isDimension: true,
    },
    {
        key: FIELD.ACCOUNT_ID_KEY,
        title: 'AccountId',
        isDimension: true,
    },
    {
        key: FIELD.CAMPAIGN_ID_KEY,
        title: 'CampaignId',
        isDimension: true,
    },
    {
        key: FIELD.CAMPAIGN_NAME_KEY,
        title: 'CampaignName',
        isDimension: true,
    },
    {
        key: 'campaignCreationDate',
        title: 'CampaignCreationDate',
        isDimension: true,
        isDate: true,
    },
    {
        key: FIELD.ADSET_ID_KEY,
        title: 'AdsetId',
        isDimension: true,
    },
    {
        key: 'adsetName',
        title: 'AdsetName',
        isDimension: true,
    },
    {
        key: 'adsetEffectiveStatusDimension',
        title: 'AdsetEffectiveStatus',
        isDimension: true,
    },
    {
        key: FIELD.SITE_KEY,
        title: 'Site',
        isDimension: true,
        isMultipleSelect: true,
    },
    {
        key: 'keyword',
        title: 'Keyword',
        isDimension: true,
        isMultipleSelect: true,
    },
    {
        key: 'articleName',
        title: 'Article Name',
        isDimension: true,
        isMultipleSelect: true,
    },
    {
        key: 'cm',
        title: 'CM',
        isDimension: true,
        isMultipleSelect: true,
    },
    {
        key: 'country',
        title: 'Country',
        isDimension: true,
        isMultipleSelect: true,
    },
    {
        key: 'device',
        title: 'Device',
        isDimension: true,
        isMultipleSelect: true,
    },
    {
        key: 'os',
        title: 'OS',
        isDimension: true,
        isMultipleSelect: true,
    },
    {
        key: 'vertical',
        title: 'Vertical',
        isDimension: true,
        isMultipleSelect: true,
    },
    {
        key: 'platform',
        title: 'Platform',
        isDimension: true,
        isMultipleSelect: true,
    },
    {
        key: 'placement',
        title: 'Placement',
        isDimension: true,
        isMultipleSelect: true,
    },
    {
        key: 'channel',
        title: 'Channel',
        isDimension: true,
        isMultipleSelect: true,
    },
    {
        key: FIELD.BUSINESS_KEY,
        title: 'Business',
        isDimension: true,
        isMultipleSelect: true,
    },
    {
        key: 'tags',
        title: 'Tags',
        isDimension: true,
        isMultipleSelect: true,
    },
    {
        key: 'bidStrategy',
        title: 'Strategy',
        isDimension: true,
        isMultipleSelect: true,
    },
];

const metricsList = [
    {
        key: 'actualClicks',
        title: 'ActClicks',
        fullTitle: 'Actual Clicks',
        precision: 0,
        isNumber: true,
        showTotal: true,
        format: format.int,
    },
    {
        key: 'actualRevenue',
        title: 'ActRevenue',
        fullTitle: 'Actual Revenue',
        precision: 2,
        prefix: '€',
        isNumber: true,
        showTotal: true,
        format: format.currency,
    },
    {
        key: 'actualRevenueYesterday',
        title: 'ActRevenueYes',
        fullTitle: 'Actual Revenue Yesterday',
        precision: 2,
        prefix: '€',
        isNumber: true,
        showTotal: true,
        format: format.currency,
    },
    {
        key: 'actualRPC',
        title: 'ActRPC',
        fullTitle: 'Actual RPC',
        precision: 2,
        prefix: '€',
        isNumber: true,
        showTotal: true,
        format: format.currency,
    },
    {
        key: 'exploradsClicks',
        title: 'ExpClicks',
        fullTitle: 'Explorads Clicks',
        precision: 0,
        isNumber: true,
        showTotal: true,
        format: format.int,
    },
    {
        key: 'rpc',
        title: 'RPC',
        fullTitle: 'RPC',
        precision: 2,
        prefix: '€',
        isNumber: true,
        showTotal: true,
        total: (row) => Calculate.rpc(row['actualRevenue'], row['exploradsClicks']),
        dependsOn: ['actualRevenue', 'exploradsClicks'],
        format: format.currency,
    },
    {
        key: 'cpr',
        title: 'CPR',
        fullTitle: 'CPR',
        precision: 2,
        prefix: '€',
        isNumber: true,
        showTotal: true,
        total: (row) => Calculate.cpr(row[FIELD.SPEND_KEY], row['conversions']),
        dependsOn: [FIELD.SPEND_KEY, 'conversions'],
        format: format.currency,
    },
    {
        key: 'cpm',
        title: 'CPM',
        fullTitle: 'CPM',
        precision: 2,
        prefix: '€',
        isNumber: true,
        showTotal: true,
        total: (row) => Calculate.cpm(row[FIELD.IMPRESSIONS_KEY], row[FIELD.SPEND_KEY]),
        dependsOn: [FIELD.SPEND_KEY, FIELD.IMPRESSIONS_KEY],
        format: format.currency,
    },
    {
        key: 'actualSpam',
        title: 'ActSpam',
        fullTitle: 'Actual Spam',
        precision: 2,
        prefix: '%',
        isNumber: true,
        showTotal: true,
        format: format.percentage,
    },
    {
        key: 'pageViews',
        title: 'Page Views',
        fullTitle: 'Page Views',
        precision: 0,
        isNumber: true,
        showTotal: true,
        format: format.int,
    },
    {
        key: 'fbClicks',
        title: 'FBClicks',
        fullTitle: 'FBClicks',
        precision: 0,
        isNumber: true,
        showTotal: true,
        format: format.int,
    },
    {
        key: 'conversions',
        title: 'FBConversions',
        fullTitle: 'FB Conversions',
        precision: 0,
        isNumber: true,
        showTotal: true,
        format: format.int,
    },
    {
        key: 'siteImps',
        title: 'SiteImps',
        fullTitle: 'SiteImps',
        precision: 0,
        isNumber: true,
        showTotal: true,
        format: format.int,
    },
    {
        key: 'siteClicks',
        title: 'SiteClicks',
        fullTitle: 'SiteClicks',
        precision: 0,
        isNumber: true,
        showTotal: true,
        format: format.int,
    },
    {
        key: 'gfeedImps',
        title: 'GoogleFeedImpressions',
        fullTitle: 'GoogleFeedImpressions',
        precision: 0,
        isNumber: true,
        showTotal: true,
        format: format.int,
    },
    {
        key: 'revenueClicks',
        title: 'Results',
        fullTitle: 'Results',
        precision: 0,
        isNumber: true,
        showTotal: true,
        format: format.int,
    },
    {
        key: 'impressions',
        title: 'Impressions',
        fullTitle: 'Impressions',
        precision: 0,
        isNumber: true,
        showTotal: true,
        format: format.int,
    },
    {
        key: FIELD.SPEND_KEY,
        title: 'Cost',
        fullTitle: 'Cost',
        prefix: '€',
        precision: 2,
        isNumber: true,
        showTotal: true,
        format: format.currency,
    },
    {
        key: 'budgetUsage',
        title: 'Budget Usage',
        fullTitle: 'Budget Usage',
        isNumber: true,
        postfix: '%',
        precision: 0,
        format: format.percentage,
        clintSideFiltered: true,
    },
    {
        key: FIELD.REVENUE_KEY,
        title: 'Revenue',
        fullTitle: 'Revenue',
        prefix: '€',
        precision: 2,
        isNumber: true,
        showTotal: true,
        format: format.currency,
    },
    {
        key: FIELD.ROI_KEY,
        title: 'ROI',
        fullTitle: 'ROI',
        postfix: '%',
        precision: 0,
        isNumber: true,
        showTotal: true,
        total: (row) => Calculate.roi(row[FIELD.REVENUE_KEY], row[FIELD.SPEND_KEY]),
        dependsOn: [FIELD.REVENUE_KEY, FIELD.SPEND_KEY],
        format: format.percentage,
        clintSideFiltered: true,
    },
    {
        key: FIELD.ROI_1H_KEY,
        title: 'Roi-1H',
        fullTitle: 'RoiLastHour',
        postfix: '%',
        precision: 0,
        isNumber: true,
        showTotal: true,
        total: (row) => Calculate.roi(row[FIELD.REV_1H_KEY], row[FIELD.SPEND_1H_KEY]),
        dependsOn: [FIELD.REV_1H_KEY, FIELD.SPEND_1H_KEY],
        format: format.percentage,
        clintSideFiltered: true,
    },
    {
        key: FIELD.ACTUAL_ROI_KEY,
        title: 'ActROI',
        fullTitle: 'Actual ROI',
        postfix: '%',
        precision: 0,
        isNumber: true,
        showTotal: true,
        total: (row) => Calculate.roi(row['actualRevenue'], row['actualSpend']),
        dependsOn: ['actualRevenue', 'actualSpend'],
        format: format.percentage,
        clintSideFiltered: true,
    },
    {
        key: FIELD.ACTUAL_ROI_YESTERDAY_KEY,
        title: 'ActROI-Yes',
        fullTitle: 'Actual ROI Yesterday',
        postfix: '%',
        precision: 0,
        isNumber: true,
        showTotal: true,
        total: (row) => Calculate.roi(row['actualRevenueYesterday'], row[FIELD.SPEND_YESTERDAY_KEY]),
        dependsOn: ['actualRevenueYesterday', FIELD.SPEND_YESTERDAY_KEY],
        format: format.percentage,
        clintSideFiltered: true,
    },
    {
        key: FIELD.REV_3H_KEY,
        title: 'REV-3H',
        fullTitle: 'revenueLast3Hours',
        prefix: '€',
        precision: 2,
        isNumber: true,
        showTotal: true,
        format: format.currency,
    },
    {
        key: FIELD.REV_1H_KEY,
        title: 'REV-1H',
        fullTitle: 'revenueLastHour',
        prefix: '€',
        precision: 2,
        isNumber: true,
        showTotal: true,
        format: format.currency,
    },
    {
        key: FIELD.SPEND_3H_KEY,
        title: 'Cost-3H',
        fullTitle: 'Cost Last 3 hours',
        prefix: '€',
        precision: 2,
        isNumber: true,
        showTotal: true,
        format: format.currency,
    },
    {
        key: 'actualSpend',
        title: 'ActCost',
        fullTitle: 'Actual Spend',
        prefix: '€',
        precision: 2,
        isNumber: true,
        showTotal: true,
        format: format.currency,
    },
    {
        key: FIELD.SPEND_1H_KEY,
        title: 'Spend-1H',
        fullTitle: 'SpendLastHour',
        prefix: '€',
        precision: 2,
        isNumber: true,
        showTotal: true,
        format: format.currency,
    },
    {
        key: FIELD.ROI_3H_KEY,
        title: 'Roi-3H',
        fullTitle: 'RoiLast3Hours',
        postfix: '%',
        precision: 0,
        isNumber: true,
        showTotal: true,
        total: (row) => Calculate.roi(row[FIELD.REV_3H_KEY], row[FIELD.SPEND_3H_KEY]),
        dependsOn: [FIELD.REV_3H_KEY, FIELD.SPEND_3H_KEY],
        format: format.percentage,
        clintSideFiltered: true,
    },
    {
        key: FIELD.REV_YESTERDAY_KEY,
        title: 'Revenue-Yes',
        fullTitle: 'revenueYesterday',
        prefix: '€',
        precision: 2,
        isNumber: true,
        showTotal: true,
        format: format.currency,
    },
    {
        key: FIELD.SPEND_YESTERDAY_KEY,
        title: 'Revenue-Yes',
        fullTitle: 'spendYesterday',
        prefix: '€',
        precision: 2,
        isNumber: true,
        showTotal: true,
        format: format.currency,
    },
    {
        key: FIELD.ROI_YESTERDAY_KEY,
        title: 'Roi-Yes',
        fullTitle: 'RoiYesterday',
        postfix: '%',
        precision: 0,
        isNumber: true,
        showTotal: true,
        total: (row) => Calculate.roi(row[FIELD.REV_YESTERDAY_KEY], row[FIELD.SPEND_YESTERDAY_KEY]),
        dependsOn: [FIELD.REV_YESTERDAY_KEY, FIELD.SPEND_YESTERDAY_KEY],
        format: format.percentage,
        clintSideFiltered: true,
    },
    {
        key: 'missingClicks',
        title: 'Missing clicks%',
        fullTitle: 'Missing Clicks',
        postfix: '%',
        precision: 2,
        isNumber: true,
        showTotal: false,
        format: format.percentage,
    },
    {
        key: 'ctr',
        title: 'CTR',
        fullTitle: 'CTR',
        postfix: '%',
        precision: 0,
        isNumber: true,
        showTotal: true,
        total: (row) => Calculate.ctr(row['fbClicks'], row[FIELD.IMPRESSIONS_KEY]),
        dependsOn: ['fbClicks', FIELD.IMPRESSIONS_KEY],
        format: format.percentage,
        clintSideFiltered: true,
    },
    {
        key: 'cvr1',
        title: 'CVR1',
        fullTitle: 'CVR1',
        postfix: '%',
        precision: 0,
        isNumber: true,
        showTotal: true,
        total: (row) => Calculate.cvr(row['siteClicks'], row['fbClicks']),
        dependsOn: ['fbClicks', 'siteClicks'],
        format: format.percentage,
        clintSideFiltered: true,
    },
    {
        key: 'cvr2',
        title: 'CVR2',
        fullTitle: 'CVR2',
        postfix: '%',
        precision: 0,
        isNumber: true,
        showTotal: true,
        total: (row) => Calculate.cvr(row['revenueClicks'], row['fbClicks']),
        dependsOn: ['fbClicks', 'revenueClicks'],
        format: format.percentage,
        clintSideFiltered: true,
    },
    {
        key: 'cpc',
        title: 'CPC',
        fullTitle: 'CPC',
        prefix: '€',
        precision: 3,
        isNumber: true,
        showTotal: true,
        total: (row) => Calculate.cpc(row[FIELD.SPEND_KEY], row['fbClicks']),
        dependsOn: [FIELD.SPEND_KEY, 'fbClicks'],
        format: format.currency,
        clintSideFiltered: true,
    },
    {
        key: 'profit',
        title: 'Profit',
        fullTitle: 'Profit',
        prefix: '€',
        precision: 2,
        isNumber: true,
        showTotal: true,
        format: format.currency,
    },
    {
        key: 'profitLast3Hours',
        title: 'Profit-3H',
        fullTitle: 'Profit Last 3 Hours',
        prefix: '€',
        precision: 2,
        isNumber: true,
        showTotal: true,
        format: format.currency,
    },
    {
        key: 'actualProfit',
        title: 'ActProfit',
        fullTitle: 'Actual Profit',
        prefix: '€',
        precision: 2,
        isNumber: true,
        showTotal: true,
        format: format.currency,
    },
    {
        key: 'actualProfitYesterday',
        title: 'ActProfit-Yes',
        fullTitle: 'Actual Profit Yesterday',
        prefix: '€',
        precision: 2,
        isNumber: true,
        showTotal: true,
        format: format.currency,
    },
    {
        key: FIELD.BUDGET_KEY,
        title: 'Budget',
        fullTitle: 'DailyBudget',
        prefix: '€',
        precision: 2,
        isNumber: true,
        format: format.currency,
    },
    {
        key: FIELD.BID_KEY,
        title: 'Bid',
        fullTitle: 'BidAmount',
        prefix: '€',
        precision: 2,
        isNumber: true,
        format: format.currency,
    },
    {
        key: 'campaignEffectiveStatus',
        title: 'CampaignEffectiveStatus',
    },
    {
        key: FIELD.ADSET_STATUS_KEY,
        title: 'Status',
        isMultipleSelect: true,
    },
    {
        key: 'lastUpdate',
        title: 'Last Update',
        isDate: true,
    },
];

export default {
    name: 'Explorads',
    defaultTimezone: 'PST',
    service: DashboardService,

    metricsList,
    dimensionsList,
    allDimensionsList: dimensionsList.concat(metricsList),
    allDimensionsHash: _.keyBy(dimensionsList.concat(metricsList), 'key'),
    metricsListHash: _.keyBy(metricsList, 'key'),
    dimensionsListHash: _.keyBy(dimensionsList, 'key'),

    selectedDimensions: [
        FIELD.ADSET_ID_KEY,
        'adsetName',
        'keyword',
        'vertical',
        'platform',
        'cm',
        'device',
        'country',
        'channel',
        FIELD.BUSINESS_KEY,
        'accountId',
        FIELD.BID_STRATEGY_KEY,
    ],
    selectedMetrics: [
        'adsetEffectiveStatus',
        'actualRevenue',
        'actualProfit',
        FIELD.ACTUAL_ROI_KEY,
        FIELD.ACTUAL_ROI_YESTERDAY_KEY,
        FIELD.SPEND_KEY,
        FIELD.REVENUE_KEY,
        'profit',
        'cpc',
        'rpc',
        'cvr1',
        'cvr2',
        'ctr',
        'actualProfitYesterday',
        'revenueLast3Hours',
        FIELD.SPEND_3H_KEY,
        'profitLast3Hours',
        'actualSpend',
        'cpr',
        'cpm',
        'exploradsClicks',
        FIELD.BUDGET_KEY,
        FIELD.BID_KEY,
        FIELD.ROI_KEY,
        FIELD.ROI_3H_KEY,
    ],
    hidden: {
        [HIDE_TYPE.MAIN]: {
            [FIELD.ADSET_ID_KEY]: true,
            cm: true,
            [FIELD.ROI_1H_KEY]: true,
            [FIELD.ROI_YESTERDAY_KEY]: true,
            vertical: true,
            platform: true,
            device: true,
            country: true,
            channel: true,
            [FIELD.BUSINESS_KEY]: true,
            fbClicks: true,
            missingClicks: true,
            revenueClicks: true,
            accountId: true,
        },
    },
    dimensionsOrder: [
        'adsetEffectiveStatus',
        'adsetName',
        'keyword',
        FIELD.ADSET_ID_KEY,
        'vertical',
        'platform',
        'cm',
        'device',
        'country',
        'channel',
        FIELD.BUSINESS_KEY,
        'actualSpend',
        'actualRevenue',
        'actualProfit',
        'actualProfitYesterday',
        FIELD.ACTUAL_ROI_KEY,
        FIELD.ACTUAL_ROI_YESTERDAY_KEY,
        'spend',
        'revenue',
        'profit',
        FIELD.ROI_KEY,
        'cpc',
        'cpr',
        'rpc',
        FIELD.SPEND_3H_KEY,
        'revenueLast3Hours',
        'profitLast3Hours',
        FIELD.ROI_3H_KEY,
        'cpm',
        'cvr1',
        'cvr2',
        'ctr',
        'exploradsClicks',
        FIELD.BUDGET_KEY,
        FIELD.BID_KEY,
        FIELD.BID_STRATEGY_KEY,
    ],

    status: {
        ACTIVE: 'ACTIVE',
        PAUSED: 'PAUSED',
    },
};
