import _ from 'lodash';

// if precision is not needed - pass false
export default ({ prefix, postfix } = {}, precision = 2, commas = true) => (x) => {
    if (_.isNil(x)) x = 0;

    if (!_.isNumber(x)) return x;

    if (precision !== false) {
        x = _.round(x, precision);
        x = x.toFixed(precision);
    }

    x = x.toString();

    // adding commas
    if (commas) {
        let parts = x.split('.');
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        x = parts.join('.');
    }

    if (prefix) {
        x = /^-/.test(x) ? x.replace(/^-/, `-${prefix}`) : prefix + x;
    }
    if (postfix) {
        x = x + postfix;
    }

    return x;
};
