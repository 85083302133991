import _ from 'lodash';
import { notification } from 'antd';
import store from 'store/store';
import { updateActionsStatus, updatePendingActions, toggleRefreshDataPopup } from 'store/userActions/actionCreators';

import { getAccessToken } from 'util/auth';
import request from 'util/request';

const parseMessage = (e) => {
    try {
        const resp = JSON.parse(_.get(e, 'data', '{}'));
        return resp;
    } catch (err) {
        console.error('parseMessage err', err.message);
    }
};

class Subscribe {
    constructor() {
        this.subscription = null;
        this.maxRetries = 10;
        this.retriesCount = 0;
    }

    async subscribe() {
        if (!window.EventSource) {
            return notification.error({
                message: 'Failed to subscribe',
                description: 'Your browser does not support EventSource',
            });
        }
        // close connection if already subscribed
        this.unsubscribe();

        const token = await getAccessToken();

        const url = request.getURL(`/subscribe?token=Bearer ${token}`);
        this.subscription = new EventSource(url);
        this.subscription.onerror = () => {
            console.info('EventSource error, retry', this.retriesCount);
            this.retriesCount++;
            if (this.retriesCount >= this.maxRetries) {
                this.unsubscribe();
            }
        };
        this.subscription.addEventListener('onOpen', this.onOpen.bind(this));
        this.subscription.addEventListener('onNotification', this.onNotification.bind(this));
        this.subscription.addEventListener('onUpdateActionStatus', this.onUpdateActionStatus.bind(this));
        this.subscription.addEventListener('onUpdatePendingActions', this.onUpdatePendingActions.bind(this));
        this.subscription.addEventListener('onActionFinished', this.onActionFinished.bind(this));
        this.subscription.addEventListener('onDisconnect', this.onDisconnect.bind(this));
    }

    unsubscribe() {
        if (this.subscription) {
            console.info('Unsubscribe');
            this.subscription.close();
            this.subscription = null;
        }
    }

    isSubscribed() {
        console.info('isSubscribed', this.subscription && this.subscription.readyState === 1);
        return this.subscription && this.subscription.readyState === 1;
    }

    /* event hadlers */

    onOpen(e) {
        const resp = parseMessage(e);
        console.info('onOpen', resp);

        if (resp.data === true) {
            console.info('Subscribed');
            this.retriesCount = 0;
        } else {
            console.error('Failed to subscribe');
        }
    }

    onNotification(e) {
        const resp = parseMessage(e);
        console.info('onNotification', resp);

        if (resp) {
            const { type, message = '', description = '', duration = 0 } = resp;
            notification[type]({
                message,
                description,
                duration,
            });
        }
    }

    onUpdateActionStatus(e) {
        const resp = parseMessage(e);
        console.info('onUpdateActionStatus', resp);
        const { actionStatus } = resp;
        store.dispatch(updateActionsStatus([actionStatus]));
    }

    onActionFinished(e) {
        const resp = parseMessage(e);
        console.info('onActionFinished', resp);
        store.dispatch(toggleRefreshDataPopup(true));
    }

    onUpdatePendingActions(e) {
        const resp = parseMessage(e);
        console.info('onUpdatePendingActions', resp);
        const { actionItems } = resp;
        store.dispatch(updatePendingActions(actionItems));
    }

    onDisconnect(e) {
        const resp = parseMessage(e);
        console.info('onDisconnect', resp);

        this.unsubscribe();
    }

    /* */
}

const Subscription = new Subscribe();

export const subscribeToServerEvents = () => {
    const onUpdateSubscription = () => {
        console.info('onUpdateSubscription fire');
        if (!Subscription.isSubscribed() && document.visibilityState === 'visible') {
            console.info('onUpdateSubscription', 'subscribe');
            Subscription.subscribe();
        }
    };

    document.addEventListener('visibilitychange', onUpdateSubscription);

    onUpdateSubscription();

    return () => {
        document.removeEventListener('visibilitychange', onUpdateSubscription);
        Subscription.unsubscribe();
    };
};

export default Subscription;
