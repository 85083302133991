import React, { useEffect, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, Tabs, Button, Card, Typography, Space } from 'antd';
import { InfoCircleTwoTone } from '@ant-design/icons';
import dayjs from 'dayjs';

import { fetchActionsStatus } from 'store/userActions/actionCreators';

import { removeActions, runActions } from 'store/userActions/actionCreators';
import { bulkSelectionSetRows } from 'store/userActions/actionCreators';

import { clearAdsLimits } from 'store/campaignData/actionCreator';

import { ModalContent } from 'components/StatusActionsModal';
import { RunModalContent } from 'components/RunActionsModal';

import DashboardContext from 'context/dashboardContext';

const { Text } = Typography;

const DisabledActionsWarning = () => {
    const now = dayjs();
    let closerOpenWindow;
    if (now.minute() <= 6) {
        closerOpenWindow = now.minute(7).second(0);
    } else {
        closerOpenWindow = now.add(1, 'hour').minute(7).second(0);
    }
    return (
        <Space>
            <InfoCircleTwoTone />
            <Text type="warning">
                Actions blocked while the dashboard is being updated. Please try again at{' '}
                {closerOpenWindow.format('HH:mm')}.
            </Text>
        </Space>
    );
};

const UnivarsalActionsModal = (props) => {
    const { isActionsRunning, actions = [] } = useSelector((store) => store.userActions);
    const dispatch = useDispatch();

    const dashboardContext = useContext(DashboardContext);

    const isTaboola = dashboardContext.name === 'Taboola';

    const currentMinute = dayjs().minute();
    // If the current minute is less than 7, the Run button should be disabled
    // because we update metadata at start of the hour
    const shouldBlockRun = currentMinute < 6 || currentMinute >= 58;

    const onRunActions = async () => {
        const done = await dispatch(runActions(isTaboola));
        dispatch(clearAdsLimits());
        dispatch(bulkSelectionSetRows([]));
        if (done) props.onClose(true);
    };

    const onClearAll = () => {
        dispatch(removeActions({ all: true }));
        dispatch(clearAdsLimits());
    };

    useEffect(() => {
        dispatch(fetchActionsStatus());
    }, []);

    return (
        <Modal
            title={'Actions'}
            footer={false}
            maskClosable={false}
            className="actions-status-modal"
            onCancel={props.onClose}
            visible
        >
            <Tabs centered>
                <Tabs.TabPane tab="Status" key="item-1">
                    <ModalContent />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Run actions" key="item-2">
                    <RunModalContent />
                    <div
                        style={{
                            width: '100%',
                            display: actions.length ? 'flex' : 'none',
                            justifyContent: 'flex-end',
                            position: 'sticky',
                            bottom: 0,
                            padding: '10px 0',
                            backgroundColor: '#fff',
                        }}
                    >
                        <div
                            style={{
                                width: '70%',
                                display: 'flex',
                                justifyContent: 'space-around',
                            }}
                        >
                            {shouldBlockRun && <DisabledActionsWarning />}
                        </div>
                        <div
                            style={{
                                width: '30%',
                                display: 'flex',
                                justifyContent: 'space-around',
                            }}
                        >
                            <Button type="default" onClick={onClearAll} disabled={isActionsRunning}>
                                Clear all
                            </Button>
                            <Button type="default" onClick={() => props.onClose()} disabled={isActionsRunning}>
                                Cancel
                            </Button>
                            <Button
                                type="primary"
                                disabled={shouldBlockRun}
                                onClick={onRunActions}
                                loading={isActionsRunning}
                            >
                                Run
                            </Button>
                        </div>
                    </div>
                </Tabs.TabPane>
            </Tabs>
        </Modal>
    );
};

export default UnivarsalActionsModal;
