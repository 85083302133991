import request from 'util/request';
import dayjs from 'dayjs';

const formatDate = (date = {}) => {
    return {
        ...date,
        range: [dayjs(date.range[0]).format('YYYY-MM-DD HH:mm:ss'), dayjs(date.range[1]).format('YYYY-MM-DD HH:mm:ss')],
    };
};

const getDimensionsMetrics = () => {
    return request.get('/dashboard/dimensions');
};

const fetchReportData = (params) => {
    const urlParams = new URLSearchParams(window.location.search);
    const estTimezone = urlParams?.get('est_timezone');
    const useESTTimezone = estTimezone === 'true' ? true : false;

    const data = {
        date: formatDate(params.date),
        dimensions: params.dimensions,
        metrics: params.metrics,
        filters: params.filters,
        useESTTimezone,
    };
    return request.post('/dashboard/adsense-report-query', { data });
};

export default {
    getDimensionsMetrics,
    fetchReportData,
};
