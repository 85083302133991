import _ from 'lodash';
import store from 'store/store';
import { FILTER_OPERAND, operandsHash } from 'data/filterOperands';
import formatNumber from 'util/formatNumber';
import dayjs from 'dayjs';

const skipServerFilters = (field, dimensions, metrics, metricsListHash) => {
    const allSelectedFields = dimensions.concat(metrics);
    if (!allSelectedFields.includes(field) && !metricsListHash[field]?.clintSideFiltered) return true;

    return false;
};

const filterData = (dashboardConfig, data = [], currentSelection = {}) => {
    const { filters = {}, dimensions, metrics } = currentSelection;
    const toNumber = (x) => {
        x = formatNumber({}, 2, false)(x);
        x = parseFloat(x) || 0;
        return x;
    };

    const checkFieldValue = (rowValue, filterValue, dimensionItem, filterOperand) => {
        // parse value to number for numeric metrics
        if (dimensionItem.isNumber) {
            rowValue = toNumber(rowValue);
        }
        // parse value back to string for string operands
        if (filterOperand.string) {
            if (_.isNil(rowValue)) rowValue = '';
            if (dimensionItem.key === 'tags') {
                rowValue = rowValue.map((tag) => tag.name.toString().toLowerCase());
            } else {
                rowValue = rowValue.toString().toLowerCase();
            }

            filterValue =
                filterOperand.key === FILTER_OPERAND.ONE_OF || filterOperand.key === FILTER_OPERAND.NOT_ONE_OF
                    ? (filterValue || []).map((v) => (v || '').toString().toLowerCase()).filter(Boolean)
                    : filterValue.toString().toLowerCase();
        }

        if (dimensionItem.isDate) {
            rowValue = dayjs(rowValue.split('t')[0]).format('YYYY-MM-DD HH:00:00');
            if (typeof filterValue === 'string' && filterValue.split(' ').length < 2)
                rowValue = dayjs(rowValue.split('t')[0]).format('YYYY-MM-DD');
        }

        // if (dimensionItem.key === 'tags'){
        //     rowValue = rowValue?.tags?.map(tag => tag.name)
        // }

        switch (filterOperand.key) {
            case FILTER_OPERAND.EQUAL:
                return rowValue === filterValue;

            case FILTER_OPERAND.NOT_EQUAL:
                return rowValue !== filterValue;

            case FILTER_OPERAND.LESS_OR_EQUAL:
                return rowValue <= filterValue;

            case FILTER_OPERAND.LESS:
                return rowValue < filterValue;

            case FILTER_OPERAND.GREATER_OR_EQUAL:
                return rowValue >= filterValue;

            case FILTER_OPERAND.GREATER:
                return rowValue > filterValue;

            case FILTER_OPERAND.CONTAINS:
                return rowValue.includes(filterValue);

            case FILTER_OPERAND.NOT_CONTAINS:
                return !rowValue.includes(filterValue);

            case FILTER_OPERAND.STARTS_WITH:
                return rowValue.startsWith(filterValue);

            case FILTER_OPERAND.ONE_OF:
                return filterValue.some((item) => {
                    if (dimensionItem.key === 'tags') {
                        return rowValue.includes(item);
                    }
                    return rowValue === item;
                });

            case FILTER_OPERAND.NOT_ONE_OF:
                return !filterValue.some((item) => {
                    if (dimensionItem.key === 'tags') {
                        return rowValue.includes(item);
                    }
                    return rowValue === item;
                });

            case FILTER_OPERAND.IN_RANGE:
                return dayjs(rowValue).isBetween(filterValue[0], filterValue[1], undefined, '[]');

            default:
                return false;
        }
    };

    const filterMapper = (row) => {
        // AND between all filters
        for (const key in filters) {
            const { field, items = [] } = filters[key];
            if (skipServerFilters(field, dimensions, metrics, dashboardConfig.metricsListHash)) continue;
            let rowOk = false;
            // OR inside each filter
            for (const item of items) {
                if (
                    checkFieldValue(
                        row[field],
                        item.value,
                        dashboardConfig.allDimensionsHash[field],
                        operandsHash[item.operand]
                    )
                ) {
                    rowOk = true;
                    break;
                }
            }
            if (!rowOk) return false;
        }
        return true;
    };

    return data.filter(filterMapper);
};

export const shouldBeFetched = (forceUpdate = false) => {
    if (forceUpdate) return true;
    const { dashboard, currentSelection } = store.getState();
    const { metrics = [], dimensions = [], date = {}, filters } = currentSelection;
    const { metrics: prevMetrics = [], dimensions: prevDimensions = [], date: prevDate = {} } = dashboard;

    if (date.interval !== prevDate.interval) return true;
    if (
        !dayjs(date.range[0]).isSame(dayjs(prevDate.range[0])) ||
        !dayjs(date.range[1]).isSame(dayjs(prevDate.range[1]))
    )
        return true;

    const filterFields = _.map(filters).map((f) => f.field);

    if (filterFields.some((f) => !dimensions.concat(metrics).includes(f))) return true;

    const newMetrics = _.difference(metrics, prevMetrics);

    return newMetrics.length > 0 || !_.isEqual(dimensions, prevDimensions);
};

export const getFilteredData = async (dashboardConfig, shouldFetch = false) => {
    const { dashboard, currentSelection } = store.getState();
    let data;
    if (shouldFetch) {
        const fetchParams = {
            date: { ...currentSelection.date },
            dimensions: _(currentSelection.dimensions)
                .flatMap((dimensionKey) => dashboardConfig.dimensionsListHash[dimensionKey]?.dependsOn || [])
                .concat(currentSelection.dimensions)
                .uniq()
                .value(),
            metrics: _(currentSelection.metrics)
                .flatMap((metricKey) => dashboardConfig.metricsListHash[metricKey]?.dependsOn || [])
                .concat(currentSelection.metrics)
                .uniq()
                .value(),
            filters: { ...currentSelection.filters },
        };
        const resp = await dashboardConfig.service.fetchReportData(fetchParams);

        if (resp.error) throw new Error(resp.error);
        data = _.get(resp, 'data', []);
        console.log('Response: ', data);
    }
    data = data || dashboard.data || [];
    const filteredData = filterData(dashboardConfig, data, currentSelection);
    return { data, filteredData };
};

export const calculateTotalRow = (dashboardConfig, data = []) => {
    const sumTotalMetrics = dashboardConfig.metricsList.filter((metric) => metric.showTotal && !metric.total);
    const calcTotalMetrics = dashboardConfig.metricsList.filter((metric) => metric.showTotal && metric.total);

    // calculate total metrics using only sum of all rows
    const totalRowSumMetrics = sumTotalMetrics.reduce(
        (acc, metric) => ({
            ...acc,
            [metric.key]: _.sumBy(data, metric.key),
        }),
        {}
    );

    // calculate total metrics using formulas
    const totalRowCalcMetrics = calcTotalMetrics.reduce(
        (acc, metric) => ({
            ...acc,
            [metric.key]: metric.total(totalRowSumMetrics),
        }),
        {}
    );

    const totalRow = {
        ...totalRowSumMetrics,
        ...totalRowCalcMetrics,
    };

    return totalRow;
};

const getFilterValues = (data, key) => {
    const values = [];
    for (const row of data) {
        const value = row[key];
        if (key === 'tags') {
            values.push(...value.map((tag) => tag.name));
        } else {
            values.push(value);
        }
    }
    return values;
};

export const getFilterOptions = (dashboardConfig, data = []) => {
    return dashboardConfig.allDimensionsList.reduce(
        (acc, dimension) =>
            dimension.isMultipleSelect
                ? { ...acc, [dimension.key]: _.uniq(getFilterValues(data, dimension.key)) }
                : acc,
        {}
    );
};
