import _ from 'lodash';
import React, { useEffect, useState, useContext } from 'react';
import { InputNumber, notification, Switch, Space } from 'antd';
import { HistoryOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import DashboardContext from 'context/dashboardContext';
import getMetricCellValues from 'util/getMetricCellValues';
import { getActionFromObject } from 'util/currentPendingActions';
import { hasUserPermissions } from 'util/permissions';
import * as FIELD from 'data/fields';
import { fetchAdsetHistory } from 'store/userActions/actionCreators'; // Убедитесь, что указали правильный путь к вашим action creators

const maxBudget = 10_000;

const getInputComponent = (metric) => {
    if (metric.key === FIELD.BID_KEY) return BidInput;
    if (metric.key === FIELD.ADSET_STATUS_KEY) return StatusInput;
    if (metric.key === FIELD.BUDGET_KEY) return BudgetInput;
    return null;
};

const getActionParamKey = (actionType) => {
    if (actionType === 'bid') return 'new_bid';
    if (actionType === 'status') return 'newAdsetStatus';
    if (actionType === 'budget') return 'new_budget';
};

const BidInput = (props) => {
    const { value, onChange, onCancel, onSaveNewValue } = props;

    return (
        <InputNumber
            className="editable-cell-number-input"
            value={value}
            onStep={onChange}
            onKeyDown={(e) => e.preventDefault()} // do not allow changing the value manually
            onPressEnter={() => onSaveNewValue('bid')}
            onBlur={onCancel}
            min={0}
            // max={1}
            step={0.01}
            precision={2}
            autoFocus
        />
    );
};

const BudgetInput = (props) => {
    const { value, onChange, onCancel, onSaveNewValue } = props;

    const onPressEnter = (e) => {
        if (value !== _.toNumber(e.target.value) || value > maxBudget || value < 0) {
            notification.error({
                message: 'Failed to change budget',
                description: `Budget has to be an integer value between 0 and ${maxBudget}`,
            });
        } else {
            onSaveNewValue('budget');
        }
    };

    return (
        <InputNumber
            className="editable-cell-number-input"
            value={value}
            onStep={onChange}
            onChange={onChange}
            onPressEnter={onPressEnter}
            onBlur={onCancel}
            min={0}
            max={maxBudget}
            step={1}
            precision={0}
            autoFocus
        />
    );
};

const StatusInput = (props) => {
    const { value, onChange, onCancel, onSaveNewValue } = props;
    const dashboardContext = useContext(DashboardContext);
    return (
        <Switch
            checkedChildren={dashboardContext.status.ACTIVE}
            unCheckedChildren={dashboardContext.status.PAUSED}
            checked={value === dashboardContext.status.ACTIVE}
        />
    );
};

const EditableCell = (props) => {
    const dispatch = useDispatch();
    const dashboardContext = useContext(DashboardContext);

    //const isTaboola = dashboardContext.name === 'Taboola';

    const { className, displayValue, value, metric = {}, onCellClick, onSave, row } = props;

    const { user } = useSelector((store) => store.auth);
    const cellCurrentAction = useSelector((store) =>
        getActionFromObject(store.userActions.currentActions, metric.key, row)
    );

    const cellPendingAction = useSelector((store) =>
        getActionFromObject(store.userActions.pendingActions, metric.key, row)
    );

    const [editing, setEditing] = useState(false);
    const [newValue, setNewValue] = useState(value || 0);

    const rowBusiness = row[FIELD.BUSINESS_KEY];
    const canUserEdit = hasUserPermissions('editor', user, rowBusiness ? [rowBusiness] : []);

    const currentValue = cellPendingAction
        ? cellPendingAction.newValue
        : cellCurrentAction
        ? cellCurrentAction.newValue
        : value;

    const displayingValue = cellPendingAction
        ? getMetricCellValues(cellPendingAction.newValue, metric).value
        : cellCurrentAction
        ? getMetricCellValues(cellCurrentAction.newValue, metric).value
        : displayValue;

    useEffect(() => {
        setNewValue(currentValue);
    }, [editing, currentValue]);

    const onSaveNewValue = (actionType) => {
        if (currentValue !== newValue && onSave) {
            const paramKey = getActionParamKey(actionType);
            if (paramKey) {
                onSave(actionType, { [paramKey]: newValue });
            }
        }
        setEditing(false);
    };

    const onStatusSwitchChange = (checked) => {
        onSave('status', { newAdsetStatus: checked ? dashboardContext.status.ACTIVE : dashboardContext.status.PAUSED });
    };

    const onCampaignStatusSwitch = (checked) => {
        onSave('status', {
            newCampaignStatus: checked ? dashboardContext.status.ACTIVE : dashboardContext.status.PAUSED,
        });
    };

    const showHistoryModal = (adsetId) => {
        dispatch(fetchAdsetHistory(adsetId));
    };

    // disable editing status for now
    const isEditing = editing && metric.key !== FIELD.ADSET_STATUS_KEY;

    const InputComponent = getInputComponent(metric);
    return (
        <div
            className={
                className +
                (isEditing ? ' editing' : '') +
                (cellCurrentAction ? ' current-action' : '') +
                (cellPendingAction ? ' pending-action' : '')
            }
            onClick={onCellClick}
            onDoubleClick={cellPendingAction || !canUserEdit ? null : () => setEditing(true)}
        >
            {isEditing ? (
                <InputComponent
                    value={newValue}
                    onChange={setNewValue}
                    onSaveNewValue={onSaveNewValue}
                    onCancel={() => setEditing(false)}
                />
            ) : metric.key === FIELD.ADSET_STATUS_KEY ? (
                <Space>
                    <Switch
                        size="small"
                        onChange={canUserEdit ? onStatusSwitchChange : null}
                        checked={displayingValue === dashboardContext.status.ACTIVE}
                    />
                    <HistoryOutlined onClick={() => showHistoryModal(row.adsetId)} />
                </Space>
            ) : metric.key === 'campaignEffectiveStatus' ? (
                <Switch
                    size="small"
                    onChange={canUserEdit ? onCampaignStatusSwitch : null}
                    checked={displayingValue === dashboardContext.status.ACTIVE}
                />
            ) : (
                displayingValue
            )}
        </div>
    );
};

export default EditableCell;
