import * as actions from 'store/actions';
import store from 'store/store';
import {
    fetchCampaignAdSetsData,
    preloadCampaignAdSetsData,
    preloadAdsetAdsData,
    fetchAdsetAdsData,
} from 'service/campaigns.service';
import { notification } from 'antd';

export const changeCampaignLoadingStatus = () => {
    return {
        type: actions.CAMPAIGN_DATA_LOADING,
    };
};

export const changeCampaignModalVisibility = () => {
    return {
        type: actions.CHANGE_CAMPAIGN_MODAL_VISIBILITY,
    };
};

export const setCampaignDataLoading = (loading) => {
    return {
        type: actions.CAMPAIGN_DATA_LOADING,
        loading,
    };
};

export const setLimitsDataLoading = (limitsLoading) => {
    return {
        type: actions.LIMITS_LOADING,
        limitsLoading,
    };
};

export const setCampaignAdSetsData = (adsets) => {
    return {
        type: actions.SET_CAMPAIGN_ADSETS_DATA,
        adsets,
    };
};

export const setAdsetAdsData = (ads) => {
    return {
        type: actions.SET_CAMPAIGN_ADS_DATA,
        ads,
    };
};

export const changeCampaignModalName = (modalName) => {
    return {
        type: actions.CHANGE_CAMPAIGN_MODAL_NAME,
        modalName,
    };
};

export const changeCampaignModalType = (modalType) => {
    return {
        type: actions.CHANGE_CAMPAIGN_MODAL_TYPE,
        modalType,
    };
};

export const setAdsLimits = (limits) => {
    return {
        type: actions.SET_ADS_LIMiTS,
        adsLimits: limits,
    };
};

export const clearAdsLimits = () => {
    return {
        type: actions.SET_ADS_LIMiTS,
        adsLimits: {},
    };
};

export const changeAdsLimits = (adsetId, value) => async (dispatch) => {
    const currentLimits = store.getState().campaignData.adsLimits;
    if (currentLimits[adsetId]) currentLimits[adsetId] += value;
    else currentLimits[adsetId] = value;

    dispatch(setAdsLimits(currentLimits));
};

export const changedAccountLimits = (accountId, value) => async (dispatch) => {
    const currentLimits = store.getState().campaignData.accountLimits;
    if (currentLimits[accountId]) currentLimits[accountId] += value;
    else currentLimits[accountId] = value;

    dispatch(setAccountLimits(currentLimits));
};

export const setAccountLimits = (limits) => {
    return {
        type: actions.SET_ACCOUNT_LIMITS,
        accountLimits: limits,
    };
};

export const getCampaignAdSetsData = (campaignName, date) => async (dispatch) => {
    try {
        dispatch(setCampaignAdSetsData([]));
        dispatch(clearAdsLimits());
        dispatch(changeCampaignModalName(campaignName));
        dispatch(setCampaignDataLoading(true));
        dispatch(setLimitsDataLoading(true));

        const preloadedData = (await preloadCampaignAdSetsData(campaignName, date))?.data || [];
        if (!preloadedData?.clientAdsetData?.length) throw new Error('Failed to get adsets data');
        dispatch(setCampaignAdSetsData(preloadedData?.clientAdsetData || []));
        dispatch(setCampaignDataLoading(false));

        const data = (await fetchCampaignAdSetsData(campaignName, date))?.data || [];
        dispatch(setCampaignAdSetsData(data?.clientAdsetData || []));
        if (!data?.clientAdsetData?.length) throw new Error('Failed to get adsets data');
        if (data?.fbFetchError) {
            notification.error({
                message: 'Error',
                description: 'Failed to fetch Facebook Ads Limits',
            });
        }
        dispatch(setLimitsDataLoading(false));
    } catch (error) {
        dispatch(setCampaignDataLoading(false));
        dispatch(setLimitsDataLoading(false));
        console.error('Action failed getCampaignAdSetsData', error);
        notification.error({
            message: 'Error',
            description: 'Failed to get ads data',
        });
    }
};

export const getCampaignAdsData = (campaignName) => async (dispatch) => {
    try {
        setCampaignDataLoading(true);
        const adsData = [];

        setCampaignDataLoading(false);
    } catch (error) {
        setCampaignDataLoading(false);
        console.error('Action failed getCampaignAdsData', error);
    }
};

export const getAdsetAdsData = (adsetName, date) => async (dispatch) => {
    try {
        dispatch(changeCampaignModalType('ad'));
        dispatch(setCampaignDataLoading(true));
        dispatch(setAdsetAdsData([]));
        dispatch(changeCampaignModalName(adsetName));
        dispatch(setLimitsDataLoading(true));

        const preloadedData = (await preloadAdsetAdsData(adsetName, date))?.data || [];
        console.info('Preloaded Ads Data', preloadedData);
        dispatch(setAdsetAdsData(preloadedData?.clientAdsData || []));
        if (!preloadedData?.clientAdsData?.length) throw new Error('Failed to get ads data');
        dispatch(setCampaignDataLoading(false));

        const data = (await fetchAdsetAdsData(adsetName, date))?.data || [];
        console.info('Ads Data With Limits', data);
        dispatch(setAdsetAdsData(data?.clientAdsData || []));
        if (!data?.clientAdsData?.length) throw new Error('Failed to get ads data');
        if (data?.fbFetchError) {
            notification.error({
                message: 'Error',
                description: 'Failed to fetch Facebook Ads Limits',
            });
        }
        dispatch(setLimitsDataLoading(false));
    } catch (error) {
        dispatch(setCampaignDataLoading(false));
        console.error('Action failed getAdsetAdsData', error);
        notification.error({
            message: 'Error',
            description: 'Failed to get ads data',
        });
    }
};
