import React, { useEffect, useState, useContext } from 'react';
import _ from 'lodash';
import { Modal, Button, Empty, Spin, Tooltip, Collapse } from 'antd';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { fetchActionsStatus, retryActions } from 'store/userActions/actionCreators';
import AccessByRole from 'components/Permission/AccessByRole';
import DashboardContext from 'context/dashboardContext';

import './index.css';

const title_mapper = {
    campaign: 'Campaign actions',
    adset: 'Adset actions',
    ad: 'Ad actions',
};

const getItemParams = (itemType, item) => {
    if (itemType === 'adset') {
        const params = {
            itemId: item.ad_set_id,
            itemName: item.ad_set_name,
        };
        if (item.action === 'bid') {
            params.fromValue = item.old_bid;
            params.toValue = item.bid;
            params.tooltip = `From $${params.fromValue} to $${params.toValue}`;
        } else if (item.action === 'status') {
            params.fromValue = item.oldAdsetStatus;
            params.toValue = item.newAdsetStatus;
        }

        return params;
    }
    if (itemType === 'campaign') {
        const params = {
            itemId: item.campaign_id,
            itemName: item.campaign_name,
        };
        if (item.action === 'budget') {
            params.fromValue = item.old_budget;
            params.toValue = item.budget;
            params.tooltip = `From $${params.fromValue} to $${params.toValue}`;
        }

        return params;
    }

    if (itemType === 'ad') {
        const params = {
            itemId: item.ad_id,
            itemName: item.ad_name,
            action: item.action,
            accountId: item.account_id,
        };

        if (item.action === 'status') {
            params.fromValue = item.oldAdStatus;
            params.toValue = item.newAdStatus;
        }

        return params;
    }
};

const InProgressStatus = () => {
    return (
        <div className="status-item-status in-progress">
            <div className="status-title">In progress</div>
            <div className="status-spin-container">
                <Spin size="small" spinning />
            </div>
        </div>
    );
};

const FinishedStatus = ({ error }) => {
    return (
        <div className={'status-item-status finished' + (error ? ' with-error' : '')}>
            <div className="status-title">Finished</div>
            <div className="status-error">{error}</div>
        </div>
    );
};

const ActionListItemStatus = (props) => {
    const { actionId, actionItemId, newItemName, seeDetails, error, finished } = props;
    const [retryLoading, setRetryLoading] = useState(false);
    const dispatch = useDispatch();

    const onRetry = async () => {
        setRetryLoading(true);
        await dispatch(retryActions(actionId, [actionItemId]));
        await dispatch(fetchActionsStatus());
        setRetryLoading(false);
    };

    if (!finished) {
        return (
            <div className="action-list-item-right center">
                <InProgressStatus />
            </div>
        );
    }

    if (error) {
        return (
            <div className="action-list-item-right">
                <div className="error-wrapper">
                    <Tooltip title={error} placement="topLeft">
                        <span className="error-label">{error}</span>
                    </Tooltip>
                    <AccessByRole role="editor" companies={[props.business]}>
                        <Button type="primary" onClick={onRetry} loading={retryLoading}>
                            Retry
                        </Button>
                    </AccessByRole>
                </div>
            </div>
        );
    }

    if (seeDetails) {
        return <div className="action-list-item-right left">{seeDetails}</div>;
    }

    if (newItemName) {
        return (
            <div className="action-list-item-right left">
                <Tooltip title={newItemName} placement="topLeft">
                    <span>{newItemName}</span>
                </Tooltip>
            </div>
        );
    }

    return (
        <div className="action-list-item-right center">
            <FinishedStatus />
        </div>
    );
};

const ActionListItemAction = (props) => {
    const { action, tooltip, toValue } = props;
    const dashboardContext = useContext(DashboardContext);
    const showDescription = action === 'bid' || action === 'budget';
    let actionName = action;
    if (action === 'status') {
        if (toValue === dashboardContext.status.ACTIVE) actionName = 'Activate';
        if (toValue === dashboardContext.status.PAUSED) actionName = 'Pause';
    }
    return (
        <div>
            <div className={'action-item-action ' + action + (showDescription ? ' top-offset' : '')}>{actionName}</div>
            {showDescription && (
                <Tooltip title={tooltip} placement="topLeft">
                    <div className="action-item-budget-description">{tooltip}</div>
                </Tooltip>
            )}
        </div>
    );
};

const ExpandedCreatives = ({ ads = [] }) => {
    return ads.map((ad) => {
        return (
            <div className="expanded-creative-row" key={ad.ad_id}>
                <div>Creative:</div>
                <div>
                    <Tooltip title={`${ad.ad_name} (${ad.ad_id})`}>
                        {ad.ad_name} ({ad.ad_id})
                    </Tooltip>
                </div>
                <div>
                    <ArrowRightOutlined />
                </div>
                <div>
                    <Tooltip title={ad.new_ad_name}>{ad.new_ad_name}</Tooltip>
                </div>
            </div>
        );
    });
};

const ActionListItem = (props) => {
    const { itemName, itemId, ads, adsets } = props;

    const lineRow = (
        <div className="action-list-item">
            <div className="action-list-item-left">
                <div className="action-item-name">
                    {itemName} ({itemId})
                </div>
                <ActionListItemAction {...props} />
            </div>
            <ActionListItemStatus {...props} />
        </div>
    );

    let expandedContent;
    if (props.finished && !props.error) {
        if (adsets && adsets.length) {
            expandedContent = 1231313213123;
        } else if (ads && ads.length) {
            expandedContent = <ExpandedCreatives ads={ads} />;
        }
    }
    if (!expandedContent) return lineRow;

    return (
        <Collapse className="action-list-collapse" ghost>
            <Collapse.Panel header={lineRow}>
                <div className="expanded-content-wrapper">{expandedContent}</div>
            </Collapse.Panel>
        </Collapse>
    );
};

const ActionsList = (props) => {
    const { _id: actionId, items = [] } = props.action;
    const grouped = _.groupBy(items, 'itemType');

    return Object.keys(title_mapper)
        .filter((key) => grouped[key]?.length)
        .map((key) => {
            return (
                <div key={key} className="action-group">
                    <div className="action-group-title">
                        <Button style={{ flex: 1 }} icon={<ArrowLeftOutlined />} onClick={() => props.onBackArrow()} />
                        <div style={{ flex: 30 }}>{title_mapper[key]}</div>
                    </div>
                    <div className="action-list">
                        {_.orderBy(grouped[key], (r) => getItemParams(key, r).itemName).map((act) => (
                            <ActionListItem
                                key={act._id}
                                actionId={actionId}
                                actionItemId={act._id}
                                action={act.action}
                                error={act.error}
                                finished={act.finished}
                                business={act.business}
                                {...getItemParams(key, act)}
                            />
                        ))}
                    </div>
                </div>
            );
        });
};

const DateActionStatus = ({ items = [] }) => {
    const finishedAll = items.every((item) => item.finished);

    if (!finishedAll) {
        return <InProgressStatus />;
    }

    const errorsCount = items.filter((i) => i.error).length;
    const errorsText = errorsCount ? `${errorsCount} ${errorsCount > 1 ? 'errors' : 'error'}` : '';

    return <FinishedStatus error={errorsText} />;
};

const DatesList = (props) => {
    const { status = {} } = props;

    if (!Object.keys(status).length) return <Empty style={{ margin: '30px' }} description="No actions" />;

    const list = _.orderBy(Object.values(status), ['unixDate'], ['desc']);
    const list1 = []; //list.map((l, i) => ({ ...l, _id: l._id + i }))
    const list2 = []; //list.map((l, i) => ({ ...l, _id: l._id + i + 1}))
    const list3 = []; //list.map((l, i) => ({ ...l, _id: l._id + i + 2 }))

    return (
        <div className="status-dates-list">
            {list
                .concat(list1)
                .concat(list2)
                .concat(list3)
                .map((item) => {
                    const { _id, date, items = [] } = item;
                    return (
                        <div key={_id} className="status-date-item" onClick={() => props.onOpen(_id)}>
                            <div className="status-item-date">{date}</div>
                            <div className="status-item-count">
                                {items.length} {items.length > 1 ? 'actions' : 'action'}
                            </div>
                            <DateActionStatus items={items} />
                        </div>
                    );
                })}
        </div>
    );
};

export const ModalContent = (props) => {
    const { status = {}, isFetchingStatus } = useSelector((store) => store.userActions);

    const [selectedAction, setSelectedAction] = useState(null);

    const onSelectDate = (key) => {
        setSelectedAction(key);
        // if (status[key]) {
        //     props.setTitle(
        //         <div className="popup-title-with-btn">
        //             <Button icon={<ArrowLeftOutlined />} onClick={() => onSelectDate()} />
        //             <div>Actions status {status[key].date}</div>
        //         </div>
        //     );
        // } else {
        //     props.setTitle();
        // }
    };

    return (
        <Spin spinning={isFetchingStatus}>
            {selectedAction ? (
                <div>
                    <ActionsList onBackArrow={() => onSelectDate()} action={status[selectedAction]} />
                </div>
            ) : (
                <DatesList status={status} onOpen={onSelectDate} />
            )}
        </Spin>
    );
};

const StatusActionsModal = (props) => {
    const defaultTitle = 'Actions status';
    const [popupTitle, setPopupTitle] = useState(defaultTitle);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchActionsStatus());
    }, []);

    return (
        <Modal
            title={popupTitle}
            footer={false}
            maskClosable={false}
            className="actions-status-modal"
            onCancel={props.onClose}
            visible
        >
            <ModalContent setTitle={(t) => setPopupTitle(t || defaultTitle)} />
        </Modal>
    );
};

export default StatusActionsModal;
