import React from 'react';
import _ from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { Checkbox } from 'antd';
import * as FIELD from 'data/fields';
import { bulkSelectionToggleRows } from 'store/userActions/actionCreators';
import { rowPendingActionSelector } from 'util/currentPendingActions';
import { hasUserPermissions } from 'util/permissions';

const BulkSelectCell = (props) => {
    const { row = {} } = props;
    const rowId = row[FIELD.ID_KEY];

    const isRowSelected = useSelector((store) => !!store.userActions.bulkSelectedRowIds?.[rowId]);
    const isFetchingPendingActions = useSelector((store) => !!store.userActions.pendingActionsFetching);
    const rowPendingAction = useSelector((store) => rowPendingActionSelector(row)(store.userActions.pendingActions));
    const rowItems = useSelector((store) => store.dashboard.filteredData);
    const sorting = useSelector((store) => store.dashboard.sorting);
    const rowSelectedItems = useSelector((store) => store.userActions.bulkSelectedRowIds);
    const { user } = useSelector((store) => store.auth);

    const dispatch = useDispatch();

    const isRowDisabled = !!(isFetchingPendingActions || rowPendingAction);
    const rowBusiness = row[FIELD.BUSINESS_KEY];
    const canUserEdit = hasUserPermissions('editor', user, rowBusiness ? [rowBusiness] : []);

    const onChangeSelect = (event, checked) => {
        const itemsToChange = [];
        const { shiftKey } = event.nativeEvent;
        if (rowSelectedItems && rowItems && shiftKey) {
            if (checked) {
                const sortFields = sorting ? Object.keys(sorting) : [];
                const sortValues = sorting ? Object.values(sorting) : [];
                const items = _.orderBy(rowItems, sortFields, sortValues);

                const selectedItemsIndexes = Object.keys(rowSelectedItems).map((e) => {
                    return _.findIndex(items, function (i) {
                        return i[FIELD.ID_KEY] === e;
                    });
                });

                const currentItemIndex = _.findIndex(items, function (item) {
                    return item[FIELD.ID_KEY] === rowId;
                });

                const lastSelectedItemIndex = selectedItemsIndexes.pop();

                if (lastSelectedItemIndex > currentItemIndex) {
                    for (let index = currentItemIndex; index <= lastSelectedItemIndex; index++) {
                        itemsToChange.push(items[index][FIELD.ID_KEY]);
                    }
                }

                if (currentItemIndex > lastSelectedItemIndex) {
                    for (let index = currentItemIndex; index >= lastSelectedItemIndex; index--) {
                        if (!!rowSelectedItems[items[index][FIELD.ID_KEY]]) {
                            break;
                        }
                        itemsToChange.push(items[index][FIELD.ID_KEY]);
                    }
                }
            }

            // if (!checked) {
            //     const selectedItemsIndexes = Object.keys(rowSelectedItems).map((e) => {
            //         const itemIndex = _.findIndex(rowItems, function (i) {
            //             return i[FIELD.ID_KEY] === e;
            //         });
            //         return {[itemIndex]: e}
            //     });

            //     const currentItemIndex = _.findIndex(rowItems, function (item) {
            //         return item[FIELD.ID_KEY] === rowId;
            //     });
            //     //15 5
            //     if (currentItemIndex > lastDisabled) {
            //         selectedItemsIndexes.map((item) => {
            //             const itemIndex = Object.keys(item)[0]
            //             if (itemIndex > lastDisabled && itemIndex <= currentItemIndex) itemsToChange.push(item[itemIndex])
            //         })
            //     }

            // }
        }

        if (itemsToChange.length) {
            _.forEach(itemsToChange, function (value) {
                dispatch(bulkSelectionToggleRows([value], checked));
            });
            return;
        }
        // if (!checked) {
        //     const lastDisabledIndex = _.findIndex(rowItems, function (i) {
        //         return i[FIELD.ID_KEY] === rowId;
        //     });
        //     setLastDisabled(lastDisabledIndex)
        // }
        dispatch(bulkSelectionToggleRows([rowId], checked));
    };

    return (
        <div style={{ textAlign: 'center' }}>
            <Checkbox
                checked={isRowSelected}
                disabled={isRowDisabled || !canUserEdit}
                onChange={(e) => onChangeSelect(e, !!e.target.checked)}
            />
        </div>
    );
};

export default BulkSelectCell;
