import _ from 'lodash';

/** check if user's role is higher than or the same as required role */
const checkRole = (requiredRole, userRole) => {
    if (!requiredRole) return true;
    if (!userRole) return false;

    if (requiredRole === 'admin') {
        return userRole === 'admin';
    }
    if (requiredRole === 'editor') {
        return userRole === 'admin' || userRole === 'editor';
    }
    if (requiredRole === 'viewer') {
        return userRole === 'admin' || userRole === 'editor' || userRole === 'viewer';
    }
};

const hasUserGlobalPermissions = (requiredRole, user) => {
    return checkRole(requiredRole, user.role);
};

const hasUserCompanyPermissions = (requiredRole, user, companyIds) => {
    const userCompaniesHash = _.keyBy(user.organizations, 'name');
    // check that user has the requiredRole in every company
    return (companyIds || []).every((companyId) => checkRole(requiredRole, userCompaniesHash[companyId]?.role));
};

export const hasUserPermissions = (requiredRole, user, companyIds) => {
    // check global role
    if (hasUserGlobalPermissions(requiredRole, user)) return true;

    // if companyIds is specified - check user's role in particular companies
    if (companyIds?.length) return hasUserCompanyPermissions(requiredRole, user, companyIds);

    // if companyIds is not specified - check user's role in any company
    return user.organizations.some((org) => checkRole(requiredRole, org.role));
};
