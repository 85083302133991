import React, { useContext } from 'react';
import _ from 'lodash';
import { Modal, Empty, Table, Button, Tag, Space, Descriptions, Typography } from 'antd';
import { DeleteOutlined, PlayCircleOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import {
    togglePresetsModalVisibility,
    applyPreset,
    deletePreset,
    lockEditing,
} from 'store/currentSelection/actionCreators';
import { operandsHash } from 'data/filterOperands';
import DashboardContext from 'context/dashboardContext';

const { Text } = Typography;

const ActionsRow = (props) => {
    const dashboardContext = useContext(DashboardContext);
    const { id } = useSelector((store) => store.auth.user);
    const isDataFetching = useSelector((store) => store.dashboard.isDataFetching);
    const { preset } = props;
    const dispatch = useDispatch();

    const onRun = async () => {
        dispatch(applyPreset(dashboardContext, preset, true));
        dispatch(togglePresetsModalVisibility());
        // Guy and Ido auth0 accounts as exception for editing
        dispatch(
            lockEditing(
                !['auth0|62d42e4dbf0c8b46ce5815e6', 'auth0|62f55dd1276e28eb38ceee87', id].includes(preset.author)
            )
        );
    };

    const onDelete = () => {
        dispatch(deletePreset(preset?.id, dashboardContext.name));
    };

    return (
        <Space>
            <Button onClick={onRun} disabled={isDataFetching} icon={<PlayCircleOutlined />}>
                Run
            </Button>
            <Button disabled={preset.author !== id} onClick={onDelete} danger icon={<DeleteOutlined />} />
        </Space>
    );
};

const cleanFilters = (filters, filterOptions) => {
    if (!filters) return false;

    const filterOptionsKeys = Object.keys(filterOptions);
    const strictOperands = ['one_of', 'not_one_of'];
    const cleanedFilters = filters.map((filter) => {
        if (!filterOptionsKeys.includes(filter.field)) return filter;

        const cleanedItems = [];
        for (const item of filter.items) {
            if (!strictOperands.includes(item.operand)) cleanedItems.push(item);

            if (_.isArray(item.value)) {
                const val = item.value.filter((v) => filterOptions[filter.field].includes(v));
                if (val && val.length)
                    cleanedItems.push({
                        key: item.key,
                        operand: item.operand,
                        value: val,
                    });
            } else {
                if (filterOptions[filter.field].includes(item.value)) cleanedItems.push(item);
            }
        }
        return {
            field: filter.field,
            items: cleanedItems,
        };
    });
    return cleanedFilters;
};



const PresetsTable = (props) => {
    const dashboardContext = useContext(DashboardContext);
    const { data } = props;
    const filterOptions = useSelector((store) => store.currentSelection.filterOptions);
    const loading = useSelector((store) => store.currentSelection.presetsLoading);
    
    if (!data || !data.length) return <Empty />;

    const filters = _.uniqBy(data,'title').map(f=>({text: f.title, value: f.title}))
    const columns = [
        {
            title: 'Type',
            render: (val, row) => (
                <Tag color={row.shared ? 'geekblue' : 'green'}>{row.shared ? 'Shared' : 'Personal'}</Tag>
            ),
            align: 'center',
            width: 60,
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            align: 'center',
            width: 300,
            sorter: (a, b) => a.title.localeCompare(b.title),
            filterSearch: true,
            filters, 
            onFilter: (value, record) => record.title.includes(value),
        },
        {
            title: 'Actions',
            align: 'center',
            render: (val, row) => <ActionsRow preset={row} />,
        },
    ];

    return (
        <Table
            dataSource={data}
            columns={columns}
            rowKey={'id'}
            bordered={false}
            pagination={false}
            loading={loading}
            expandable={{
                expandedRowRender: (record) => (
                    <Descriptions title="Preset details" bordered layout="vertical" column={2}>
                        <Descriptions.Item label="Dimensions">
                            <Space wrap>
                                {record.dimensions.map((item) => (
                                    <Tag color="cyan">{dashboardContext.allDimensionsHash[item].title}</Tag>
                                ))}
                            </Space>
                        </Descriptions.Item>
                        <Descriptions.Item label="Metrics">
                            <Space wrap>
                                {record.metrics.map((item) => (
                                    <Tag color="purple">{dashboardContext.allDimensionsHash[item].title}</Tag>
                                ))}
                            </Space>
                        </Descriptions.Item>
                        {!!_.map(record?.filters)?.length && (
                            <Descriptions.Item label="Filters">
                                <Space direction="vertical">
                                    {cleanFilters(_.map(record?.filters), filterOptions)?.map((item) => {
                                        return (
                                            <Text code>
                                                {dashboardContext.allDimensionsHash[item.field].title}
                                                {item.items.map((i) => {
                                                    return (
                                                        <>
                                                            {' '}
                                                            {operandsHash[i.operand].label} {i.value.toString()}
                                                        </>
                                                    );
                                                })}
                                            </Text>
                                        );
                                    })}
                                </Space>
                            </Descriptions.Item>
                        )}
                    </Descriptions>
                ),
                rowExpandable: (record) => true,
            }}
        />
    );
};

const PresetsList = () => {
    const presets = useSelector((store) => store.currentSelection.presets);

    return <PresetsTable data={presets} />;
};

const DashboardPresetsModal = () => {
    const dispatch = useDispatch();
    const visible = useSelector((store) => store.currentSelection.presetModalVisible);

    const onClose = () => {
        dispatch(togglePresetsModalVisibility());
    };

    return (
        <Modal visible={visible} title={'Report presets'} onCancel={onClose} footer={false} width={750}>
            <PresetsList />
        </Modal>
    );
};

export default DashboardPresetsModal;
