import React, { useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Button, Divider, Spin } from 'antd';
import { ClearOutlined } from '@ant-design/icons';
import CurrentSelection from 'components/CurrentSelection';
import { runReports } from 'util/runReports';
import { quickRanges } from 'util/quickRanges';
import { removeFilters, setRangeDate } from 'store/currentSelection/actionCreators';
import DashboardContext from 'context/dashboardContext';

import './index.css';

import { isSameRange } from 'util/isSameRange';

const DashboardFilters = ({ style }) => {
    const dashboardContext = useContext(DashboardContext);
    const isDataFetching = useSelector((store) => store.dashboard.isDataFetching);
    const date = useSelector((store) => store.currentSelection.date);
    const dispatch = useDispatch();

    const onRun = (forceUpdate) => {
        const { preset, range = [] } = date || {};
        const newRange = quickRanges()[preset];

        // if preset range is not the same as range in redux, update date in redux and run report
        if (newRange && range && !isSameRange(range, newRange)) {
            dispatch(setRangeDate({ range: newRange, preset }));
            setTimeout(() => {
                dispatch(runReports(dashboardContext, forceUpdate));
            }, 1000);
        } else {
            dispatch(runReports(dashboardContext, forceUpdate));
        }
    };

    const onClearAll = () => {
        dispatch(removeFilters({ all: true }));
    };

    return (
        <div className="dashboard-select-columns" style={style}>
            <Spin spinning={isDataFetching}>
                <Divider>Filters</Divider>
                <div className="dashboard-columns-filters-block">
                    <CurrentSelection />
                    <div className="dashboard-columns-controls">
                        <Button
                            className="run-report-button"
                            type="primary"
                            onClick={isDataFetching ? null : () => onRun()}
                        >
                            Filter
                        </Button>
                        <Button type="ghost" onClick={onClearAll}>
                            Clear filters
                            <ClearOutlined />
                        </Button>
                    </div>
                </div>
            </Spin>
        </div>
    );
};

export default DashboardFilters;
