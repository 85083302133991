import _ from 'lodash';
import dayjs from 'dayjs';
import { notification, message } from 'antd';
import * as actions from 'store/actions';
import store from 'store/store';
import ActionsService from 'service/actions.service';
import { rowPendingActionSelector } from 'util/currentPendingActions';
import { getActionRowKey, getActionFromRow, calculateNewBidBudget } from 'util/actionsHelper';
import { STYLE, addDynamicStyle, removeDynamicStyle } from 'util/dynamicStyles';
import { hasUserPermissions } from 'util/permissions';
import * as FIELD from 'data/fields';
import { dataLoadingState } from 'store/dashboard/actionCreators';

const mapActionType = (actionType) => {
    if (actionType === 'copy') return 'duplicate';
    return actionType;
};

export const runActions = (isTaboola) => async (dispatch) => {
    try {
        const actionsList = store.getState().userActions.actions || [];
        if (!actionsList.length) throw new Error('No actions selected');

        dispatch({
            type: actions.ACTIONS_RUNNING_SET,
            value: true,
        });

        const resp = await ActionsService.runActions(actionsList, isTaboola);
        if (resp.error) throw new Error(resp.error);

        notification.info({
            message: 'Actions are running',
            description: "You will be notified when it's done",
        });

        dispatch(removeActions({ all: true }));

        return true;
    } catch (err) {
        console.error('runActions error', err);
        notification.error({
            message: 'Failed to run actions',
            description: err.message,
        });
    } finally {
        dispatch({
            type: actions.ACTIONS_RUNNING_SET,
            value: false,
        });
    }
};

export const toggleAction =
    (action, validateChannelAvailability = false) =>
    async (dispatch) => {
        const actionsList = store.getState().userActions.actions || [];
        const newActionRowKey = getActionRowKey(action);

        const existingAction = actionsList.find((act) => getActionRowKey(act) === newActionRowKey);

        if (existingAction) {
            if (action.action === 'bid' || action.action === 'budget' || action.action === 'status') {
                dispatch(updateAction({ ...existingAction, ...action }));
                return true;
            }
            dispatch(removeActions({ keys: [existingAction.key] }));
            return false;
        }

        if (validateChannelAvailability && action.action === 'status' && action.newAdsetStatus === 'ACTIVE') {
            dispatch(dataLoadingState(true));
            const actionAvailable = await checkActionAvailability(action);
            dispatch(dataLoadingState(false));
            if (actionAvailable !== true) {
                return false;
            }
        }

        dispatch(addActions(action));
        return true;
    };

export const addActions = (action) => {
    const actionsToAdd = _.isArray(action) ? action : [action];
    const newActions = [];

    for (const action of actionsToAdd) {
        const key = (Math.random() * Math.random()).toString().slice(2, 15);
        const newAction = {
            key,
            ...action,
        };
        // add button background
        const rowKey = getActionRowKey(newAction);
        newAction.rowKey = rowKey;
        addDynamicStyle(rowKey, `.${rowKey}`, STYLE.selectedActionButton);

        newActions.push(newAction);
    }

    return { type: actions.ACTION_ADD, newActions };
};

export const removeActions = ({ keys, all }) => {
    const actionsList = store.getState().userActions.actions || [];
    const keysSet = new Set(keys);
    const actionsToRemove = all ? actionsList : actionsList.filter((act) => keysSet.has(act.key));

    for (const act of actionsToRemove) {
        // remove button background for removed actions
        removeDynamicStyle(getActionRowKey(act));
    }

    return {
        type: actions.ACTION_REMOVE,
        keys: actionsToRemove.map((act) => act.key),
    };
};

export const updateAction = (newAction) => {
    return {
        type: actions.ACTION_CHANGE,
        newAction,
    };
};

export const toggleRefreshDataPopup = (show = false) => {
    return {
        type: actions.SET_REFRESH_DATA_POPUP,
        show,
    };
};

const checkActionAvailability = async (action) => {
    try {
        const resp = await ActionsService.getActionAvailability(action);
        if (resp?.data?.available !== true) {
            notification.warn({
                message: `Action blocked`,
                duration: 0,
                description: `
                    Action on adset ${action.ad_set_name} was blocked!\n\n\n
                    ${resp?.data?.message || 'Unknown reason'}
                    `,
            });
            console.info('Action blocked', resp?.data);
            return false;
        }
        return true;
    } catch (err) {
        console.error('checkActionAvailability error', err);
        notification.error({
            message: 'Failed to check action availability',
            description: err.message,
        });
        return false;
    }
};

export const updateActionsStatus = (data = [], force = false) => {
    const list = data.map((item) => ({
        ...item,
        date: dayjs(item.date).format('YYYY-MM-DD HH:mm:ss'),
        unixDate: dayjs(item.date).unix(),
        items: (item.items || []).map((it) => ({
            ...it,
            action: mapActionType(it.action),
        })),
    }));
    const status = _.keyBy(list, '_id');
    return {
        type: actions.ACTIONS_STATUS_SET,
        status,
        force,
    };
};

export const fetchActionsStatus = () => async (dispatch) => {
    try {
        dispatch({
            type: actions.ACTIONS_STATUS_FETCHING_SET,
            fetching: true,
        });

        const resp = await ActionsService.getStatus();
        if (resp.error) throw new Error(resp.error);

        dispatch(updateActionsStatus(resp.data, true));

        dispatch({
            type: actions.ACTIONS_STATUS_FETCHING_SET,
            fetching: false,
        });
    } catch (err) {
        console.error('fetchActionsStatus error', err);
        notification.error({
            message: 'Failed to get status',
            description: err.message,
        });
    }
};

export const updatePendingActions =
    (data = [], force = false) =>
    (dispatch) => {
        dispatch({
            type: actions.PENDING_ACTIONS_SET,
            actions: data,
            force,
        });

        // deselect rows
        // const deselectRowIds = data.map((item) => item.rowId);
        // dispatch(bulkSelectionToggleRows(deselectRowIds, false));

        // remove current actions
        // const rowIdsSet = new Set(deselectRowIds);
        // const actionsList = store.getState().userActions.actions || [];
        // const actionKeysToRemove = actionsList
        //     .filter((action) => rowIdsSet.has(action.rowId))
        //     .map((action) => action.key);
        // dispatch(removeActions({ keys: actionKeysToRemove }));
    };

export const fetchPendingActions = () => async (dispatch) => {
    try {
        dispatch({
            type: actions.PENDING_ACTIONS_STATUS_FETCHING_SET,
            fetching: true,
        });

        const resp = await ActionsService.getPendingActions();
        if (resp.error) throw new Error(resp.error);

        dispatch(updatePendingActions(resp.data, true));
    } catch (err) {
        console.error('fetchPendingActions error', err);
        notification.error({
            message: 'Failed to get pending actions',
            description: err.message,
        });
    } finally {
        dispatch({
            type: actions.PENDING_ACTIONS_STATUS_FETCHING_SET,
            fetching: false,
        });
    }
};

// FIXME: fix toggle
export const addBulkSelectionAction =
    (action, params = {}, isAdSense = false) =>
    async (dispatch) => {
        const { actionItem, filteredData = [], metrics } = store.getState().dashboard;
        const { bulkSelectedRowIds = {} } = store.getState().userActions;
        const dataHash = _.keyBy(filteredData, FIELD.ID_KEY);
        const selectedRowIds = Object.keys(bulkSelectedRowIds);

        // count added and cancelled actions
        let added = 0;
        let cancelled = 0;

        for (const rowId of selectedRowIds) {
            const row = dataHash[rowId];
            if (row) {
                let actionElement;
                if (action === 'bid') {
                    // Check if bid metric is available
                    if (!metrics.includes(FIELD.BID_KEY)) {
                        notification.warn({
                            message: 'Bid metric is not available',
                            description: 'Please select Bid in metrics list',
                            duration: 15,
                        });
                        continue;
                    }

                    const newBid = calculateNewBidBudget(params.action, params.units, params.value, row[FIELD.BID_KEY]);
                    actionElement = getActionFromRow(actionItem, action, row, { new_bid: newBid });
                } else if (action === 'budget') {
                    // Check if bid metric is available
                    if (!metrics.includes(FIELD.BUDGET_KEY)) {
                        notification.warn({
                            message: 'Budget metric is not available',
                            description: 'Please select Budget in metrics list',
                            duration: 15,
                        });
                        continue;
                    }

                    const newBudget = calculateNewBidBudget(
                        params.action,
                        params.units,
                        params.value,
                        row[FIELD.BUDGET_KEY]
                    );
                    actionElement = getActionFromRow(actionItem, action, row, { new_budget: newBudget });
                } else if (action === 'status') {
                    // Check if status metric is available
                    if (!metrics.includes(FIELD.ADSET_STATUS_KEY)) {
                        notification.warn({
                            message: 'Status metric is not available',
                            description: 'Please select Status in metrics list',
                            duration: 15,
                        });
                        continue;
                    }

                    actionElement = getActionFromRow(actionItem, action, row, params);
                }
                let result;
                if (actionElement) result = await dispatch(toggleAction(actionElement, isAdSense));
                if (result) {
                    added++;
                } else {
                    cancelled++;
                }
            }
        }

        if (added > 0) {
            message.success(`Added ${added} actions`, 1.5);
        }
        if (cancelled > 0) {
            message.warn(`Cancelled ${cancelled} actions`, 1.5);
        }
    };

export const bulkSelectionToggleRows = (rowIds = [], select = false) => {
    const rowIdsSet = new Set(rowIds);

    const { bulkSelectedRowIds = {} } = store.getState().userActions;
    const selectedRowIds = Object.keys(bulkSelectedRowIds);

    const updatedRowIds = select
        ? [...selectedRowIds, ...rowIds]
        : selectedRowIds.filter((rowId) => !rowIdsSet.has(rowId));

    return bulkSelectionSetRows(updatedRowIds);
};

export const bulkSelectionToggleAllRows = (select = false) => {
    const { filteredData = [] } = store.getState().dashboard;
    const { pendingActions = {} } = store.getState().userActions;
    const { user } = store.getState().auth;

    const rowIds = filteredData
        .filter((row) => !rowPendingActionSelector(row)(pendingActions)) // select only rows without pending actions
        // user can edit this row
        .filter((row) => {
            const rowBusiness = row[FIELD.BUSINESS_KEY];
            return hasUserPermissions('editor', user, rowBusiness ? [rowBusiness] : []);
        })
        .map((row) => row[FIELD.ID_KEY]);

    return bulkSelectionToggleRows(rowIds, select);
};

export const bulkSelectionSetRows = (rowIds = []) => {
    return {
        type: actions.MAIN_BULK_ROWS_SET,
        bulkSelectedRowIds: rowIds.reduce((acc, id) => ({ ...acc, [id]: 1 }), {}),
    };
};

export const retryActions =
    (actionId, actionItemIds = []) =>
    async (dispatch) => {
        try {
            if (!actionItemIds.length) throw new Error('No actions selected');

            const resp = await ActionsService.retryActions(actionId, actionItemIds);
            if (resp.error) throw new Error(resp.error);

            notification.info({
                message: 'Actions are running',
                description: "You will be notified when it's done",
            });
        } catch (err) {
            console.error('retryActions error', err);
            notification.error({
                message: 'Failed to run actions',
                description: err.message,
            });
        }
    };

export const setHistoryLoading = () => ({
    type: actions.SET_ADSET_HISTORY_LOADING,
});

export const setAdsetHistory = (history) => ({
    type: actions.SET_ADSET_HISTORY,
    history,
});

export const fetchAdsetHistory = (adsetId) => async (dispatch) => {
    try {
        dispatch(setHistoryLoading());
        const history = await ActionsService.getAdsetHistory(adsetId);
        dispatch(setAdsetHistory(history?.data || []));
    } catch (err) {
        notification.error({
            message: 'Failed to get adset history',
            description: err.message,
        });
        dispatch(setHistoryLoading());
    }
};

// Действие для изменения видимости модального окна истории adset
export const changeAdsetHistoryVisibility = () => ({
    type: actions.CHANGE_ADSET_HISTORY_MODAL_VISIBILITY,
});

export const setAdHistoryLoading = () => ({
    type: actions.SET_AD_HISTORY_LOADING,
});

export const setAdHistory = (adHistory, adId) => ({
    type: actions.SET_AD_HISTORY,
    adHistory,
    adId,
});

export const fetchAdHistory = (adId) => async (dispatch) => {
    try {
        dispatch(setAdHistoryLoading());

        if (store.getState()?.userActions?.adHistory?.[adId]?.length) {
            dispatch(setAdHistoryLoading());
            return;
        }

        const history = await ActionsService.getAdHistory(adId);
        debugger;
        dispatch(setAdHistory(history?.data || [], adId));
        dispatch(setAdHistoryLoading());
    } catch (err) {
        notification.error({
            message: 'Failed to get ad history',
            description: err.message,
        });
        dispatch(setAdHistoryLoading());
    }
};
