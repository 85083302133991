import store from 'store/store';
import { selectMetrics, selectDimensions, removeFilters, disableMetrics } from 'store/currentSelection/actionCreators';
import { addFilters } from 'store/currentSelection/actionCreators';
import { FILTER_OPERAND } from 'data/filterOperands';

export const rankingMetricsSet = new Set(['quality_ranking', 'engagement_rate_ranking', 'conversion_rate_ranking']);

const creativeDimensionsSet = new Set(['creative_id', 'creative']);
const creativeMetrics = [
    'quality_ranking',
    'engagement_rate_ranking',
    'conversion_rate_ranking',
    'ad_effective_status',
];

const lastHourIntervalsSet = new Set(['day', 'week', 'month']);
const lastHourMetrics = [
    'last_hour_roi',
    'last_hour_spend',
    'last_hour_sessions',
    'last_hour_paid_sessions',
    'last_hour_cpr',
    'last_hour_erpr',
    'last_hour_cr',
    'last_hour_ctr',
    'last_hour_pps',
];

const CREATIVE = 'creative';
export const CREATIVE_STATUS = 'ad_effective_status';

const enable = (metricsToEnable = []) => {
    store.dispatch(disableMetrics(metricsToEnable, false));
};

const disable = (metricsToDisable = []) => {
    const metricsToDisableSet = new Set(metricsToDisable);
    const { metrics: selectedMetrics = [], dimensions: selectedDimensions = [] } = store.getState().currentSelection;

    // disable metrics
    store.dispatch(disableMetrics(metricsToDisable, true));

    // remove filters
    store.dispatch(removeFilters({ fields: metricsToDisable }));

    // deselect metrics
    store.dispatch(selectMetrics(selectedMetrics.filter((sm) => !metricsToDisableSet.has(sm))));
    store.dispatch(selectDimensions(selectedDimensions.filter((sd) => !metricsToDisableSet.has(sd))));
};

export const toggleCreativeMetrics = (newDimensions = []) => {
    if (newDimensions.some((d) => creativeDimensionsSet.has(d))) {
        // enable creative metrics
        enable(creativeMetrics);
    } else {
        // disable creative metrics
        disable(creativeMetrics);
    }
};

export const toggleLastHourMetrics = (newInterval) => {
    if (lastHourIntervalsSet.has(newInterval)) {
        // enable last hour metrics
        enable(lastHourMetrics);
    } else {
        // disable last hour metrics
        disable(lastHourMetrics);
    }
};

export const toggleDateDimension = (newInterval) => {
    if (newInterval === 'none') {
        disable(['date']);
    } else {
        enable(['date']);
    }
};

export const toggleCreativeDimension = (checked) => {
    if (checked.includes(CREATIVE)) {
        const { metrics: selectedMetrics } = store.getState().currentSelection;
        const newSelectedMetrics = [...selectedMetrics, CREATIVE_STATUS];
        store.dispatch(selectMetrics(newSelectedMetrics));
        setCreativeStatusFilter();
    }
};

export const toggleCreativeStatus = (checked) => {
    if (checked.includes(CREATIVE_STATUS)) {
        setCreativeStatusFilter();
    }
};

export const setCreativeStatusFilter = () => {
    const filters = [];
    const statusFilter = {
        field: 'ad_effective_status',
        items: [
            {
                operand: FILTER_OPERAND.EQUAL,
                value: 'ACTIVE',
            },
        ],
    };
    filters.push(statusFilter);
    store.dispatch(addFilters(filters));
};
