import React from 'react';

const useEmptyColumn = () => {
    return {
        title: '',
        className: 'grid-cell service-column',
        render: () => <div className="filterable-cell-content"></div>,
        isServiceColumn: true,
    };
};

export default useEmptyColumn;
