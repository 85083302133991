import * as actions from 'store/actions';
import { notification } from 'antd';
import * as SearchCampaignsService from 'service/search-campaigns.service';

export const setAdSenseAdsTargetCampaignId = (selectedCampaignId) => {
    return {
        type: actions.SET_ADSENSE_CAMPAIGN_ID,
        selectedCampaignId,
    };
};

export const setAdSenseDataLoading = (loading) => {
    return {
        type: actions.SET_ADSENSE_ADS_DATA_LOADING,
        loading,
    };
};

export const setAdSenseAdsData = (data) => {
    return {
        type: actions.SET_ADSENSE_ADS_DATA,
        data,
    };
};

export const setAdSenseAdsPopupVisible = () => {
    return {
        type: actions.SET_ADSENSE_ADS_POPUP_VISIBLE,
    };
};

export const fetchAdSenseCampaignAdsData = (campaignId, datesRange) => async (dispatch) => {
    try {
        //set data loading
        dispatch(setAdSenseDataLoading(true));
        //show popup
        const campaignAdsData = await SearchCampaignsService.fetchAdSenseCampaignAdsData({ campaignId, datesRange });
        if (campaignAdsData.error) throw new Error(campaignAdsData.error);
        console.log('campaignAdsData', campaignAdsData);

        dispatch(setAdSenseAdsData(campaignAdsData.data));
    } catch (error) {
        console.error('fetchAdSenseCampaignAdsData error', error);
        notification.error({
            message: 'Failed to fetch campaign ads data',
            description: error.message,
        });
    } finally {
        dispatch(setAdSenseDataLoading(false));
    }
};

export const setAdSenseAdsDateRange = (range) => {
    return {
        type: actions.SET_ADSENSE_ADS_DATE_RANGE,
        range,
    };
};
