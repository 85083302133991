import _ from 'lodash';
import dayjs from 'dayjs';

export const stringSorter =
    (key, defValue = '') =>
    (a, b) =>
        (a[key] || defValue).localeCompare(b[key] || defValue);
export const numberSorter = (key) => (a, b) => {
    return _.toNumber(a[key]) - _.toNumber(b[key]);
};

export const dateSorter = (key) => (a, b) => {
    const diff = dayjs(a[key]).diff(dayjs(b[key]));
    if (_.isNaN(diff)) return -1;
    return diff;
};
