export const STYLE = {
    selectedActionButton: '{color: #1890ff;background: #1890ff1c;}',
};

export const addDynamicStyle = (id, selector, style) => {
    const css = `${selector}${style}`;
    const tag = document.createElement('style');
    tag.innerHTML = css;
    tag.id = id;
    document.head.appendChild(tag);
};

export const removeDynamicStyle = (id) => {
    const style = document.getElementById(id);
    if (style) style.remove();
};
