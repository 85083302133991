import React, { useState } from 'react';
import { Button, Select, InputNumber } from 'antd';
import { BID_BUDGET_ACTIONS, BID_BUDGET_UNITS } from 'data/actions';
// import { useDispatch } from 'react-redux';

const getValuePrefixByAction = (action) => {
    if (action === BID_BUDGET_ACTIONS.INCREASE || action === BID_BUDGET_ACTIONS.DECREASE) {
        return 'by';
    }
    return '';
};

const getUnitsByAction = (action) => {
    if (action === BID_BUDGET_ACTIONS.INCREASE || action === BID_BUDGET_ACTIONS.DECREASE) {
        return [BID_BUDGET_UNITS.DOLLAR, BID_BUDGET_UNITS.PERCENTAGE];
    }
    if (action === BID_BUDGET_ACTIONS.SET) {
        return [BID_BUDGET_UNITS.DOLLAR];
    }
};

const UnitsSelect = (props) => {
    const { value, onChange, options = [] } = props;

    return (
        <Select value={value} onChange={onChange} style={{ width: 70 }}>
            {options.map((opt) => (
                <Select.Option key={opt} value={opt}>
                    {opt}
                </Select.Option>
            ))}
        </Select>
    );
};

const ValueInput = (props) => {
    const { value, onChange, prefix } = props;

    return (
        <div>
            <span className="value-prefix">{prefix || ''}</span>
            <InputNumber value={value} onChange={onChange} min={0} precision={2} />
        </div>
    );
};

const ActionSelect = (props) => {
    const { value, onChange } = props;

    return (
        <Select value={value} onChange={onChange} style={{ width: 120 }}>
            {Object.values(BID_BUDGET_ACTIONS).map((opt) => (
                <Select.Option key={opt} value={opt}>
                    {opt}
                </Select.Option>
            ))}
        </Select>
    );
};

const BidBudgetAction = (props) => {
    const { actionType, title, disabled, onAddAction } = props;

    const [action, setAction] = useState(BID_BUDGET_ACTIONS.SET);
    const [value, setValue] = useState(0);
    const [units, setUnits] = useState(BID_BUDGET_UNITS.DOLLAR);

    const onChangeAction = (action) => {
        setAction(action);
        setValue(0);
        setUnits(BID_BUDGET_UNITS.DOLLAR);
    };

    const onClickAdd = () => {
        onAddAction(actionType, { action, units, value });

        setAction(BID_BUDGET_ACTIONS.SET);
        setValue(0);
        setUnits(BID_BUDGET_UNITS.DOLLAR);
    };

    return (
        <div className="bid-budget-action">
            <span className="title">{title}</span>
            <ActionSelect value={action} onChange={onChangeAction} />
            <ValueInput value={value} onChange={setValue} prefix={getValuePrefixByAction(action)} />
            <UnitsSelect value={units} onChange={setUnits} options={getUnitsByAction(action)} />
            <Button onClick={onClickAdd} disabled={disabled || !value} type="primary">
                Add action
            </Button>
        </div>
    );
};

export default BidBudgetAction;
