import axios from 'axios';
import { getAccessToken } from 'util/auth';
import { getAppVersionFromStorage } from 'util/localStorage';

const apiUrl = (process.env.REACT_APP_API_URL || '') + '/api';

const get = (...params) => request('GET', ...params);
const post = (...params) => request('POST', ...params);
const put = (...params) => request('PUT', ...params);
const del = (...params) => request('DELETE', ...params);

const getURL = (url) => encodeURI(apiUrl + url);

async function request(method, url, data, cancelToken) {
    try {
        const token = await getAccessToken();

        const headers = {
            Authorization: `Bearer ${token}`,
            koversion: getAppVersionFromStorage(),
        };

        const result = await axios({
            url: getURL(url),
            method,
            data,
            timeout: 0,
            maxContentLength: 0,
            cancelToken,
            headers,
        });
        return result.data;
    } catch (err) {
        if (axios.isCancel(err)) {
            return {
                error: 'cancelled',
            };
        }
        const code = err.response && err.response.status;
        if (code === 401 || code === 426) {
            window.location.reload();
        }
        return {
            error: err.response && err.response.data,
            code,
        };
    }
}

export default {
    get,
    post,
    put,
    del,
    getURL,
};
