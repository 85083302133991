import * as actions from 'store/actions';
import moment from 'moment';

const ACTION_HANDLERS = {
    [actions.SET_ADSENSE_CAMPAIGN_ID]: (state, { selectedCampaignId }) => {
        return {
            ...state,
            selectedCampaignId,
        };
    },

    [actions.SET_ADSENSE_ADS_DATA]: (state, { data }) => {
        return {
            ...state,
            adSenseCampaignAdsData: data,
        };
    },
    [actions.SET_ADSENSE_ADS_DATA_LOADING]: (state, { loading }) => {
        return {
            ...state,
            adSenseCampaignAdsDataLoading: loading,
        };
    },
    [actions.SET_ADSENSE_ADS_POPUP_VISIBLE]: (state) => {
        return {
            ...state,
            adSenseCampaignAdsPopupVisible: !state.adSenseCampaignAdsPopupVisible,
        };
    },
    [actions.SET_ADSENSE_ADS_DATE_RANGE]: (state, { range }) => {
        return {
            ...state,
            adSenseCampaignAdsDataSelectedRange: range,
        };
    },
};

const initialState = {
    selectedCampaignId: '',

    adSenseCampaignAdsData: [],
    adSenseCampaignAdsPopupVisible: false,
    adSenseCampaignAdsDataLoading: false,
    adSenseCampaignAdsDataSelectedRange: [moment(), moment()],
};

export default function (state = initialState, action) {
    const handler = ACTION_HANDLERS[action.type];

    return handler ? handler(state, action) : state;
}
