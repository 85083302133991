import _ from 'lodash';
import moment from 'moment';
import { notification } from 'antd';
import * as PresetsService from 'service/presets.service';
import store from 'store/store';
import * as actions from 'store/actions';
import { runReports } from 'util/runReports';
import { quickRanges } from 'util/quickRanges';
import {
    setColumnsOrderToStorage,
    setColumnsWidthToStorage,
    getColumnsOrderFromStorage,
    getColumnsWidthFromStorage,
} from 'util/localStorage';
import { FILTER_OPERAND } from 'data/filterOperands';
import { HIDE_TYPE } from 'data/hideTypes';
import { isDimensionPinned } from 'data/pinnedDimensions';

const generateKey = () => (Math.random() * Math.random() * Math.random() * Math.random()).toString().slice(2, 30);

const getNewOrder = (allDimensions = [], prevOrder = []) => {
    // keep previous order for selected dimensions
    const orderedKeys = _.intersection(prevOrder, allDimensions);
    // add new dimensions at the end
    let newOrder = orderedKeys.concat(_.difference(allDimensions, orderedKeys));
    // pinned dimensions at the beginning
    newOrder = _.uniq(newOrder.filter(isDimensionPinned).concat(newOrder));
    return newOrder;
};

export const setInitialSelection =
    (show = [], hide = [], dimensions = [], metrics = [], dimensionsOrder = []) =>
    (dispatch) => {
        dispatch(changeHidden(show, hide));

        dispatch({ type: actions.DIMENSIONS_SELECT, dimensions });
        dispatch({ type: actions.METRICS_SELECT, metrics });

        const allDimensions = _.uniq(dimensions.concat(metrics));
        const storageOrder = _.uniq(getColumnsOrderFromStorage());
        // columns in storage order are the same as selected in dashboard
        if (
            storageOrder &&
            storageOrder.length === allDimensions.length &&
            storageOrder.length === _.intersection(storageOrder, allDimensions).length
        ) {
            dispatch(setDimensionOrder(storageOrder));
        } else {
            dispatch(setDimensionOrder(dimensionsOrder));
        }

        dispatch(updateUserColumnsWidth(getColumnsWidthFromStorage()));
    };

export const setRangeDate = (date) => {
    return {
        type: actions.DATE_SELECT,
        date,
    };
};

export const hideDimensions = (dimensionsToHide) => {
    return {
        type: actions.DIMENSIONS_HIDE,
        dimensionsToHide,
    };
};

export const changeHidden = (toShow = [], toHide = []) => {
    return {
        type: actions.CHANGE_HIDDEN,
        toShow,
        toHide,
    };
};

export const selectDimensions =
    (dimensions = []) =>
    (dispatch) => {
        const { metrics, dimensionsOrder } = store.getState().currentSelection;
        const allDimensions = (metrics || []).concat(dimensions);

        dispatch({ type: actions.DIMENSIONS_SELECT, dimensions });
        dispatch(setDimensionOrder(getNewOrder(allDimensions, dimensionsOrder)));
    };

export const selectMetrics =
    (metrics = []) =>
    (dispatch) => {
        const { dimensions, dimensionsOrder } = store.getState().currentSelection;
        const allDimensions = (dimensions || []).concat(metrics);

        dispatch({ type: actions.METRICS_SELECT, metrics });
        dispatch(setDimensionOrder(getNewOrder(allDimensions, dimensionsOrder)));
    };

export const disableMetrics = (metrics = [], disableStatus = true) => {
    return {
        type: actions.METRICS_DISABLE,
        metrics,
        disableStatus,
    };
};

export const addFilter = (filter) => {
    const key = _.get(filter, 'field', '') + generateKey();
    const items = _.get(filter, 'items', []).map((itm) => ({
        ...itm,
        key: itm.key || generateKey(),
    }));
    const newFilter = {
        field: '',
        ...filter,
        items: items.length ? items : [{ key: generateKey(), operand: FILTER_OPERAND.EQUAL, value: '' }],
    };
    return { type: actions.FILTER_ADD, key, newFilter };
};

export const addFilters = (filters = []) => {
    const filtersToAdd = {};
    for (const filter of filters) {
        const { field, items = [] } = filter;
        const key = field + generateKey();
        filtersToAdd[key] = {
            field,
            items: items.map((itm) => ({
                ...itm,
                key: itm.key || generateKey(),
            })),
        };
    }
    return {
        type: actions.FILTERS_ADD,
        filters: filtersToAdd,
    };
};

export const changeFilter = (key, newFilter) => {
    const items = _.get(newFilter, 'items', []).map((itm) => ({
        ...itm,
        key: itm.key || generateKey(),
    }));
    return {
        type: actions.FILTER_CHANGE,
        key,
        newFilter: {
            ...newFilter,
            items,
        },
    };
};

export const removeFilters = ({ keys, fields, all }) => {
    return {
        type: actions.FILTER_REMOVE,
        keys,
        fields,
        all,
    };
};

export const removeEmptyFilters = () => {
    return {
        type: actions.FILTER_REMOVE_EMPTY,
    };
};

export const setDimensionOrder = (newOrder = []) => {
    setColumnsOrderToStorage(newOrder);
    return {
        type: actions.DIMENSIONS_ORDER_SET,
        order: newOrder,
    };
};

export const updateUserColumnsWidth = (columnsWidth = {}) => {
    const { userColumnsWidth = {} } = store.getState().currentSelection;
    const newColumnsWidth = {
        ...userColumnsWidth,
        ...columnsWidth,
    };

    setColumnsWidthToStorage(newColumnsWidth);

    return {
        type: actions.USER_COLUMNS_WIDTH_SET,
        userColumnsWidth: newColumnsWidth,
    };
};

export const togglePresetsModalVisibility = () => {
    return {
        type: actions.CHANGE_PRESETS_MODAL_VISIBILITY,
    };
};

export const setPresets = (presets) => {
    return {
        type: actions.SET_PRESETS,
        presets,
    };
};

export const savePreset = (title, shared, update, dashboard) => async (dispatch) => {
    try {
        dispatch(setPresetsLoading(true));
        const {
            selectedPreset,
            dimensions,
            metrics,
            disabledMetrics,
            dimensionsOrder,
            filters,
            filterOptions,
            date,
            hidden,
        } = store.getState().currentSelection;

        const preset = {
            title,
            shared,
            dimensions,
            metrics,
            disabledMetrics,
            dimensionsOrder,
            filters,
            filterOptions,
            date,
            hidden,
        };

        if (update) preset.id = selectedPreset.id;

        //save preset to mongo
        const result = await PresetsService.savePreset({ ...preset, title, dashboard });
        if (!result) throw new Error('Failed to save preset');

        dispatch(getPresets(dashboard));
        notification.success({
            message: update ? 'Preset updated successfully' : 'Preset saved successfully',
            description: `The preset “${title}” has been successfully saved`,
        });
        dispatch(setPresetsLoading(false));
    } catch (err) {
        console.error('Save New Preset error', err.message);
        notification.error({
            message: 'Error',
            description: err.message,
        });
        dispatch(setPresetsLoading(false));
    }
};

export const getPresets =
    (dashboardName = 'KO') =>
    async (dispatch) => {
        try {
            dispatch(setPresetsLoading(true));
            const presets = await PresetsService.fetchAllPresets(dashboardName);
            dispatch(setPresets(presets?.data || []));
            dispatch(setPresetsLoading(false));
        } catch (err) {
            console.error('Get Presets error', err.message);
            notification.error({
                message: 'Error',
                description: err.message,
            });
            dispatch(setPresetsLoading(false));
        }
    };

export const setSelectedPreset = (preset) => {
    return {
        type: actions.SET_SELECTED_PRESET,
        selectedPreset: preset,
    };
};

export const applyPreset =
    (dashboardConfig, preset, run = false) =>
    async (dispatch) => {
        try {
            dispatch(setPresetsLoading(true));
            const { filterOptions, hidden: currentHidden } = store.getState().currentSelection;

            const toHide = [];
            const toShow = [];

            if (preset?.hidden) {
                for (const type in preset.hidden) {
                    if (type === 'undefined' || !type) continue;
                    for (const field in preset.hidden[type]) {
                        if (!field || field === '[object Object]') continue;

                        const hideItem = {
                            hideType: type,
                            field,
                        };

                        if (preset.hidden[type][field] === true) toHide.push(hideItem);
                        else toShow.push(hideItem);
                    }
                }

                const remaining = _.difference(Object.keys(currentHidden.main), Object.keys(preset.hidden.main));
                for (const rem of remaining) {
                    toShow.push({
                        hideType: HIDE_TYPE.MAIN,
                        field: rem,
                    });
                }
            }

            dispatch(removeFilters({ keys: [], fields: [], all: true }));
            dispatch(changeHidden(toShow, toHide));
            dispatch(selectMetrics(preset.metrics));
            dispatch(selectDimensions(preset.dimensions));
            dispatch(setDimensionOrder(preset.dimensionsOrder));
            dispatch(disableMetrics(Object.keys(preset?.disabledMetrics || {})));
            dispatch(
                setRangeDate({
                    range: preset?.date?.preset
                        ? quickRanges()[preset?.date?.preset]
                        : preset?.date?.range?.map((d) => moment(d)),
                    preset: preset?.date?.preset || '',
                    interval: preset?.date?.interval || '',
                })
            );

            const newFilters = _.map(preset?.filters || {});
            if (newFilters && newFilters.length) {
                const filterOptionsKeys = Object.keys(filterOptions);
                // const strictOperands = ['one_of', 'not_one_of'];
                const cleanedFilters = newFilters.map((filter) => {
                    if (!filterOptionsKeys.includes(filter.field)) return filter;

                    const cleanedItems = [];
                    for (const item of filter.items) {
                        // if (!strictOperands.includes(item.operand)) cleanedItems.push(item);

                        cleanedItems.push(item);
                        // if (_.isArray(item.value)) {
                        //     // const val = item.value.filter((v) => filterOptions[filter.field].includes(v));
                        //     // if (val && val.length)
                        //         cleanedItems.push({
                        //             key: item.key,
                        //             operand: item.operand,
                        //             value: item.value,
                        //         });
                        // } else {
                        //     // if (filterOptions[filter.field].includes(item.value))
                        //     cleanedItems.push(item);
                        // }
                    }
                    return {
                        field: filter.field,
                        items: cleanedItems,
                    };
                });

                dispatch(addFilters(cleanedFilters));
            }

            dispatch(setSelectedPreset(preset));

            if (run) dispatch(runReports(dashboardConfig, true));

            dispatch(setPresetsLoading(false));
        } catch (err) {
            console.error('applyPreset', err.message);
            notification.error({
                message: 'Error',
                description: err.message,
            });
            dispatch(setPresetsLoading(false));
        }
    };

export const deletePreset = (id, name) => async (dispatch) => {
    try {
        dispatch(setPresetsLoading(true));
        const result = await PresetsService.deletePreset(id);
        if (result?.error) throw new Error(result.error);
        dispatch(getPresets(name));

        notification.success({
            message: 'Preset deleted successfully',
        });
    } catch (err) {
        console.error('deletePreset', err.message);
        notification.error({
            message: 'Failed to delete preset',
            description: err.message,
        });
        dispatch(setPresetsLoading(false));
    }
};

export const setPresetsLoading = (loading) => {
    return {
        type: actions.SET_PRESETS_LOADING,
        presetsLoading: loading,
    };
};

export const lockEditing = (lock) => {
    return {
        type: actions.LOCK_PRESET_EDITING,
        isLocked: lock,
    };
};
