import * as actions from 'store/actions';
import store from 'store/store';
import { notification } from 'antd';

import {
    fetchCampaignLast7DaysData,
    fetchCampaignPerCountryData,
    fetchAdsetLast7DaysData,
} from 'service/search-campaigns.service';

export const reset = () => {
    return {
        type: actions.RESET_SEARCH_CAMPAIGN_STATISTICS,
    };
};

export const changeModalVisibility = () => {
    return {
        type: actions.CHANGE_SEARCH_CAMPAIGN_STATISTICS_MODAL_VISIBILITY,
    };
};

export const setTargetCampaignId = (campaignId) => {
    return {
        type: actions.SET_CURRENT_CAMPAIGN_ID,
        campaignId,
    };
};

export const setTargetAdsetId = (adsetId) => {
    return {
        type: actions.SET_CURRENT_ADSET_ID,
        adsetId,
    };
};

export const setLast7DaysDataLoading = (loading) => {
    return {
        type: actions.SET_CAMPAIGN_LAST_7_DAYS_DATA_LOADING,
        loading,
    };
};

export const setPerCountryDataLoading = (loading) => {
    return {
        type: actions.SET_CAMPAIGN_PER_COUNTRY_DATA_LOADING,
        loading,
    };
};

export const setPerCountryData = (data) => {
    return {
        type: actions.SET_CAMPAIGN_PER_COUNTRY_DATA,
        data,
    };
};

export const setLast7DaysData = (data) => {
    return {
        type: actions.SET_CAMPAIGN_LAST_7_DAYS_DATA,
        data,
    };
};

export const fetchLast7DaysData =
    (isAdsetLevel = false) =>
    async (dispatch) => {
        try {
            dispatch(setLast7DaysDataLoading(true));
            const targetId = isAdsetLevel
                ? store.getState().searchCampaignStatistics.selectedAdsetId
                : store.getState().searchCampaignStatistics.selectedCampaignId;
            if (!targetId) {
                throw new Error('No Target Id selected');
            }

            const dataFetcher = isAdsetLevel ? fetchAdsetLast7DaysData : fetchCampaignLast7DaysData;

            const data = await dataFetcher(targetId);
            if (data?.error) {
                throw new Error(data.error);
            }

            dispatch(setLast7DaysData(data.data));
            dispatch(setLast7DaysDataLoading(false));
        } catch (error) {
            dispatch(setLast7DaysDataLoading(false));
            console.error('Fetch campaign last 7 days data error', error.message);
            notification.error({
                message: 'Error',
                description: error.message,
            });
        }
    };

export const fetchPerCountryData =
    (preset = 'Last 7 Days') =>
    async (dispatch) => {
        try {
            dispatch(setPerCountryDataLoading(true));
            const campaignId = store.getState().searchCampaignStatistics.selectedCampaignId;
            if (!campaignId) {
                throw new Error('No Campaign Id selected');
            }

            const data = await fetchCampaignPerCountryData(campaignId, preset);
            if (data?.error) {
                throw new Error(data.error);
            }

            dispatch(setPerCountryData(data.data));
            dispatch(setPerCountryDataLoading(false));
        } catch (error) {
            dispatch(setPerCountryDataLoading(false));
            console.error('Fetch campaign per country data error', error.message);
            notification.error({
                message: 'Error',
                description: error.message,
            });
        }
    };

export const loadCampaignStatisticsData = () => async (dispatch) => {
    try {
        dispatch(fetchLast7DaysData());
        dispatch(fetchPerCountryData());
    } catch (error) {
        console.error('Load campaign statistics data error', error.message);
        notification.error({
            message: 'Error',
            description: error.message,
        });
    }
};

export const loadAdsetStatisticsData = () => async (dispatch) => {
    try {
        dispatch(fetchLast7DaysData(true));
    } catch (error) {
        console.error('Load campaign statistics data error', error.message);
        notification.error({
            message: 'Error',
            description: error.message,
        });
    }
};
