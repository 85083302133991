import dayjs from 'dayjs';

export const quickRanges = () => {
    const yesterdayEnd = dayjs().subtract(1, 'days').endOf('day');
    const todayEnd = dayjs().endOf('day');  // для представления конца сегодняшнего дня
    return {
        Today: [dayjs().startOf('day'), todayEnd],
        Yesterday: [dayjs().subtract(1, 'days').startOf('day'), yesterdayEnd],
        // 'Last 24 Hours': [moment().subtract(24, 'hours'), moment()],
        'Last 3 Days': [dayjs().subtract(2, 'days').startOf('day'), todayEnd],
        'Last 7 Days': [dayjs().subtract(6, 'days').startOf('day'), todayEnd],
        'Last 30 Days': [dayjs().subtract(30, 'days').startOf('day'), yesterdayEnd],
        'Last Month': [
            dayjs().subtract(1, 'month').startOf('month').startOf('day'),
            dayjs().subtract(1, 'month').endOf('month').endOf('day'),
        ],
        'Month to Date': [dayjs().startOf('month').startOf('day'), dayjs().endOf('day')],
    };
};

export const isRangeMoreThan = (range, count = 3, t = 'days') =>
    range && range.length === 2 && dayjs(range[1]).diff(dayjs(range[0]), t) + 1 > count;
