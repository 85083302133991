import React, { useState, useContext } from 'react';
import _ from 'lodash';
import {
    Modal,
    Table,
    Input,
    Typography,
    Switch,
    message,
    Statistic,
    Space,
    Card,
    Tooltip,
    InputNumber,
    notification,
} from 'antd';
import { ArrowUpOutlined, ArrowDownOutlined, SearchOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { setCampaignPerDayPopupVisible } from 'store/taboola/actionCreator';
import getMetricCellValues from 'util/getMetricCellValues';
import { stringSorter, numberSorter, dateSorter } from 'util/tables';
import { hasUserPermissions } from 'util/permissions';
import DashboardContext from 'context/dashboardContext';
import * as FIELD from 'data/fields';
import {
    setCampaignAdsPopupVisible,
    setCampaignAdsDateRange,
    fetchTaboolaCampaignAdsData,
} from 'store/taboola/actionCreator';
import DateRange from 'components/DateRange';

const { Text, Link } = Typography;

const SiteDataTable = (props) => {
    const { data, loading } = props;
    const columns = [
        {
            title: 'Ad ID',
            dataIndex: 'adId',
            key: 'adId',
            width: 100,
            sorter: (a, b) => stringSorter('adId')(a, b),
        },
        {
            title: 'Spend',
            dataIndex: 'spend',
            key: 'spend',
            width: 100,
            sorter: (a, b) => numberSorter('spend')(a, b),
            render: (value) => {
                return <span>{getMetricCellValues(value, { precision: 2, prefix: '$', isNumber: true }).value}</span>;
            },
        },
        {
            title: 'Revenue',
            dataIndex: 'revenue',
            key: 'revenue',
            width: 100,
            sorter: (a, b) => numberSorter('revenue')(a, b),
            render: (value) => {
                return <span>{getMetricCellValues(value, { precision: 2, prefix: '$', isNumber: true }).value}</span>;
            },
        },
        {
            title: 'Profit',
            dataIndex: 'profit',
            key: 'profit',
            width: 100,
            sorter: (a, b) => numberSorter('profit')(a, b),
            render: (value) => {
                return <span>{getMetricCellValues(value, { precision: 2, prefix: '$', isNumber: true }).value}</span>;
            },
        },
        {
            title: 'ROI',
            dataIndex: 'roi',
            key: 'roi',
            width: 100,
            sorter: (a, b) => numberSorter('roi')(a, b),
            render: (value) => {
                return (
                    <span>
                        {getMetricCellValues(value, { prefix: '', postfix: '%', precision: 1, isNumber: true }).value}
                    </span>
                );
            },
        },
        {
            title: 'CPC',
            dataIndex: 'cpc',
            key: 'cpc',
            width: 100,
            sorter: (a, b) => numberSorter('cpc')(a, b),
            render: (value) => {
                return <span>{getMetricCellValues(value, { precision: 2, prefix: '$', isNumber: true }).value}</span>;
            },
        },
        {
            title: 'RPS',
            dataIndex: 'rps',
            key: 'rps',
            width: 100,
            sorter: (a, b) => numberSorter('rps')(a, b),
            render: (value) => {
                return <span>{getMetricCellValues(value, { precision: 2, prefix: '$', isNumber: true }).value}</span>;
            },
        },
        {
            title: 'CVR',
            dataIndex: 'cvrContent',
            key: 'cvrContent',
            width: 100,
            sorter: (a, b) => numberSorter('cvrContent')(a, b),
            render: (value) => {
                return <span>{getMetricCellValues(value, { postfix: '%', precision: 2, isNumber: true }).value}</span>;
            },
        },
        {
            title: 'CPR',
            dataIndex: 'cpr',
            key: 'cpr',
            width: 100,
            sorter: (a, b) => numberSorter('cpr')(a, b),
            render: (value) => {
                return <span>{getMetricCellValues(value, { precision: 2, prefix: '$', isNumber: true }).value}</span>;
            },
        },
        {
            title: 'CTR',
            dataIndex: 'ctr',
            key: 'ctr',
            width: 100,
            sorter: (a, b) => numberSorter('ctr')(a, b),
            render: (value) => {
                return (
                    <span>
                        {getMetricCellValues(value, { prefix: '', postfix: '%', precision: 1, isNumber: true }).value}
                    </span>
                );
            },
        },
        {
            title: 'PPS',
            dataIndex: 'ppsCalc',
            key: 'ppsCalc',
            width: 100,
            sorter: (a, b) => numberSorter('ppsCalc')(a, b),
            render: (value) => {
                return <span>{getMetricCellValues(value, { precision: 2, isNumber: true }).value}</span>;
            },
        },
        {
            title: 'RPM',
            dataIndex: 'rpm',
            key: 'rpm',
            width: 100,
            sorter: (a, b) => numberSorter('rpm')(a, b),
            render: (value) => {
                return <span>{getMetricCellValues(value, { precision: 2, prefix: '$', isNumber: true }).value}</span>;
            },
        },
    ];

    return (
        <Table
            size={'large'}
            rowKey={'Site'}
            dataSource={data || []}
            columns={columns}
            loading={loading}
            pagination={false}
            scroll={{ x: '100vw', y: '45vh' }}
        />
    );
};

const TaboolaAdsData = () => {
    const dispatch = useDispatch();
    const {
        selectedCampaignId,
        campaignAdsData,
        campaignAdsPopupVisible,
        campaignAdsDataLoading,
        campaignAdsDataSelectedRange,
    } = useSelector((state) => state.taboola);

    const onClose = () => {
        dispatch(setCampaignAdsPopupVisible());
    };

    const onDateRangeChange = (date) => {
        dispatch(setCampaignAdsDateRange(date));
        dispatch(fetchTaboolaCampaignAdsData(selectedCampaignId, date));
    };

    return (
        <Modal
            title={'Ads Data'}
            footer={false}
            maskClosable
            width={'85vw'}
            onCancel={onClose}
            visible={campaignAdsPopupVisible}
        >
            <div style={{ width: 240 }}>
                <DateRange value={campaignAdsDataSelectedRange} disablePresets={true} onChange={onDateRangeChange} />
            </div>
            <SiteDataTable data={campaignAdsData} loading={campaignAdsDataLoading} />
        </Modal>
    );
};

export default TaboolaAdsData;
