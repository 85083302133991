import _ from 'lodash';
import { notification } from 'antd';
import store from 'store/store';
import * as actions from 'store/actions';
import * as mainReportData from 'util/mainReportData';
import { calculateReportColumns } from 'util/calculateColumnsWidth';
import getActionItem from 'util/getActionItem';
import { HIDE_TYPE } from 'data/hideTypes';

export const dataLoadingState = (fetching) => (dispatch) => {
    dispatch({
        type: actions.MAIN_DATA_FETCHING_SET,
        fetching,
    });
};

export const runMainReport = (dashboardConfig, forceUpdate) => async (dispatch) => {
    try {
        const shouldFetch = mainReportData.shouldBeFetched(forceUpdate);
        if (shouldFetch) {
            dispatch({
                type: actions.MAIN_DATA_FETCHING_SET,
                fetching: true,
            });
        }

        dispatch({
            type: actions.MAIN_BULK_ROWS_SET,
            bulkSelectedRowIds: [],
        });

        const { data = [], filteredData = [] } = await mainReportData.getFilteredData(dashboardConfig, shouldFetch);

        console.info('main filteredData', filteredData);

        const { currentSelection, dashboard } = store.getState();
        const { dimensions = [], metrics = [], hidden = {} } = currentSelection;
        const { sorting: currentSorting = {} } = dashboard;
        const visibleDimensions = dimensions.filter((d) => !_.get(hidden, [HIDE_TYPE.MAIN, d]));
        const visibleMetrics = metrics.filter((d) => !_.get(hidden, [HIDE_TYPE.MAIN, d]));

        const newSorting = _.pick(currentSorting, visibleDimensions.concat(visibleMetrics));

        /// TODO: calculate totals + use them to calculate widths + store totals in redux

        const totalRow = mainReportData.calculateTotalRow(dashboardConfig, filteredData);

        const columnsWidth = calculateReportColumns(filteredData, dashboardConfig.allDimensionsHash || {});

        dispatch({
            type: actions.MAIN_AFTER_RUN_SET,
            data,
            filteredData,
            totalRow,
            columnsWidth,
            actionItem: getActionItem(dimensions),
            afterRun: currentSelection,
            sorting: newSorting,
        });

        // update filter options
        if (shouldFetch) {
            const filterOptions = mainReportData.getFilterOptions(dashboardConfig, data);
            dispatch({
                type: actions.FILTER_OPTIONS_SET,
                filterOptions,
                force: shouldFetch,
            });
        }
    } catch (err) {
        console.error('runReport error', err.message);
        notification.error({
            message: 'Error',
            description: err.message,
        });
        dispatch({
            type: actions.MAIN_DATA_FETCHING_SET,
            fetching: false,
        });
    }
};

export const setSorting = (sorting = {}) => {
    return {
        type: actions.MAIN_SORTING_SET,
        sorting,
    };
};

export const setPagination = (pagination = {}) => {
    return {
        type: actions.MAIN_PAGINATION_SET,
        pagination,
    };
};

export const setDashboardDimensionOrder = (order = []) => {
    return {
        type: actions.MAIN_DIMENSIONS_ORDER_SET,
        order,
    };
};

export const updateColumnsWidth = (updColumnsWidth = {}) => {
    const { columnsWidth = {} } = store.getState().dashboard;

    return {
        type: actions.MAIN_COLUMNS_WIDTH_SET,
        columnsWidth: {
            ...columnsWidth,
            ...updColumnsWidth,
        },
    };
};
