import * as actions from 'store/actions';
import { getCurrentActionsObject, getPendingActionsObject } from 'util/currentPendingActions';
import { setUserActionsToStorage, getUserActionsFromStorage } from 'util/localStorage';

const ACTION_HANDLERS = {
    [actions.ACTION_ADD]: (state, { newActions }) => {
        const updActions = [...state.actions, ...newActions];
        setUserActionsToStorage(updActions);
        return {
            ...state,
            actions: updActions,
            currentActions: getCurrentActionsObject(updActions),
        };
    },
    [actions.ACTION_CHANGE]: (state, { newAction = {} }) => {
        const updActions = [...state.actions.filter((act) => act.key !== newAction.key), newAction];
        setUserActionsToStorage(updActions);
        return {
            ...state,
            actions: updActions,
            currentActions: getCurrentActionsObject(updActions),
        };
    },
    [actions.SET_REFRESH_DATA_POPUP]: (state, { show = false }) => {
        return {
            ...state,
            showRefreshDataPopup: !!show,
        };
    },
    [actions.ACTION_REMOVE]: (state, { keys = [] }) => {
        const keysSet = new Set(keys);
        const updActions = state.actions.filter((act) => !keysSet.has(act.key));
        setUserActionsToStorage(updActions);
        return {
            ...state,
            actions: updActions,
            currentActions: getCurrentActionsObject(updActions),
        };
    },
    [actions.ACTIONS_RUNNING_SET]: (state, { value }) => {
        return {
            ...state,
            isActionsRunning: value,
        };
    },

    // bulk selection
    [actions.MAIN_BULK_ROWS_SET]: (state, { bulkSelectedRowIds = {} }) => {
        return {
            ...state,
            bulkSelectedRowIds: bulkSelectedRowIds,
        };
    },

    // pending actions
    [actions.PENDING_ACTIONS_SET]: (state, { actions = [], force = false }) => {
        return {
            ...state,
            pendingActions: getPendingActionsObject(actions),
        };
    },
    [actions.PENDING_ACTIONS_STATUS_FETCHING_SET]: (state, { fetching }) => {
        return {
            ...state,
            pendingActionsFetching: fetching,
        };
    },

    // status
    [actions.ACTIONS_STATUS_FETCHING_SET]: (state, { fetching }) => {
        return {
            ...state,
            isFetchingStatus: fetching,
        };
    },
    [actions.ACTIONS_STATUS_SET]: (state, { status = {}, force = false }) => {
        const newStatus = force
            ? status
            : {
                  ...state.status,
                  ...status,
              };
        return {
            ...state,
            status: newStatus,
        };
    },
    // Adset history modal
    [actions.SET_ADSET_HISTORY_LOADING]: (state) => ({
        ...state,
        loadingHistory: !state.loadingHistory,
        adsetHistory: [],
        adsetHistoryModalVisible: true,
    }),
    [actions.CHANGE_ADSET_HISTORY_MODAL_VISIBILITY]: (state) => ({
        ...state,
        adsetHistoryModalVisible: !state.adsetHistoryModalVisible,
    }),
    [actions.SET_ADSET_HISTORY]: (state, { history }) => ({
        ...state,
        adsetHistory: history,
        loadingHistory: false,
    }),
    // Ad History
    [actions.SET_AD_HISTORY_LOADING]: (state) => ({
        ...state,
        loadingAdHistory: !state.loadingAdHistory,
    }),
    [actions.SET_AD_HISTORY]: (state, { adHistory, adId }) => ({
        ...state,
        adHistory: {
            ...adHistory,
            [adId]: adHistory,
        },
    }),
};

const initialState = {
    actions: getUserActionsFromStorage(),
    isActionsRunning: false,

    status: {},
    isFetchingStatus: false,
    copyCreativeRow: null,
    adSetToCustomCopy: null,

    // object of current actions (it helps to display edited values in grid)
    currentActions: {},

    // object of pending actions (it helps to disable some actions)
    pendingActions: {},
    pendingActionsFetching: false,

    bulkSelectedRowIds: {},

    showRefreshDataPopup: false,

    adsetHistory: [],
    loadingHistory: false,
    adsetHistoryModalVisible: false,

    // Ad History
    adHistory: {},
    loadingAdHistory: false,
};

export default function (state = initialState, action) {
    const handler = ACTION_HANDLERS[action.type];

    return handler ? handler(state, action) : state;
}
