import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import isBetween from 'dayjs/plugin/isBetween';

export default () => {
    dayjs.extend(utc);
    dayjs.extend(timezone);
    dayjs.extend(isBetween);
    dayjs.tz.setDefault('America/New_York');
};
