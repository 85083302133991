import request from 'util/request';

const runActions = (actions = [], isTaboolaActions = false) => {
    return request.post('/actions/run', { data: { actions, isTaboolaActions } });
};

const retryActions = (id, actionIds = []) => {
    return request.post('/actions/retry', { data: { id, actionIds } });
};

const getStatus = () => {
    return request.get('/actions/status');
};

const getPendingActions = () => {
    return request.get('/actions/pending');
};

const getAdsetHistory = (adsetId) => {
    return request.get(`/actions/history/${adsetId}`);
};

const getAdHistory = (adId) => {
    return request.get(`/actions/ad-history/${adId}`);
};

const getActionAvailability = (action) => {
    return request.post('/actions/availability', { data: { action } });
};

export default {
    runActions,
    retryActions,
    getStatus,
    getPendingActions,
    getAdsetHistory,
    getActionAvailability,
    getAdHistory,
};
