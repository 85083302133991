import React, { useContext } from 'react';
import { Typography, Popconfirm } from 'antd';

import EditableCell from 'components/ReportDashboard/DashboardResult/components/EditableCell';
import DashboardContext from 'context/dashboardContext';
import { stringSorter, numberSorter, dateSorter } from 'util/tables';
import getMetricCellValues from 'util/getMetricCellValues';
import * as FIELD from 'data/fields';
import { isDimensionPinned } from 'data/pinnedDimensions';

const { Text } = Typography;

const ROI_KEYS = [
    FIELD.ROI_KEY,
    FIELD.ROI_1H_KEY,
    FIELD.ROI_3H_KEY,
    FIELD.ROI_YESTERDAY_KEY,
    FIELD.ACTUAL_ROI_KEY,
    FIELD.ACTUAL_ROI_1H_KEY,
    FIELD.ACTUAL_ROI_3H_KEY,
    FIELD.ACTUAL_ROI_YESTERDAY_KEY,
];

const getCellClass = (field, value) => {
    if (ROI_KEYS.includes(field)) {
        if (value <= 99) return 'red';
        if (value <= 110) return 'yellow';
        return 'green';
    }
    return '';
};

const getColumnSorter = (metric = {}) => {
    if (metric.isDate) return dateSorter(metric.key);
    if (metric.isNumber) return numberSorter(metric.key);
    return stringSorter(metric.key);
};

const useMetricColumns = (props) => {
    const dashboardContext = useContext(DashboardContext);
    const {
        metrics = [],
        columnsWidth = {},
        sorting = {},
        onCellClick,
        onToggleRowAction,
        actionItem,
        isTotalRow,
    } = props;

    const isTaboola = dashboardContext.name === 'Taboola';

    return metrics.map((key) => {
        const metric = dashboardContext.metricsListHash[key];
        if (!metric) return null;

        return {
            dataIndex: key,
            title: metric.title,
            fixed: isDimensionPinned(key) ? 'left' : false,
            className: 'grid-cell grid-number-cell',
            render: (val, row, ind) => {
                const { value, filterValue } = getMetricCellValues(val, metric);

                if (isTotalRow) {
                    return <div className="filterable-cell-content">{metric.showTotal ? value : ''}</div>;
                }

                const cellKey = key + ind;
                const cellClassName = `filterable-cell-content ${cellKey} ${getCellClass(key, val)}`;

                const onClick = filterValue === false ? null : (e) => onCellClick(e, key, filterValue, cellKey);

                if (
                    (actionItem === 'adset' && key === FIELD.BID_KEY) ||
                    (actionItem === 'adset' && key === FIELD.ADSET_STATUS_KEY) ||
                    (actionItem === 'adset' && key === FIELD.BUDGET_KEY) ||
                    (actionItem === 'campaign' && key === 'campaignEffectiveStatus') ||
                    (actionItem === 'campaign' && isTaboola && [FIELD.BID_KEY, FIELD.BUDGET_KEY].includes(key))
                ) {
                    return (
                        <EditableCell
                            className={cellClassName}
                            metric={metric}
                            value={val}
                            displayValue={value}
                            onCellClick={onClick}
                            row={row}
                            onSave={(actionType, params) => onToggleRowAction(actionType, row, params)}
                        />
                    );
                }

                if (key === FIELD.ROI_KEY && dashboardContext.name === 'Explorads') {
                    return (
                        <div className={cellClassName} onClick={onClick}>
                            <Popconfirm
                                icon={false}
                                title={() => {
                                    return (
                                        <>
                                            <Text strong>ROI Details</Text>
                                            <br />
                                            <Text>
                                                ROI-1H: {getMetricCellValues(row[FIELD.ROI_1H_KEY], metric).value}
                                            </Text>
                                            <br />
                                            <Text>
                                                ROI-3H: {getMetricCellValues(row[FIELD.ROI_3H_KEY], metric).value}
                                            </Text>
                                            <br />
                                            <Text>
                                                ROI-Yesterday:{' '}
                                                {getMetricCellValues(row[FIELD.ROI_YESTERDAY_KEY], metric).value}
                                            </Text>
                                            <br />
                                        </>
                                    );
                                }}
                                showCancel={false}
                            >
                                {value}
                            </Popconfirm>
                        </div>
                    );
                }

                return (
                    <div className={cellClassName} onClick={onClick}>
                        {value}
                    </div>
                );
            },
            width: columnsWidth[key] || 200,
            ellipsis: true,
            sorter: getColumnSorter(metric),
            sortOrder: sorting[key] || false,
        };
    });
};

export default useMetricColumns;
