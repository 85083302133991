import React, { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Layout } from 'antd';
import { useDispatch } from 'react-redux';
import Login from 'pages/Login';
import NoAccess from 'pages/Login/NoAccess';
import { useAuth } from 'hooks/useAuth';
import Header from './Header';
import SideMenu from './SideMenu';
import ContentRoutes from './ContentRoutes';
import { fetchPendingActions } from 'store/userActions/actionCreators';
import { toggleCreativeMetrics } from 'util/dimensionsHelper';
import { subscribeToServerEvents } from 'util/Subscribe';
import { getAppVersionFromStorage } from 'util/localStorage';

const MainApp = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchPendingActions());
    }, []);

    useEffect(() => {
        toggleCreativeMetrics();
    }, []);

    useEffect(() => {
        const unsubscribe = subscribeToServerEvents();
        return unsubscribe;
    }, []);

    return (
        <Router>
            <Layout style={{ background: '#fff', flexDirection: 'column' }} hasSider={true}>
                <SideMenu />
                <Layout.Header style={{ background: '#fff', borderBottom: 'thin solid #f0f0f0' }}>
                    <Header />
                </Layout.Header>
                <Layout.Content
                    style={{
                        padding: 30,
                        paddingLeft: 110,
                        transition: 'all 200ms',
                        backgroundColor: '#f4f5fc',
                        position: 'relative',
                    }}
                >
                    <ContentRoutes />
                    <p
                        style={{
                            position: 'absolute',
                            bottom: 3,
                            right: 30,
                            margin: 0,
                            padding: 0,
                            color: '#9a9a9a',
                        }}
                    >
                        version: {getAppVersionFromStorage()}
                    </p>
                </Layout.Content>
            </Layout>
        </Router>
    );
};

export default () => {
    const { isAuthorized, noAccess } = useAuth();

    if (!isAuthorized) return <Login />;
    if (noAccess) return <NoAccess />;

    return <MainApp />;
};
