import * as actions from 'store/actions';

const ACTION_HANDLERS = {
    [actions.CAMPAIGN_DATA_LOADING]: (state, { loading }) => {
        return { ...state, loading };
    },
    [actions.LIMITS_LOADING]: (state, { limitsLoading }) => {
        return { ...state, limitsLoading };
    },
    [actions.CHANGE_CAMPAIGN_MODAL_VISIBILITY]: (state) => {
        return { ...state, modalVisible: !state.modalVisible };
    },
    [actions.SET_CAMPAIGN_ADSETS_DATA]: (state, { adsets }) => {
        return { ...state, adsets };
    },
    [actions.SET_CAMPAIGN_ADS_DATA]: (state, { ads }) => {
        return { ...state, ads };
    },
    [actions.CHANGE_CAMPAIGN_MODAL_TYPE]: (state, { modalType }) => {
        return { ...state, modalType };
    },
    [actions.CHANGE_CAMPAIGN_MODAL_NAME]: (state, { modalName }) => {
        return { ...state, modalName };
    },
    [actions.SET_ADS_LIMiTS]: (state, { adsLimits }) => {
        return { ...state, adsLimits };
    },
    [actions.SET_CURRENT_CAMPAIGN_ID]: (state, { campaignId }) => {
        return { ...state, selectedCampaignId: campaignId };
    },
    [actions.SET_ACCOUNT_LIMITS]: (state, { accountLimits }) => {
        return { ...state, accountLimits };
    },
};

const initialState = {
    loading: false,
    limitsLoading: false,
    modalVisible: false,
    adsets: [],
    ads: [],
    modalType: 'adset',
    modalName: 'Campaign Name',
    selectedCampaignId: '',
    adsLimits: {},
    accountLimits: {},
};

export default function (state = initialState, action) {
    const handler = ACTION_HANDLERS[action.type];

    return handler ? handler(state, action) : state;
}
