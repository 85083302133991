import 'antd/dist/antd.variable.min.css';

// import 'util/moment-range';

import React from 'react';
import { ConfigProvider } from 'antd';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { AuthProvider } from 'hooks/useAuth';
import store from 'store/store';

import App from './App';

import './index.css';

import setupDayjs from 'util/setupDayjs';
setupDayjs();

ConfigProvider.config({
    theme: {
        primaryColor: 'rgb(155, 127, 240)',
    },
});

ReactDOM.render(
    <Provider store={store}>
        <AuthProvider>
            <App />
        </AuthProvider>
    </Provider>,
    document.getElementById('root')
);
