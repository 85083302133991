import _ from 'lodash';

const anyOrZero = (value) => (_.isFinite(value) ? value : 0);

export const roi = (revenue, spend) => {
    const result = (revenue / spend) * 100;
    return anyOrZero(result);
};

export const ctr = (clicks, impressions) => {
    const result = (clicks / impressions) * 100;
    return anyOrZero(result);
};

export const cvr = (customClicks, fbClicks) => {
    const result = (customClicks / fbClicks) * 100;
    return anyOrZero(result);
};

export const cpc = (spend, clicks) => {
    const result = spend / clicks;
    return anyOrZero(result);
};

export const rps = (revenue, sessions) => {
    const result = revenue / sessions;
    return anyOrZero(result);
};

export const rpc = (revenue, conversions) => {
    const result = revenue / conversions;
    return anyOrZero(result);
};

export const pps = (pps, sessions) => {
    const result = pps / sessions;
    return anyOrZero(result);
};

export const impsPerSession = (displayImps, sessions) => {
    const result = displayImps / sessions;
    return anyOrZero(result);
};

export const iosPercentage = (iosSessions, sessions) => {
    const result = (iosSessions / sessions) * 100;
    return anyOrZero(result);
};

export const nonUSPercentage = (nonUSSessions, sessions) => {
    const result = (nonUSSessions / sessions) * 100;
    return anyOrZero(result);
};

export const cpr = (spend, fbConv) => {
    return anyOrZero(spend / fbConv);
};

export const cpm = (imps, spend) => {
    return anyOrZero((spend / imps) * 1000);
};

export const budgetUsage = (spend, budget) => {
    const result = (spend / budget) * 100;
    return anyOrZero(result);
};
// CVR - conversion rate between cpc and cpr - cpc/cpr in percentage part 3
export const cvrContent = (fbConversions, clicks) => {
    const result = (fbConversions / clicks) * 100;
    return anyOrZero(result);
};
//IPP - impressions per page part 3
export const ipp = (displayImpressions, pageViews) => {
    const result = displayImpressions / pageViews;
    return anyOrZero(result);
};
//FB CPM metric Facebook Cost Per Mille (FB CPM) part 3
export const fbCpm = (spend, impressions) => {
    const result = (spend / impressions) * 1000;
    return anyOrZero(result);
};