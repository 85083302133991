import React, { useState } from 'react';
import { Button, Select } from 'antd';
import { STATUS } from 'data/actions';

const StatusSelect = (props) => {
    const { value, onChange } = props;

    return (
        <Select value={value} onChange={onChange} style={{ width: 120 }}>
            {Object.values(STATUS).map((opt) => (
                <Select.Option key={opt} value={opt}>
                    {opt}
                </Select.Option>
            ))}
        </Select>
    );
};

const AdStatusAction = (props) => {
    const { title, disabled, onAddAction } = props;

    const [status, setStatus] = useState('');

    const onClickAdd = () => {
        onAddAction('status', { newAdStatus: status });

        setStatus('');
    };

    return (
        <div className="status-action">
            <span className="title">{title}</span>
            <StatusSelect value={status} onChange={setStatus} />
            <div style={{ width: 114 }}></div>
            <div style={{ width: 70 }}></div>
            <Button onClick={onClickAdd} disabled={disabled || !status} type="primary">
                Add action
            </Button>
        </div>
    );
};

export default AdStatusAction;
