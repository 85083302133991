import _ from 'lodash';

export const FILTER_OPERAND = {
    EQUAL: 'eq',
    NOT_EQUAL: 'not_eq',
    LESS_OR_EQUAL: 'less_eq',
    LESS: 'less',
    GREATER_OR_EQUAL: 'gr_eq',
    GREATER: 'gr',
    CONTAINS: 'cont',
    NOT_CONTAINS: 'not_cont',
    STARTS_WITH: 'starts',
    ONE_OF: 'one_of',
    NOT_ONE_OF: 'not_one_of',
    IN_RANGE: 'in_range'
};

export const operandsList = [
    { key: FILTER_OPERAND.EQUAL, label: '=', number: true, string: true },
    { key: FILTER_OPERAND.NOT_EQUAL, label: '≠', number: true, string: true },
    { key: FILTER_OPERAND.LESS_OR_EQUAL, label: '<=', number: true, string: false },
    { key: FILTER_OPERAND.LESS, label: '<', number: true, string: false },
    { key: FILTER_OPERAND.GREATER_OR_EQUAL, label: '>=', number: true, string: false },
    { key: FILTER_OPERAND.GREATER, label: '>', number: true, string: false },
    { key: FILTER_OPERAND.CONTAINS, label: 'Contains', number: true, string: true },
    { key: FILTER_OPERAND.NOT_CONTAINS, label: 'Not contains', number: true, string: true },
    { key: FILTER_OPERAND.STARTS_WITH, label: 'Starts with', number: true, string: true },
    { key: FILTER_OPERAND.ONE_OF, label: 'One of', number: false, string: true },
    { key: FILTER_OPERAND.NOT_ONE_OF, label: 'Not one of', number: false, string: true },
    { key: FILTER_OPERAND.IN_RANGE, label: 'In range', number: false, string: false }
];

export const operandsHash = _.keyBy(operandsList, 'key');
