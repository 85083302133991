import React, { useContext } from 'react';
import { Resizable } from 'react-resizable';
import ReactDragListView from 'react-drag-listview';
import _ from 'lodash';
import { Table } from 'antd';

import useDateColumn from 'components/ReportDashboard/DashboardResult/components/useDateColumn';
import useBulkSelectColumn from 'components/ReportDashboard/DashboardResult/components/useBulkSelectColumn';
import useEmptyColumn from 'components/ReportDashboard/DashboardResult/components/useEmptyColumn';
import useDimensionColumns from 'components/ReportDashboard/DashboardResult/components/useDimensionColumns';
import useMetricColumns from 'components/ReportDashboard/DashboardResult/components/useMetricColumns';

import sortDimensions from 'util/sortDimensions';
import * as FIELD from 'data/fields';
import getMetricCellValues from 'util/getMetricCellValues';
import DashboardContext from 'context/dashboardContext';

const getRowClassName = (row, isExplorads) => {
    let rowValue = isExplorads ? row[FIELD.ACTUAL_ROI_KEY] : row[FIELD.ROI_KEY];

    if (!rowValue && isExplorads && row?.[FIELD.ROI_KEY]) rowValue = row[FIELD.ROI_KEY];

    if (rowValue <= 99) return 'result-grid-row result-grid-red-row';
    if (rowValue <= 110) return 'result-grid-row result-grid-yellow-row';
    if (rowValue > 110) return 'result-grid-row result-grid-green-row';
    return 'result-grid-row';
};

const useTableColumns = (props) => {
    const {
        metrics = [],
        dimensions = [],
        columnsWidth = {},
        order = [],
        sorting = {},
        actionItem,
        onFilterCellClick,
        onToggleRowAction,
        isTotalRow,
    } = props;

    const dimensionColumns = useDimensionColumns({
        dimensions,
        columnsWidth,
        sorting,
        onCellClick: onFilterCellClick,
        isTotalRow,
    });

    const metricColumns = useMetricColumns({
        metrics,
        columnsWidth,
        sorting,
        onCellClick: onFilterCellClick,
        onToggleRowAction,
        actionItem,
        isTotalRow,
    });

    const dateColumn = useDateColumn({ columnsWidth, sorting, onCellClick: onFilterCellClick });
    // const actionColumn = useActionColumn({
    //     actionItem,
    //     onToggleRowAction,
    //     onCopyCreativeRowAction,
    //     copyCreativeRow,
    //     onCustomCopyAdset,
    // })
    const bulkSelectColumn = useBulkSelectColumn(isTotalRow);
    const emptyColumn = useEmptyColumn();

    const columns = (dimensions.some((d) => d === 'date') ? [dateColumn] : [])
        .concat(dimensionColumns)
        .concat(metricColumns)
        .filter(Boolean);

    if (!columns.length) return [];

    columns.push(emptyColumn);
    // if (actionItem) columns.push(actionColumn)
    // if (actionItem) columns.push(bulkSelectColumn)

    const sortedColumns = [bulkSelectColumn].concat(sortDimensions(columns, order, 'dataIndex'));

    const resultColumns = sortedColumns.map((col, index) => {
        return {
            ...col,
            title: col.isServiceColumn ? col.title : <span className="dragHandler">{col.title}</span>,
            width: index === sortedColumns.length - 1 ? undefined : col.width,
            onHeaderCell: col.isServiceColumn
                ? null
                : (column) => ({
                      width: column.width,
                      onResize: (e, { size }) => {
                          props.onColumnsSizeChange(column.dataIndex, size.width);
                      },
                  }),
        };
    });

    return resultColumns;
};

const ResizableTitle = (props) => {
    const { onResize, width, ...restProps } = props;
    const isServiceColumn = props.className?.includes('service-column');

    if (!width || isServiceColumn) {
        return <th {...restProps} />;
    }

    return (
        <Resizable
            width={width}
            height={0}
            handle={
                <span
                    className="react-resizable-handle"
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                />
            }
            onResize={onResize}
            minConstraints={[100, 0]}
            draggableOpts={{ enableUserSelectHack: true }}
        >
            <th {...restProps} />
        </Resizable>
    );
};

const ResultTable = (props) => {
    const { data, totalRow, order, pagination, runFlag, onChange, onPaginationChange, onColumnsOrderChange } = props;

    const dashboardContext = useContext(DashboardContext);
    const columns = useTableColumns(props);
    const summaryColumns = useTableColumns({ ...props, isTotalRow: true });

    const onDragEndHandler = (fromIndex, toIndex) => {
        // find columns by indexes
        const fromCol = columns[fromIndex];
        const toCol = columns[toIndex];
        // service column (bulk, empty column) position can not be changed
        // fixed column can be exchanged with fixed column only (same for non-fixed columns)
        if (fromCol && toCol && !fromCol.isServiceColumn && !toCol.isServiceColumn && fromCol.fixed === toCol.fixed) {
            // find indexes in order array by column dataIndex
            fromIndex = order.indexOf(fromCol.dataIndex);
            toIndex = order.indexOf(toCol.dataIndex);
            // if indexes found -> change order
            if (fromIndex >= 0 && toIndex >= 0) {
                const newOrder = [...order];
                const item = newOrder.splice(fromIndex, 1)[0];
                newOrder.splice(toIndex, 0, item);
                onColumnsOrderChange(newOrder);
            }
        }
    };

    return (
        <ReactDragListView.DragColumn
            onDragEnd={onDragEndHandler}
            nodeSelector="th"
            handleSelector=".dragHandler"
            ignoreSelector="react-resizable-handle"
        >
            <Table
                key={runFlag}
                className="result-table grid-table"
                rowKey={FIELD.ID_KEY}
                rowClassName={(row) => getRowClassName(row, ['Explorads', 'AdSense'].includes(dashboardContext.name))}
                loading={false}
                columns={columns}
                dataSource={data}
                size="small"
                pagination={{
                    position: ['none', 'bottomLeft'],
                    size: 'default',
                    pageSize: pagination?.pageSize || 15,
                    onChange: onPaginationChange,
                    showSizeChanger: true,
                }}
                scroll={{
                    x: window.innerWidth - 80, // >= 1850 ? 1850 : window.innerWidth - 80,
                    y: Math.max(window.innerHeight - 250, 450),
                }}
                onChange={onChange}
                bordered={false}
                components={{
                    header: { cell: ResizableTitle },
                }}
                summary={() => (
                    <Table.Summary fixed="top">
                        <Table.Summary.Row className={'result-grid-row ' + getRowClassName(totalRow)}>
                            {summaryColumns.map((c, i) => {
                                return (
                                    <Table.Summary.Cell key={c.dataIndex || i} index={i} className={'summary-cell'}>
                                        <div className="filterable-cell-content">
                                            {dashboardContext.metricsListHash[c.dataIndex]?.showTotal
                                                ? getMetricCellValues(
                                                      totalRow[c.dataIndex],
                                                      dashboardContext.metricsListHash[c.dataIndex]
                                                  )?.value
                                                : ''}
                                        </div>
                                    </Table.Summary.Cell>
                                );
                            })}
                        </Table.Summary.Row>
                    </Table.Summary>
                )}
            />
        </ReactDragListView.DragColumn>
    );
};

export default ResultTable;
