import React, { useContext } from 'react';
import { Modal } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { toggleRefreshDataPopup } from 'store/userActions/actionCreators';
import { runReports } from 'util/runReports';
import DashboardContext from 'context/dashboardContext';

const RefreshDataModal = () => {
    const dashboardContext = useContext(DashboardContext);

    const show = useSelector((store) => store.userActions.showRefreshDataPopup);
    const dispatch = useDispatch();

    const onClosePopup = () => {
        dispatch(toggleRefreshDataPopup(false));
    };

    const onRefreshData = () => {
        dispatch(runReports(dashboardContext, true));
        onClosePopup();
    };

    return (
        <Modal
            title="Refresh data"
            onCancel={onClosePopup}
            onOk={onRefreshData}
            okText="Refresh"
            maskClosable={false}
            visible={show}
        >
            <div>
                <p>Some data was updated</p>
                <p>To see fresh data - click Refresh</p>
            </div>
        </Modal>
    );
};

export default RefreshDataModal;
