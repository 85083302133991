import request from 'util/request';
import dayjs from 'dayjs';

const formatDate = (date = {}) => {
    return {
        ...date,
        range: [
            dayjs(date[0]).startOf('day').format('YYYY-MM-DD HH:mm:ss'),
            dayjs(date[1]).endOf('day').format('YYYY-MM-DD HH:mm:ss'),
        ],
    };
};

export const fetchCampaignLast7DaysData = (campaignId) => {
    return request.get(`/search-campaign/last7days/${campaignId}`);
};

export const fetchAdsetLast7DaysData = (adsetId) => {
    return request.get(`/search-campaign/adset-last7days/${adsetId}`);
};

export const fetchCampaignPerCountryData = (campaignId, preset) => {
    return request.post(`/search-campaign/per-country`, { data: { campaignId, preset } });
};

export const fetchAdSenseCampaignAdsData = (params) => {
    const data = {
        date: formatDate(params.datesRange),
        campaignId: params.campaignId,
    };
    return request.post(`/search-campaign/ads`, { data });
};
