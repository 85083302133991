import React, { useState, useEffect, useContext } from 'react';
import { notification, Button, Row, Space, Divider, Tooltip, Modal, Input, Checkbox } from 'antd';
import { SaveOutlined, ProjectOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';

import { setRangeDate } from 'store/currentSelection/actionCreators';

import DateRange from 'components/DateRange';
import DateInterval from 'components/DateInterval';
import AccessByRole from 'components/Permission/AccessByRole';

import { isRangeMoreThan } from 'util/quickRanges';
import { toggleLastHourMetrics, toggleDateDimension } from 'util/dimensionsHelper';
import { isExternal } from 'util/checkExternal';
import { isSameRange } from 'util/isSameRange';
import { quickRanges } from 'util/quickRanges';
import { runReports } from 'util/runReports';

import { togglePresetsModalVisibility, savePreset, getPresets } from 'store/currentSelection/actionCreators';

import UnivarsalActionsModal from 'components/UniversalActionsModal';
import DashboardPresetsModal from 'components/ReportDashboard/DashboardPresetsModal';
import DashboardContext from 'context/dashboardContext';

import './index.css';

const DateSelector = () => {
    const { range, preset, interval } = useSelector((store) => store.currentSelection.date);
    const dispatch = useDispatch();

    const onChangeRange = (newRange, newPreset) => {
        if (interval === 'hour' && isRangeMoreThan(newRange, 3, 'days')) {
            return notification.warn({
                message: 'Wrong date range',
                description: 'You can not select more than 3 days for the hourly interval',
            });
        }
        dispatch(setRangeDate({ range: newRange, preset: newPreset }));
    };

    return (
        <div style={{ width: '290px', marginRight: '20px' }}>
            <label>Date</label>
            <DateRange value={range} preset={preset} onChange={onChangeRange} />
        </div>
    );
};

const IntervalSelector = () => {
    const { range, interval } = useSelector((store) => store.currentSelection.date);
    const dispatch = useDispatch();

    const onChangeInterval = (newInterval) => {
        if (newInterval === 'hour' && isRangeMoreThan(range, 3, 'days')) {
            return notification.warn({
                message: 'Wrong interval',
                description: 'You can not select hourly interval for more than 3 days range',
            });
        }
        dispatch(setRangeDate({ interval: newInterval }));
        toggleLastHourMetrics(newInterval);
        toggleDateDimension(newInterval);
    };

    return (
        <div style={{ width: '290px', marginRight: '20px' }}>
            <label>Interval</label>
            <DateInterval value={interval} onChange={onChangeInterval} />
        </div>
    );
};

const ActionsCount = () => {
    const [showRunModal, setShowRunModal] = useState(false);
    const [showStatusModal, setShowStatusModal] = useState(false);
    const [showUniversalModal, setShowUniversalModal] = useState(false);

    const { actions = [] } = useSelector((store) => store.userActions);

    const toggleModal = (params = {}) => {
        const { run = false, status = false } = params;

        setShowRunModal(run);
        setShowStatusModal(status);
    };

    const toggleUniversalModal = () => {
        setShowUniversalModal(!showUniversalModal);
    };

    useEffect(() => {
        window.showActionsStatusPopup = () => toggleModal({ status: true });
        return () => {
            window.showActionsStatusPopup = null;
        };
    }, []);

    return (
        <div className="first-row-actions">
            {!isExternal() && (
                <div className="actions-label">
                    Actions selected: <span>{actions.length}</span>
                </div>
            )}
            <div className="actions-controls">
                <Button onClick={toggleUniversalModal}> Show actions menu </Button>
            </div>
            {showUniversalModal && <UnivarsalActionsModal onClose={toggleUniversalModal} />}
        </div>
    );
};

const SavePreset = () => {
    const dispatch = useDispatch();
    const dashboardContext = useContext(DashboardContext);
    const selectedPreset = useSelector((store) => store.currentSelection.selectedPreset);
    const allPresets = useSelector((store) => store.currentSelection.presets);
    const { id } = useSelector((store) => store.auth.user);

    const defaultPresetName = selectedPreset && selectedPreset.author === id ? selectedPreset.title : '';

    const [isVisible, setVisible] = useState(false);
    const [presetName, setPresetName] = useState(null);
    const [isShared, setShared] = useState(null);

    const save = () => {
        try {
            const name = presetName?.toString()?.trim() || defaultPresetName || '';

            // Имя должно быть длиннее 3х символов
            if (name.length <= 3) throw new Error('The name must be more than three characters long');

            // Если уже есть пресет с таким имененем
            if (selectedPreset && selectedPreset.title !== name && allPresets?.map((p) => p.title)?.includes(name))
                throw new Error('This name is already in use');

            if (
                selectedPreset && // У нас есть пресет
                selectedPreset.shared && // он общий
                selectedPreset.author !== id && // Пользователь не автор пресета
                selectedPreset.title === name // он использует такое же имя как и оригинал
            )
                throw new Error('This name is already in use');

            const shared = isShared === null ? !!selectedPreset?.shared : !!isShared;

            const update =
                selectedPreset && selectedPreset.author === id // пользователь автор пресета
                    ? selectedPreset.title === name // имя пресета не поменялось
                        ? true // обновляем пресет
                        : false // создаём новый пресет
                    : false; // создаём новый пресет

            dispatch(savePreset(name, shared, update, dashboardContext.name));
            setVisible(false);
        } catch (err) {
            notification.warn({
                message: 'Preset saving error',
                description: err.message,
            });
        }
    };

    const onChange = (event) => {
        const value = event.target.value;
        setPresetName(value);
    };

    const onSharedChange = (event) => {
        setShared(event.target.checked);
    };

    return (
        <>
            <Tooltip trigger={'hover'} placement="right" title={'Save current preset'}>
                <Button onClick={() => setVisible(true)} type="primary" icon={<SaveOutlined />} />
            </Tooltip>
            <Modal
                visible={isVisible}
                onCancel={() => setVisible(false)}
                onOk={save}
                title={'Preset saving settings'}
                width={350}
            >
                <Space align="center" size="large">
                    <Input
                        value={selectedPreset && presetName === null ? defaultPresetName : presetName}
                        //defaultValue={defaultPresetName}
                        onChange={onChange}
                    />
                    <Checkbox
                        checked={selectedPreset && isShared === null ? selectedPreset?.shared : !!isShared}
                        onChange={onSharedChange}
                    >
                        Shared
                    </Checkbox>
                </Space>
            </Modal>
        </>
    );
};

const ReportsConfigButton = () => {
    const dispatch = useDispatch();

    const onClick = () => {
        dispatch(togglePresetsModalVisibility());
    };

    return (
        <Button type="ghost" onClick={onClick} icon={<ProjectOutlined />}>
            Presets
        </Button>
    );
};

const DashboardHeader = () => {
    const dashboardContext = useContext(DashboardContext);
    const isDataFetching = useSelector((store) => store.dashboard.isDataFetching);
    const date = useSelector((store) => store.currentSelection.date);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getPresets(dashboardContext.name));
    }, []);

    const onRun = (forceUpdate) => {
        const { preset, range = [] } = date || {};
        const newRange = quickRanges()[preset];
        // if preset range is not the same as range in redux, update date in redux and run report
        if (newRange && range && !isSameRange(range, newRange)) {
            dispatch(setRangeDate({ range: newRange, preset }));
            setTimeout(() => {
                dispatch(runReports(dashboardContext, forceUpdate));
            }, 1000);
        } else {
            dispatch(runReports(dashboardContext, forceUpdate));
        }
    };

    return (
        <div
            className="dashboard-header-wrapper"
            style={{ border: 'thin solid #f0f0f0', borderRadius: 5, backgroundColor: 'white', padding: 10 }}
        >
            <Row align={'bottom'} justify={'space-between'}>
                <Space align="end" split={<Divider style={{ height: 30 }} type="vertical" />}>
                    <Space wrap align="end">
                        <DateSelector />
                        <IntervalSelector />
                        <div style={{ verticalAlign: 'bottom' }}>
                            <Button type="default" onClick={isDataFetching ? null : () => onRun(true)}>
                                Fetch
                            </Button>
                        </div>
                    </Space>
                    <Space>
                        <ReportsConfigButton />
                        <SavePreset />
                    </Space>
                </Space>
                <AccessByRole role="editor">
                    <ActionsCount />
                </AccessByRole>
            </Row>
            <DashboardPresetsModal />
        </div>
    );
};

export default DashboardHeader;
