import React from 'react';
import { dateSorter } from 'util/tables';

const useDateColumn = (props) => {
    const { columnsWidth = {}, sorting = {}, onCellClick } = props;
    const dataIndex = 'date';

    return {
        dataIndex,
        title: 'Date',
        className: 'grid-cell',
        width: columnsWidth[dataIndex] || 200,
        render: (v, r, ind) => {
            const cellKey = dataIndex + ind;
            return (
                <div
                    className={'filterable-cell-content ' + cellKey}
                    onClick={(e) => onCellClick(e, dataIndex, v, cellKey)}
                >
                    {v}
                </div>
            );
        },
        sorter: {
            compare: dateSorter('realDate'),
            multiple: 0,
        },
        sortOrder: sorting[dataIndex] || false,
    };
};

export default useDateColumn;
