import React from 'react';
import { Button } from 'antd';
import { LoginOutlined } from '@ant-design/icons';
import { useAuth } from 'hooks/useAuth';

export default () => {
    const { logout } = useAuth();

    return (
        <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', height: '100%' }}>
            <Button type="ghost" icon={<LoginOutlined />} onClick={logout}>
                Logout
            </Button>
        </div>
    );
};
