import React, { useContext } from 'react';
import { Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import BidBudgetAction from './components/BidBudgetAction';
import StatusAction from './components/StatusAction';
import AdStatusAction from './components/AdStatusAction';
import { addBulkSelectionAction, bulkSelectionToggleAllRows } from 'store/userActions/actionCreators';
import DashboardContext from 'context/dashboardContext';

import './index.css';

const selectedRowsCountSelector = (store) => Object.keys(store.userActions.bulkSelectedRowIds).length;

const CampaignActions = () => {
    const selectedRowsCount = useSelector(selectedRowsCountSelector);
    const dispatch = useDispatch();
    const dashboardContext = useContext(DashboardContext);
    const isAdSense = dashboardContext?.name === 'AdSense';

    const disabled = !selectedRowsCount;

    const onBulkAddAction = (action, params) => {
        dispatch(addBulkSelectionAction(action, params, isAdSense));
    };

    return (
        <div className="actions-block">
            <div className="block-title">Campaign</div>
            <div className="block-items">
                <div className="item">
                    <BidBudgetAction
                        actionType="budget"
                        title="Budget"
                        onAddAction={onBulkAddAction}
                        disabled={disabled}
                    />
                </div>
            </div>
        </div>
    );
};

const AdsetActions = () => {
    const selectedRowsCount = useSelector(selectedRowsCountSelector);
    const dispatch = useDispatch();

    const dashboardContext = useContext(DashboardContext);
    const isAdSense = dashboardContext?.name === 'AdSense';

    const disabled = !selectedRowsCount;

    const onBulkAddAction = (action, params) => {
        dispatch(addBulkSelectionAction(action, params, isAdSense));
    };

    return (
        <div className="actions-block">
            <div className="block-title">Adset</div>
            <div className="block-items">
                <div className="item">
                    <BidBudgetAction actionType="bid" title="Bid" onAddAction={onBulkAddAction} disabled={disabled} />
                </div>
                <div className="item">
                    <StatusAction title="Status" onAddAction={onBulkAddAction} disabled={disabled} />
                </div>
            </div>
        </div>
    );
};

const AdActions = () => {
    const selectedRowsCount = useSelector(selectedRowsCountSelector);
    const dispatch = useDispatch();

    const dashboardContext = useContext(DashboardContext);
    const isAdSense = dashboardContext?.name === 'AdSense';

    const disabled = !selectedRowsCount;

    const onBulkAddAction = (action, params) => {
        dispatch(addBulkSelectionAction(action, params, isAdSense));
    };

    return (
        <div className="actions-block">
            <div className="block-title">Ad</div>
            <div className="block-items">
                <div className="item">
                    <AdStatusAction title="Status" onAddAction={onBulkAddAction} disabled={disabled} />
                </div>
            </div>
        </div>
    );
};

const BulkSelection = () => {
    const selectedRowsCount = useSelector(selectedRowsCountSelector);
    const dispatch = useDispatch();

    const onToggleAll = (select) => {
        dispatch(bulkSelectionToggleAllRows(select));
    };

    return (
        <div className="bulk-selection">
            <div>
                <span>Selected rows:</span>
                <span className="selected-count">{selectedRowsCount}</span>
            </div>
            <Button type="default" onClick={() => onToggleAll(true)}>
                Select all
            </Button>
            <Button type="default" onClick={() => onToggleAll(false)}>
                Deselect all
            </Button>
        </div>
    );
};

const BulkActions = () => {
    const actionItem = useSelector((store) => store.dashboard.actionItem);
    return (
        <div className="bulk-actions">
            <CampaignActions />
            {actionItem === 'adset' && <AdsetActions />}
            {actionItem === 'ad' && <AdActions />}
            <BulkSelection />
        </div>
    );
};

export default BulkActions;
