// userActions
export const ACTION_ADD = 'ACTION_ADD';
export const ACTION_CHANGE = 'ACTION_CHANGE';
export const ACTION_REMOVE = 'ACTION_REMOVE';
export const ACTIONS_RUNNING_SET = 'ACTIONS_RUNNING_SET';
export const ACTIONS_STATUS_FETCHING_SET = 'ACTIONS_STATUS_FETCHING_SET';
export const ACTIONS_STATUS_SET = 'ACTIONS_STATUS_SET';
export const PENDING_ACTIONS_STATUS_FETCHING_SET = 'PENDING_ACTIONS_STATUS_FETCHING_SET';
export const PENDING_ACTIONS_SET = 'PENDING_ACTIONS_SET';
export const MAIN_BULK_ROWS_SET = 'MAIN_BULK_ROWS_SET';
export const SIDER_COLLAPSED = 'SIDER_COLLAPSED';
export const SET_REFRESH_DATA_POPUP = 'SET_REFRESH_DATA_POPUP';

// dashboard
export const MAIN_DATA_FETCHING_SET = 'MAIN_DATA_FETCHING_SET';
export const MAIN_AFTER_RUN_SET = 'MAIN_AFTER_RUN_SET';
export const MAIN_SORTING_SET = 'MAIN_SORTING_SET';
export const MAIN_PAGINATION_SET = 'MAIN_PAGINATION_SET';
export const MAIN_DIMENSIONS_ORDER_SET = 'MAIN_DIMENSIONS_ORDER_SET';
export const MAIN_COLUMNS_WIDTH_SET = 'MAIN_COLUMNS_WIDTH_SET';

// currentSelection
export const DATE_SELECT = 'DATE_SELECT';
export const DIMENSIONS_HIDE = 'DIMENSIONS_HIDE';
export const DIMENSIONS_SELECT = 'DIMENSIONS_SELECT';
export const METRICS_SELECT = 'METRICS_SELECT';
export const METRICS_DISABLE = 'METRICS_DISABLE';
export const FILTER_ADD = 'FILTER_ADD';
export const FILTERS_ADD = 'FILTERS_ADD';
export const FILTER_CHANGE = 'FILTER_CHANGE';
export const FILTER_REMOVE = 'FILTER_REMOVE';
export const FILTER_REMOVE_EMPTY = 'FILTER_REMOVE_EMPTY';
export const DIMENSIONS_ORDER_SET = 'DIMENSIONS_ORDER_SET';
export const FILTER_OPTIONS_SET = 'FILTER_OPTIONS_SET';
export const CHANGE_HIDDEN = 'CHANGE_HIDDEN';
export const LOCK_PRESET_EDITING = 'LOCK_PRESET_EDITING';
export const USER_COLUMNS_WIDTH_SET = 'USER_COLUMNS_WIDTH_SET';

//PRESETS
export const CHANGE_PRESETS_MODAL_VISIBILITY = 'CHANGE_PRESETS_MODAL_VISIBILITY';
export const SET_PRESETS = 'SET_PRESETS';
export const SET_NEW_PRESET = 'SET_NEW_PRESET';
export const SET_SELECTED_PRESET = 'SET_SELECTED_PRESET';
export const SET_PRESETS_LOADING = 'SET_PRESETS_LOADING';

// campaignDataActions
export const CHANGE_CAMPAIGN_MODAL_VISIBILITY = 'CHANGE_CAMPAIGN_MODAL_VISIBILITY';
export const CAMPAIGN_DATA_LOADING = 'CAMPAIGN_DATA_LOADING';
export const LIMITS_LOADING = 'LIMITS_LOADING';
export const SET_CAMPAIGN_ADSETS_DATA = 'SET_CAMPAIGN_ADSETS_DATA';
export const SET_CAMPAIGN_ADS_DATA = 'SET_CAMPAIGN_ADS_DATA';
export const CHANGE_CAMPAIGN_MODAL_NAME = 'CHANGE_CAMPAIGN_MODAL_NAME';
export const CHANGE_CAMPAIGN_MODAL_TYPE = 'CHANGE_CAMPAIGN_MODAL_TYPE';
export const SET_ADS_LIMiTS = 'SET_ADS_LIMiTS';
export const SET_CURRENT_CAMPAIGN_ID = 'SET_CURRENT_CAMPAIGN_ID';
export const SET_ACCOUNT_LIMITS = 'SET_ACCOUNT_LIMITS';

// auth
export const USER_SET = 'USER_SET';

// accounts
export const SET_ACCOUNT_ADSETS_COUNT = 'SET_ACCOUNT_ADSETS_COUNT';

//tags
export const CHANGE_TAGS_MODAL_VISIBILITY = 'CHANGE_TAGS_MODAL_VISIBILITY';
export const SET_TAG_TARGET_ADSET_NAME = 'SET_TAG_TARGET_ADSET_NAME';
export const SET_TAGS_LIST = 'SET_TAGS_LIST';

// adset history modal actiions
export const CHANGE_ADSET_HISTORY_MODAL_VISIBILITY = 'CHANGE_ADSET_HISTORY_MODAL_VISIBILITY';
export const SET_ADSET_HISTORY_LOADING = 'SET_ADSET_HISTORY_LOADING';
export const SET_ADSET_HISTORY = 'SET_ADSET_HISTORY';

// Ad History Actions
export const SET_AD_HISTORY_LOADING = 'SET_AD_HISTORY_LOADING';
export const SET_AD_HISTORY = 'SET_AD_HISTORY';

// Search Campaign Statistics
export const CHANGE_SEARCH_CAMPAIGN_STATISTICS_MODAL_VISIBILITY = 'CHANGE_SEARCH_CAMPAIGN_STATISTICS_MODAL_VISIBILITY';
export const SET_CAMPAIGN_LAST_7_DAYS_DATA = 'SET_CAMPAIGN_LAST_7_DAYS_DATA';
export const SET_CAMPAIGN_PER_COUNTRY_DATA = 'SET_CAMPAIGN_PER_COUNTRY_DATA';
export const SET_CAMPAIGN_LAST_7_DAYS_DATA_LOADING = 'SET_CAMPAIGN_LAST_7_DAYS_DATA_LOADING';
export const SET_CAMPAIGN_PER_COUNTRY_DATA_LOADING = 'SET_CAMPAIGN_PER_COUNTRY_DATA_LOADING';
export const SET_TARGET_CAMPAIGN_ID = 'SET_TARGET_CAMPAIGN_ID';
export const SET_CURRENT_ADSET_ID = 'SET_CURRENT_ADSET_ID';
export const RESET_SEARCH_CAMPAIGN_STATISTICS = 'RESET_SEARCH_CAMPAIGN_STATISTICS';

// Taboola
export const SET_TABOOLA_CAMPAIGN_ID = 'SET_TABOOLA_CAMPAIGN_ID';

export const SET_TABOOLA_CAMPAIGN_SITE_DATA = 'SET_TABOOLA_CAMPAIGN_SITE_DATA';
export const SET_TABOOLA_CAMPAIGN_SITE_DATA_LOADING = 'SET_TABOOLA_CAMPAIGN_SITE_DATA_LOADING';
export const SET_TABOOLA_CAMPAIGN_SITE_POPUP_VISIBLE = 'SET_TABOOLA_CAMPAIGN_SITE_POPUP_VISIBLE';
export const SET_TABOOLA_CAMPAIGN_SITE_DATA_RANGE = 'SET_TABOOLA_CAMPAIGN_SITE_DATA_RANGE';

export const SET_TABOOLA_CAMPAIGN_ADS_DATA = 'SET_TABOOLA_CAMPAIGN_ADS_DATA';
export const SET_TABOOLA_CAMPAIGN_ADS_DATA_LOADING = 'SET_TABOOLA_CAMPAIGN_ADS_DATA_LOADING';
export const SET_TABOOLA_CAMPAIGN_ADS_POPUP_VISIBLE = 'SET_TABOOLA_CAMPAIGN_ADS_POPUP_VISIBLE';
export const SET_TABOOLA_CAMPAIGN_ADS_DATA_RANGE = 'SET_TABOOLA_CAMPAIGN_ADS_DATA_RANGE';

export const SET_TABOOLA_CAMPAIGN_PER_DAY_DATA = 'SET_TABOOLA_CAMPAIGN_PER_DAY_DATA';
export const SET_TABOOLA_CAMPAIGN_PER_DAY_DATA_LOADING = 'SET_TABOOLA_CAMPAIGN_PER_DAY_DATA_LOADING';
export const SET_TABOOLA_CAMPAIGN_PER_DAY_POPUP_VISIBLE = 'SET_TABOOLA_CAMPAIGN_PER_DAY_POPUP_VISIBLE';

export const SET_TABOOLA_ALL_ACTIVITY_SITE_DATA_RANGE = 'SET_TABOOLA_ALL_ACTIVITY_SITE_DATA_RANGE';
export const SET_TABOOLA_ALL_ACTIVITY_SITE_DATA_LOADING = 'SET_TABOOLA_ALL_ACTIVITY_SITE_DATA_LOADING';
export const SET_TABOOLA_ALL_ACTIVITY_SITE_DATA_DATA = 'SET_TABOOLA_ALL_ACTIVITY_SITE_DATA_DATA';
export const SET_TABOOLA_ALL_ACTIVITY_SITE_DATA_VISIBLE = 'SET_TABOOLA_ALL_ACTIVITY_SITE_DATA_VISIBLE';

// AdSense
export const SET_ADSENSE_CAMPAIGN_ID = 'SET_ADSENSE_CAMPAIGN_ID';

export const SET_ADSENSE_ADS_DATA = 'SET_ADSENSE_ADS_DATA';
export const SET_ADSENSE_ADS_DATA_LOADING = 'SET_ADSENSE_ADS_DATA_LOADING';
export const SET_ADSENSE_ADS_POPUP_VISIBLE = 'SET_ADSENSE_ADS_POPUP_VISIBLE';
export const SET_ADSENSE_ADS_DATE_RANGE = 'SET_ADSENSE_ADS_DATE_RANGE';
