import React, { useState, useContext } from 'react';
import _ from 'lodash';
import dayjs from 'dayjs';
import {
    Modal,
    Table,
    Input,
    Typography,
    Switch,
    message,
    Statistic,
    Space,
    Card,
    Tooltip,
    InputNumber,
    notification,
} from 'antd';
import {
    ArrowUpOutlined,
    ArrowDownOutlined,
    SearchOutlined,
    ExclamationCircleFilled,
    HistoryOutlined,
} from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import getMetricCellValues from 'util/getMetricCellValues';
import { stringSorter, numberSorter, dateSorter } from 'util/tables';
import { hasUserPermissions } from 'util/permissions';
import DashboardContext from 'context/dashboardContext';
import * as FIELD from 'data/fields';

import { fetchAdHistory } from 'store/userActions/actionCreators';

import * as AdsenseActions from 'store/adsense/actionCreator';
import { getActionFromObject } from 'util/currentPendingActions';
import { toggleAction } from 'store/userActions/actionCreators';
import DateRange from 'components/DateRange';

// const { Text, Link } = Typography;

const NotEditableStatuses = [
    'DELETED',
    'PENDING_REVIEW',
    'DISAPPROVED',
    'CAMPAIGN_PAUSED',
    'ARCHIVED',
    'ADSET_PAUSED',
    'WITH_ISSUES',
    'UNKNOWN',
];

const AdStatusSwitch = (props) => {
    const { row } = props;

    const dashboardContext = useContext(DashboardContext);
    const dispatch = useDispatch();

    const { user } = useSelector((store) => store.auth);
    // const changedLimits = useSelector((store) => store.campaignData.adsLimits);
    // const accountLimits = useSelector((store) => store.campaignData.accountLimits);
    // const limitsLoading = useSelector((store) => store.campaignData.limitsLoading);

    const cellCampaignCurrentAction = useSelector((store) =>
        getActionFromObject(store.userActions.currentActions, 'campaignEffectiveStatus', {
            campaignId: row.campaignId,
        })
    );

    const cellCampaignPendingAction = useSelector((store) =>
        getActionFromObject(store.userActions.pendingActions, 'campaignEffectiveStatus', {
            campaignId: row.campaignId,
        })
    );

    const cellAdsetCurrentAction = useSelector((store) =>
        getActionFromObject(store.userActions.currentActions, 'adsetEffectiveStatus', {
            adsetId: row.adsetId,
        })
    );

    const cellAdsetPendingAction = useSelector((store) =>
        getActionFromObject(store.userActions.pendingActions, 'adsetEffectiveStatus', {
            adsetId: row.adsetId,
        })
    );

    const cellCurrentAction = useSelector((store) =>
        getActionFromObject(store.userActions.currentActions, 'adStatus', {
            adId: row.adId,
        })
    );

    const cellPendingAction = useSelector((store) =>
        getActionFromObject(store.userActions.pendingActions, 'adStatus', {
            adId: row.adId,
        })
    );

    // Render error icon in case if we can't change status of an ad
    if (NotEditableStatuses.includes(row.adStatus)) {
        return (
            <Tooltip title={`Ad status can't be edited: Current status ${row.adStatus}!`}>
                <ExclamationCircleFilled style={{ color: '#FF0000' }} />
            </Tooltip>
        );
    }

    const rowBusiness = row[FIELD.BUSINESS_KEY];
    const canUserEdit = hasUserPermissions('editor', user, rowBusiness ? [rowBusiness] : []);

    const getStatus = (
        campaignCurrentActions,
        campaignPendingActions,
        cellAdsetCurrentAction,
        cellAdsetPendingAction,
        cellCurrentAction,
        cellPendingAction,
        value
    ) => {
        // If we have pending actions on campaign, and we want to change campaign status to PAUSED
        // => we say that status of row is PAUSED
        if (campaignPendingActions && campaignPendingActions.newValue !== dashboardContext.status.ACTIVE)
            return campaignPendingActions.newValue;

        // If we have new actions on campaign, and we want to change campaign status to PAUSED
        // => we say that status of row is PAUSED
        if (campaignCurrentActions && campaignCurrentActions.newValue !== dashboardContext.status.ACTIVE)
            return campaignCurrentActions.newValue;

        // If we have new actions on adset, and we want to change adsets status to PAUSED
        // => we say that new status of adset is status defined in action (PAUSED)
        if (cellAdsetPendingAction && cellAdsetPendingAction.newValue !== dashboardContext.status.ACTIVE)
            return cellAdsetPendingAction.newValue;

        // If we have pending actions on adset, and we want to change adsets status to PAUSED
        // => we say that new status of adset is status defined in action (PAUSED)
        if (cellAdsetCurrentAction && cellAdsetCurrentAction.newValue !== dashboardContext.status.ACTIVE)
            return cellAdsetCurrentAction.newValue;

        // If we have pending action on ad => return status from action
        if (cellPendingAction) return cellPendingAction.newValue;

        // If we have new action on ad => return status from action
        if (cellCurrentAction) return cellCurrentAction.newValue;

        // In case if we don't have any new/pending changes on ad => get status from ad
        return value;
    };

    const currentStatus = getStatus(
        cellCampaignCurrentAction,
        cellCampaignPendingAction,
        cellAdsetCurrentAction,
        cellAdsetPendingAction,
        cellCurrentAction,
        cellPendingAction,
        row.adStatus
    );

    const onStatusChange = (row, checked) => {
        const newStatus = checked ? dashboardContext.status.ACTIVE : dashboardContext.status.PAUSED;
        const action = {
            action: 'status',
            item: 'ad',
            business: row.business,
            ad_id: row.adId,
            ad_name: row.adName,
            ad_set_id: row.adsetId,
            ad_set_name: row.adsetName,
            campaign_id: row.campaignId,
            account_id: row.accountId,
            oldAdStatus: row.adStatus,
            newAdStatus: newStatus,
        };

        // const affectedPages = row?.affectedPages || [];
        // for (const page of affectedPages) {
        //     const value = checked ? page.affectValue : page.affectValue * -1;
        //     dispatch(changeAdsLimits(page.pageId, value));
        // }

        // const accountChangeValue = checked ? row.affectedAccount.affectValue : row.affectedAccount.affectValue * -1;
        // dispatch(changedAccountLimits(row.affectedAccount.id, accountChangeValue));

        dispatch(toggleAction(action, true)).then((res) => {
            if (res) {
                message.success('Added', 1.5);
            } else {
                message.warn('Cancelled', 1.5);
            }
        });
    };

    const isDisabled = (data, status, isPending = false) => {
        /* Block changes on pending adsets */
        if (isPending) return true;

        const isActive = status === dashboardContext.status.ACTIVE;
        if (isActive) return false;

        return false;
    };

    const disabled = isDisabled(row, currentStatus, !!cellPendingAction?.length);

    return (
        <Switch
            size="small"
            onChange={!canUserEdit ? null : (checked) => onStatusChange(row, checked)}
            checked={currentStatus === dashboardContext.status.ACTIVE}
            disabled={disabled}
        />
    );
};

const ActionsTable = (props) => {
    const { row } = props;
    const dispatch = useDispatch();
    const { adHistory: data, loadingAdHistory: loading } = useSelector((state) => state.userActions);

    const columns = [
        {
            title: 'Action',
            dataIndex: 'actionName',
            key: 'actionName',
        },
        {
            title: 'Action Details',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Changed By',
            dataIndex: 'user',
            key: 'user',
        },
        {
            title: 'Date and Time',
            dataIndex: 'actionTime',
            key: 'actionTime',
            render: (date) => dayjs.tz(date, 'EST').tz('America/Los_Angeles').format('YYYY-MM-DD HH:mm:ss'),
        },
    ];

    // const generateRandomText = (length) => {
    //     const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    //     let result = '';
    //     const charactersLength = characters.length;
    //     for (let i = 0; i < length; i++) {
    //         result += characters.charAt(Math.floor(Math.random() * charactersLength));
    //     }
    //     return result;
    // };

    // const sampleData = Array.from({ length: 15 }, () => ({
    //     actionName: 'Change Status',
    //     description: generateRandomText(Math.floor(Math.random() * 6) + 10), // Random length between 10 and 15
    //     user: 'User' + Math.floor(Math.random() * 100), // Placeholder user name
    //     actionTime: new Date().toISOString(), // Current date and time
    // }));

    return <Table dataSource={data?.[row.adId] || []} rowKey={(row) => row.id} columns={columns} loading={loading} />;
};

const AdsDataTable = (props) => {
    const dispatch = useDispatch();
    const { data, loading } = props;

    const onRowExpand = (expanded, record) => {
        if (expanded) {
            // Request history of ad
            dispatch(fetchAdHistory(record.adId));
        }
    };

    const columns = [
        {
            title: 'Status',
            dataIndex: 'adStatus',
            key: 'adStatus',
            width: 100,
            render: (value, row) => <AdStatusSwitch row={row} />,
        },
        Table.EXPAND_COLUMN,
        {
            title: 'Ad Name',
            dataIndex: 'adName',
            key: 'adName',
            width: 350,
            sorter: (a, b) => stringSorter('adId')(a, b),
        },
        {
            title: 'ActClicks',
            dataIndex: 'exploradsClicks',
            key: 'exploradsClicks',
            width: 100,
            sorter: (a, b) => numberSorter('exploradsClicks')(a, b),
        },
        {
            title: 'Cost',
            dataIndex: 'spend',
            key: 'spend',
            width: 100,
            sorter: (a, b) => numberSorter('spend')(a, b),
            render: (value) => {
                return <span>{getMetricCellValues(value, { precision: 2, prefix: '$', isNumber: true }).value}</span>;
            },
        },
        {
            title: 'Revenue',
            dataIndex: 'actualRevenue',
            key: 'revenue',
            width: 100,
            render: (value) => {
                return (
                    <span>
                        {getMetricCellValues(value, { prefix: '$', postfix: '', precision: 1, isNumber: true }).value}
                    </span>
                );
            },
        },
        {
            title: 'Profit',
            dataIndex: 'profit',
            key: 'profit',
            width: 100,
            sorter: (a, b) => numberSorter('profit')(a, b),
            render: (value) => {
                return <span>{getMetricCellValues(value, { precision: 2, prefix: '$', isNumber: true }).value}</span>;
            },
        },
        {
            title: 'CPR',
            dataIndex: 'cpr',
            key: 'cpr',
            width: 100,
            render: (value) => {
                return (
                    <span>
                        {getMetricCellValues(value, { prefix: '€', postfix: '', precision: 2, isNumber: true }).value}
                    </span>
                );
            },
        },
        {
            title: 'RPC',
            dataIndex: 'rpc',
            key: 'rpc',
            width: 100,
            render: (value) => {
                return (
                    <span>
                        {getMetricCellValues(value, { prefix: '€', postfix: '', precision: 2, isNumber: true }).value}
                    </span>
                );
            },
        },
        {
            title: 'ROI-Y',
            dataIndex: 'roiYesterday',
            key: 'roiYesterday',
            width: 100,
            sorter: (a, b) => numberSorter('roiYesterday')(a, b),
            render: (value) => {
                return (
                    <span>
                        {getMetricCellValues(value, { prefix: '', postfix: '%', precision: 1, isNumber: true }).value}
                    </span>
                );
            },
        },
        {
            title: 'ROI',
            dataIndex: 'roi',
            key: 'roi',
            width: 100,
            sorter: (a, b) => numberSorter('roi')(a, b),
            render: (value) => {
                return (
                    <span>
                        {getMetricCellValues(value, { prefix: '', postfix: '%', precision: 1, isNumber: true }).value}
                    </span>
                );
            },
        },
        {
            title: 'ROI-3H',
            dataIndex: 'roiLast3Hours',
            key: 'roiLast3Hours',
            width: 100,
            sorter: (a, b) => numberSorter('roiLast3Hours')(a, b),
            render: (value) => {
                return (
                    <span>
                        {getMetricCellValues(value, { prefix: '', postfix: '%', precision: 1, isNumber: true }).value}
                    </span>
                );
            },
        },
        // {
        //     title: 'Actions',
        //     // dataIndex: 'actions',
        //     key: 'actions',
        //     width: 100,
        //     fixed: 'right',
        //     render: (value, row) => {
        //         return (
        //             <Space>
        //                 <HistoryOutlined onClick={} />
        //             </Space>
        //         );
        //     },
        // }
    ];

    return (
        <Table
            size={'large'}
            rowKey={'adId'}
            dataSource={data || []}
            columns={columns}
            expandable={{
                expandedRowRender: (record) => <ActionsTable row={record} />,
                onExpand: onRowExpand,
                expandIcon: ({ expanded, onExpand, record }) => {
                    return <HistoryOutlined onClick={(e) => onExpand(record, e)} />;
                },
                // rowExpandable: record => record.name !== 'Not Expandable',
            }}
            loading={loading}
            pagination={false}
            scroll={{ x: '100vw', y: '45vh' }}
        />
    );
};

const AdSenseAdsDataModal = () => {
    const dispatch = useDispatch();
    const {
        selectedCampaignId,
        adSenseCampaignAdsData,
        adSenseCampaignAdsPopupVisible,
        adSenseCampaignAdsDataLoading,
        adSenseCampaignAdsDataSelectedRange,
    } = useSelector((state) => state.adsense);

    const onClose = () => {
        dispatch(AdsenseActions.setAdSenseAdsTargetCampaignId(''));
        dispatch(AdsenseActions.setAdSenseAdsPopupVisible());
        dispatch(AdsenseActions.setAdSenseAdsData([]));
    };

    const onDateRangeChange = (date) => {
        // console.log('Selected date range:', date);
        dispatch(AdsenseActions.setAdSenseAdsDateRange(date));
        dispatch(AdsenseActions.fetchAdSenseCampaignAdsData(selectedCampaignId, date));
    };

    return (
        <Modal
            title={'Ads Data'}
            footer={false}
            maskClosable
            width={'85vw'}
            onCancel={onClose}
            visible={adSenseCampaignAdsPopupVisible}
        >
            <div style={{ width: 240 }}>
                <DateRange
                    value={adSenseCampaignAdsDataSelectedRange}
                    disablePresets={true}
                    onChange={onDateRangeChange}
                />
            </div>
            <AdsDataTable data={adSenseCampaignAdsData} loading={adSenseCampaignAdsDataLoading} />
        </Modal>
    );
};

export default AdSenseAdsDataModal;
