// import _ from 'lodash';
import * as actions from 'store/actions';
import { HIDE_TYPE } from 'data/hideTypes';
import * as FIELD from 'data/fields';

const ACTION_HANDLERS = {
    [actions.MAIN_DATA_FETCHING_SET]: (state, { fetching }) => {
        return {
            ...state,
            isDataFetching: fetching,
        };
    },
    [actions.MAIN_AFTER_RUN_SET]: (
        state,
        { data = [], filteredData = [], totalRow = {}, columnsWidth = {}, actionItem, afterRun = {}, sorting = {} }
    ) => {
        return {
            ...state,
            data,
            filteredData,
            totalRow,
            columnsWidth,
            actionItem,
            sorting,
            isDataFetching: false,
            runFlag: !state.runFlag,
            // save current selection
            date: afterRun.date,
            filters: afterRun.filters,
            dimensions: afterRun.dimensions,
            metrics: afterRun.metrics,
            hidden: afterRun.hidden[HIDE_TYPE.MAIN],
            disabledMetrics: afterRun.disabledMetrics,
            order: afterRun.dimensionsOrder,
        };
    },
    [actions.MAIN_SORTING_SET]: (state, { sorting = {} }) => {
        return {
            ...state,
            sorting,
        };
    },
    [actions.MAIN_PAGINATION_SET]: (state, { pagination = {} }) => {
        return {
            ...state,
            pagination,
        };
    },

    [actions.MAIN_DIMENSIONS_ORDER_SET]: (state, { order = {} }) => {
        return {
            ...state,
            order,
        };
    },
    [actions.MAIN_COLUMNS_WIDTH_SET]: (state, { columnsWidth = {} }) => {
        return {
            ...state,
            columnsWidth,
        };
    },
};

const initialState = {
    date: {},
    filters: {},
    dimensions: [],
    metrics: [],
    hidden: {},
    disabledMetrics: {},

    actionItem: '',

    runFlag: false,
    isDataFetching: false,
    data: [],
    filteredData: [],
    totalRow: {},
    columnsWidth: {},
    order: [],

    sorting: {
        [FIELD.SPEND_KEY]: 'descend',
    },
    pagination: {
        pageSize: 15,
    },
};

export default function (state = initialState, action) {
    const handler = ACTION_HANDLERS[action.type];

    return handler ? handler(state, action) : state;
}
