import React, { useContext } from 'react';
import * as Papa from 'papaparse';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Button, Tooltip } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import DashboardContext from 'context/dashboardContext';
import formatNumber from 'util/formatNumber';

const getFormatter = (props) => {
    if (!props.isNumber) {
        return (value) => value;
    }

    const postfix = props.postfix === '%' ? '%' : '';
    return formatNumber({ postfix }, props.precision, false)
}

const getReportName = (presetName, date) => {
    let timeFrame = '';
    if (date.interval === 'day') timeFrame = 'daily';
    if (date.interval === 'hour') timeFrame = 'hourly';
    if (date.interval === 'week') timeFrame = 'weekly';
    if (date.interval === 'month') timeFrame = 'monthly';

    const timeRange = date.preset ? date.preset.toLowerCase() : `${date.range[0].format('DDMMMYYYY')}-${date.range[1].format('DDMMMYYYY')}`;

    return `${_.kebabCase(presetName)}_${timeFrame}_${timeRange}.csv`;
}

const sortData = (data, sorting, fieldsProps) => {
    if (!sorting || !Object.keys(sorting).length) return data;

    const sortField = Object.keys(sorting).shift(); // get first item from object
    if (!sortField) return data;

    const sortValue = sorting[sortField] === "ascend" ? "asc" : "desc";
    if (!sortValue) return data;

    return _.orderBy(data, [sortField], [sortValue]);

}

export const ExportCSVButton = () => {
    const dashboardContext = useContext(DashboardContext);
    const {
        filteredData = [],
        dimensions: selectedDimensions = [],
        metrics: selectedMetrics = [],
        order = [],
        hidden = {},
        sorting = {},
    } = useSelector((store) => store.dashboard);

    const { selectedPreset, date } = useSelector((store) => store.currentSelection);

    let presetName = 'custom';
    if (
        selectedPreset &&
        _.difference(selectedPreset.dimensions, selectedDimensions).length === 0 &&
        _.difference(selectedPreset.metrics, selectedMetrics).length === 0
    ) presetName = selectedPreset.title;

    const fieldsProps = {...dashboardContext.dimensionsListHash, ...dashboardContext.metricsListHash};

    const onDownload = () => {
        const csvData = sortData(filteredData, sorting, fieldsProps)
            .map((item) => {
                const row = {};
                // First use order to get the order of the fields
                for (const field of order) {
                    const fieldProps = fieldsProps[field];
                    const formatter = getFormatter(fieldProps);
                    if (!hidden[field]) {
                        row[fieldProps.title] = formatter(item[field]);
                    }
                }

                // Add missed fields
                for (const dimension of selectedDimensions) {
                    const fieldProps = fieldsProps[dimension];
                    const formatter = getFormatter(fieldProps);
                    if (!hidden[dimension] && !row[dimension]) {
                        row[fieldProps.title] = formatter(item[dimension]);
                    }
                }

                for (const metric of selectedMetrics) {
                    const fieldProps = fieldsProps[metric];
                    const formatter = getFormatter(fieldProps);
                    if (!hidden[metric]  && !row[metric]) {
                        row[fieldProps.title] = formatter(item[metric]);
                    }
                }

                return row;
            })
            .filter(item => Object.keys(item).length > 0);

        const csvDataToExportString = Papa.unparse(csvData);
        const csvDataToExportBlob = new Blob([csvDataToExportString], { type: 'text/csv' });
        const csvDataToExportUrl = URL.createObjectURL(csvDataToExportBlob);
        const link = document.createElement('a');
        link.href = csvDataToExportUrl;
        const reportName = getReportName(presetName, date);
        link.setAttribute('download', reportName);
        document.body.appendChild(link);
        link.click();
    }

    return (
        <>
            <Tooltip title="Export Data CSV">
                <DownloadOutlined style={{fontSize: 25}} onClick={onDownload}/>
            </Tooltip>
        </>
    )
};