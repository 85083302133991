import * as FIELD from 'data/fields';
import { BID_BUDGET_ACTIONS, BID_BUDGET_UNITS } from 'data/actions';
import isNewCampaignType from 'util/isNewCampaignType';

export const getRowActionKey = (item, action, row = {}) => {
    if (item === 'adset') return action + row.adsetId;
    if (item === 'campaign') return action + row.campaignId;
};

export const getActionRowKey = (action = {}) => {
    if (action.item === 'adset') return action.action + action.ad_set_id;
    if (action.item === 'campaign') return action.action + action.campaign_id;
    if (action.item === 'ad') return action.action + action.ad_id;
};

export const calculateNewBidBudget = (bidBudgetAction, units, value, oldValue = 0) => {
    const oldValueCents = oldValue * 100;

    let changingValue = 0;
    if (units === BID_BUDGET_UNITS.PERCENTAGE) {
        changingValue = oldValueCents * (value / 100);
    } else if (units === BID_BUDGET_UNITS.DOLLAR) {
        changingValue = value * 100; // cents
    }

    let newValueCents =
        bidBudgetAction === BID_BUDGET_ACTIONS.SET
            ? changingValue
            : bidBudgetAction === BID_BUDGET_ACTIONS.DECREASE
            ? oldValueCents - changingValue
            : oldValueCents + changingValue;

    newValueCents = Math.round(newValueCents);

    return newValueCents / 100; // back to dollars
};

export const getActionFromRow = (actionItem, action, row = {}, params = {}) => {
    const item =
        actionItem === 'adset' && action === 'budget' && !isNewCampaignType(row?.campaignName || '')
            ? 'campaign'
            : actionItem;

    return {
        action,
        item,
        business: row[FIELD.BUSINESS_KEY],
        rowId: row[FIELD.ID_KEY],
        ad_id: row.creative_id,
        ad_name: row.creative,
        ad_set_id: row.adsetId,
        ad_set_name: row.adsetName,
        campaign_id: row.campaignId,
        campaign_name: row.campaignName,
        account_id: row.accountId,
        bid: row[FIELD.BID_KEY],
        budget: row[FIELD.BUDGET_KEY],
        new_bid: params.new_bid,
        new_budget: params.new_budget,

        oldAdsetStatus: row.adsetEffectiveStatus,
        newAdsetStatus: params.newAdsetStatus,

        oldCampaignStatus: row.campaignEffectiveStatus,
        newCampaignStatus: params.newCampaignStatus,

        article_id: params.article_id,
        creative_id: params.creative_id,
        creative_oid: params.creative_oid,
    };
};
