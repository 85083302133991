import React from 'react';
import { Modal } from 'antd';
import { setUserActionsToStorage, setAppVersionToStorage } from 'util/localStorage';

export const showVersionUpdateModal = (newVersion) => {
    Modal.warning({
        title: 'Version update',
        content: (
            <div>
                <p>We've recently updated the app to a newer version.</p>
                <p>Please note that any unsaved work or data in progress will be lost.</p>
            </div>
        ),
        onOk: () => {
            // remove user actions
            setUserActionsToStorage([]);
            // set new version
            setAppVersionToStorage(newVersion);
            // refresh
            window.location.reload();
        },
    });
};
