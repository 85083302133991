export const BID_BUDGET_ACTIONS = {
    SET: 'Set',
    INCREASE: 'Increase',
    DECREASE: 'Decrease',
};

export const BID_BUDGET_UNITS = {
    DOLLAR: '$',
    PERCENTAGE: '%',
};

export const STATUS = {
    PAUSED: 'PAUSED',
    ACTIVE: 'ACTIVE',
};
