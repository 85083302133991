import React, { useState } from 'react';
import { Layout, Menu, Image } from 'antd';
import Logo from './components/logo';

import { useLocation, useNavigate } from 'react-router-dom';

import {
    DashboardOutlined,
    MonitorOutlined,
    MobileOutlined,
    GooglePlusOutlined,
    DatabaseOutlined,
} from '@ant-design/icons';

const MENU_ITEMS = [
    {
        label: 'Dashboard',
        icon: <DashboardOutlined style={{ fontSize: 18 }} />,
        key: 'dashboard',
    },
    {
        label: 'Explorads',
        icon: <MonitorOutlined style={{ fontSize: 18 }} />,
        key: 'explorads',
    },
    {
        label: 'AdSense',
        icon: <GooglePlusOutlined style={{ fontSize: 18 }} />,
        key: 'adsense',
    },
    {
        label: 'TikTok',
        icon: <MobileOutlined style={{ fontSize: 18 }} />,
        key: 'tiktok',
    },
    {
        label: 'Taboola',
        icon: <DatabaseOutlined style={{ fontSize: 18 }} />,
        // icon: <Image src="https://www.taboola.com//wp-content/uploads/2020/07/icon-white.png" style={{ width: 18, height: 18 }} />,
        key: 'taboola',
    },
];

const SideMenu = () => {
    const [collapsed, setCollapsed] = useState(true);
    const location = useLocation();
    const navigate = useNavigate();

    const [menuItems, setMenuItems] = useState([
        {
            key: 'kodigital',
            title: 'ko',
            icon: <Logo />,
            disabled: true,
        },
        ...MENU_ITEMS,
    ]);

    const onHover = () => {
        if (collapsed) {
            setCollapsed(false);
            setMenuItems([
                {
                    key: 'kodigital',
                    title: 'ko',
                    icon: <Logo fullLogo={true} />,
                    disabled: true,
                },
                ...MENU_ITEMS,
            ]);
            return;
        }

        setCollapsed(true);
        setMenuItems([
            {
                key: 'kodigital',
                title: 'ko',
                icon: <Logo />,
                disabled: true,
            },
            ...MENU_ITEMS,
        ]);
    };

    const onItemClick = ({ key }) => {
        // if (key === 'dashboard') navigate('/dashboard')
        if (key === 'dashboard') navigate('/dashboard');
        if (key === 'explorads') navigate('/explorads');
        if (key === 'tiktok') navigate('/tiktok');
        if (key === 'adsense') navigate('/adsense');
        if (key === 'taboola') navigate('/taboola');
    };

    return (
        <Layout.Sider
            style={{
                backgroundColor: '#14104a',
                overflow: 'auto',
                height: '100vh',
                position: 'fixed',
                left: 0,
                top: 0,
                bottom: 0,
                zIndex: 99999999999,
            }}
            collapsible={true}
            defaultCollapsed={true}
            collapsed={collapsed}
            trigger={null}
            onMouseEnter={onHover}
            onMouseLeave={onHover}
        >
            <Menu
                items={menuItems}
                defaultSelectedKeys={[location.pathname.replace('/', '')]}
                theme={'dark'}
                mode={'inline'}
                style={{
                    backgroundColor: '#14104a',
                    color: 'white',
                    fontSize: 18,
                }}
                onClick={onItemClick}
            />
        </Layout.Sider>
    );
};

export default SideMenu;
