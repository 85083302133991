import React from 'react';
import { Select } from 'antd';

import './index.css';

const { Option } = Select;

// const SearchInput = (props) => {
//     return (
//         <Input
//             value={props.value}
//             onChange={(e) => props.onChange(e.target.value)}
//             suffix={<SearchOutlined />}
//             placeholder={props.placeholder}
//             className="search-input"
//             allowClear
//         />
//     );
// };

// const SelectAll = (props) => {
//     return (
//         <>
//             <Tooltip title="Select All">
//                 <div onClick={props.selectAll} className="bulk-checkbox-action" role="button">
//                     <PlusSquareOutlined />
//                 </div>
//             </Tooltip>
//             <Tooltip title="Select None">
//                 <div onClick={props.selectNone} className="bulk-checkbox-action" role="button">
//                     <MinusSquareOutlined />
//                 </div>
//             </Tooltip>
//         </>
//     );
// };

// const ItemsList = (props) => {
//     const { options = [] } = props;
//     const optionsList = options.map((opt) => ({
//         value: opt.value,
//         label: <Tooltip title={opt.fullTitle}>{opt.label}</Tooltip>,
//         disabled: !!_.get(props.disabled, opt.value),
//     }));
//     return (
//         <div className="checkbox-scroller">
//             <div className="checkbox-block">
//                 <Checkbox.Group
//                     className="checkbox-group"
//                     options={optionsList}
//                     value={props.value || []}
//                     onChange={props.onChange}
//                 />
//             </div>
//         </div>
//     );
// };

const SelectDimensions = (props) => {
    const dimensionsOptions = props.dimensions.map((option) => (
        <Option disabled={props.disabled[option.key]} key={option.key} value={option.key} title={option.title}>
            {option.title}
        </Option>
    ));

    return (
        <Select
            mode="multiple"
            allowClear
            style={{ width: '100%' }}
            placeholder="Please select"
            // defaultValue={props.selected}
            value={props.selected}
            optionFilterProp="title"
            onChange={props.onChange}
            disabled={props.isLocked}
        >
            {dimensionsOptions}
        </Select>
    );
};

// const SelectDimensions = (props) => {
//     const { options = [], value = [], disabledOptions = {}, loading } = props;

//     const [searchValue, setSearchValue] = useState('');

//     const regex = new RegExp(_.escapeRegExp(searchValue), 'i');
//     const filteredOptions = options.filter((opt) => regex.test(opt.label) || regex.test(opt.fullTitle));

//     const selectAll = () => {
//         props.onChange(options.filter((opt) => !disabledOptions[opt.value]).map((opt) => opt.value));
//     };

//     const selectNone = () => {
//         props.onChange([]);
//     };

//     const onChangeValue = (val) => {
//         const filteredOutOptions = _.difference(
//             options.map((opt) => opt.value),
//             filteredOptions.map((opt) => opt.value)
//         );
//         const filteredOutValues = _.intersection(value, filteredOutOptions);
//         props.onChange([...filteredOutValues, ...val]);
//     };

//     return (
//         <div className="select-dimensions">
//             {/* <label className="select-dimensions-label">{label}</label> */}
//             <div className="select-dimensions-top">
//                 <SearchInput value={searchValue} onChange={setSearchValue} placeholder={props.label} />
//                 <SelectAll selectAll={selectAll} selectNone={selectNone} />
//             </div>
//             <Spin spinning={!!loading} size="small">
//                 <div className="select-dimensions-items">
//                     <ItemsList
//                         value={value}
//                         options={filteredOptions}
//                         disabled={disabledOptions}
//                         onChange={onChangeValue}
//                     />
//                 </div>
//             </Spin>
//         </div>
//     );
// };

export default SelectDimensions;
