const COLUMNS_ORDER_KEY = 'ko_dashboard_col_order';
const COLUMNS_WIDTH_KEY = 'ko_dashboard_col_widths';
const USER_ACTIONS_KEY = 'ko_dashboard_actions';
const APP_VERSION_KEY = 'ko_dashboard_version';

export const setColumnsOrderToStorage = (order = []) => {
    window.localStorage.setItem(COLUMNS_ORDER_KEY, JSON.stringify(order || []));
};

export const getColumnsOrderFromStorage = () => {
    let order = [];
    try {
        order = JSON.parse(window.localStorage.getItem(COLUMNS_ORDER_KEY)) || [];
    } catch (e) {}
    return order;
};

export const setColumnsWidthToStorage = (columnsWidth = {}) => {
    window.localStorage.setItem(COLUMNS_WIDTH_KEY, JSON.stringify(columnsWidth || {}));
};

export const getColumnsWidthFromStorage = () => {
    let columnsWidth = {};
    try {
        columnsWidth = JSON.parse(window.localStorage.getItem(COLUMNS_WIDTH_KEY)) || {};
    } catch (e) {}
    return columnsWidth;
};

export const setUserActionsToStorage = (actions = []) => {
    window.localStorage.setItem(USER_ACTIONS_KEY, JSON.stringify(actions || []));
};

export const getUserActionsFromStorage = () => {
    let actions = [];
    try {
        actions = JSON.parse(window.localStorage.getItem(USER_ACTIONS_KEY)) || [];
    } catch (e) {}
    return actions;
};

export const setAppVersionToStorage = (version = {}) => {
    window.localStorage.setItem(APP_VERSION_KEY, version || '');
};

export const getAppVersionFromStorage = () => {
    return window.localStorage.getItem(APP_VERSION_KEY) || '';
};

export const clearStorage = () => {
    try {
        window.localStorage.clear();
    } catch (e) {}
};
