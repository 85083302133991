import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import DashboardPage from 'pages/Dashboard';
import ExploradsDashboardPage from 'pages/Explorads';
import TikTokDashboardPage from 'pages/Tiktok';
import AdsenseDashboardPage from 'pages/Adsense';
import TaboolaDashboardPage from 'pages/Taboola';

const ContentRoutes = () => {
    return (
        <Routes>
            <Route exact path="/" element={<Navigate replace to="/adsense" />} />
            <Route exact path="/dashboard" element={<DashboardPage />} />
            <Route exact path="/explorads" element={<ExploradsDashboardPage />} />
            <Route exact path="/adsense" element={<AdsenseDashboardPage />} />
            <Route exact path="/tiktok" element={<TikTokDashboardPage />} />
            <Route exact path="/taboola" element={<TaboolaDashboardPage />} />
        </Routes>
    );
};

export default ContentRoutes;
