import React, { useState, useContext } from 'react';
import _ from 'lodash';
import {
    Modal,
    Table,
    Input,
    Typography,
    Switch,
    message,
    Statistic,
    Space,
    Card,
    Tooltip,
    InputNumber,
    notification,
} from 'antd';
import { ArrowUpOutlined, ArrowDownOutlined, SearchOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { changeCampaignModalVisibility, changeAdsLimits, changedAccountLimits } from 'store/campaignData/actionCreator';
import { toggleAction } from 'store/userActions/actionCreators';
import getMetricCellValues from 'util/getMetricCellValues';
import { getActionFromObject } from 'util/currentPendingActions';
import { stringSorter, numberSorter } from 'util/tables';
import { hasUserPermissions } from 'util/permissions';
import DashboardContext from 'context/dashboardContext';
import * as FIELD from 'data/fields';

const { Text, Link } = Typography;
const maxBudget = 10_000;

const NotEditableStatuses = [
    'DELETED',
    'PENDING_REVIEW',
    'DISAPPROVED',
    'CAMPAIGN_PAUSED',
    'ARCHIVED',
    'ADSET_PAUSED',
    'WITH_ISSUES',
];

const StatusSwitch = (props) => {
    const { row } = props;
    const dashboardContext = useContext(DashboardContext);
    const dispatch = useDispatch();

    const isAdSense = dashboardContext?.name === 'AdSense';

    const { user } = useSelector((store) => store.auth);
    const changedLimits = useSelector((store) => store.campaignData.adsLimits);
    const accountLimits = useSelector((store) => store.campaignData.accountLimits);
    const limitsLoading = useSelector((store) => store.campaignData.limitsLoading);

    const cellCampaignCurrentAction = useSelector((store) =>
        getActionFromObject(store.userActions.currentActions, 'campaignEffectiveStatus', {
            campaignId: row.campaignId,
        })
    );
    const cellCampaignPendingAction = useSelector((store) =>
        getActionFromObject(store.userActions.pendingActions, 'campaignEffectiveStatus', {
            campaignId: row.campaignId,
        })
    );

    const cellCurrentAction = useSelector((store) =>
        getActionFromObject(store.userActions.currentActions, 'adsetEffectiveStatus', {
            adsetId: row.adsetId,
        })
    );
    const cellPendingAction = useSelector((store) =>
        getActionFromObject(store.userActions.pendingActions, 'adsetEffectiveStatus', {
            adsetId: row.adsetId,
        })
    );

    const rowBusiness = row[FIELD.BUSINESS_KEY];
    const canUserEdit = hasUserPermissions('editor', user, rowBusiness ? [rowBusiness] : []);

    const getStatus = (
        campaignCurrentActions,
        campaignPendingActions,
        adsetCurrentActions,
        adsetPendingActions,
        value
    ) => {
        // If we have pending actions on campaign, and we want to change campaign status to PAUSED
        // => we say that status of row is PAUSED
        if (campaignPendingActions && campaignPendingActions.newValue !== dashboardContext.status.ACTIVE)
            return campaignPendingActions.newValue;

        // If we have new actions on campaign, and we want to change campaign status to PAUSED
        // => we say that status of row is PAUSED
        if (campaignCurrentActions && campaignCurrentActions.newValue !== dashboardContext.status.ACTIVE)
            return campaignCurrentActions.newValue;

        // If we have pending actions on adset, and we want to change adsets status
        // => we say that new status of adset is status defined in action
        if (adsetPendingActions) return adsetPendingActions.newValue;

        // If we have new actions on adset, and we want to change adsets status
        // => we say that new status of adset is status defined in action
        if (adsetCurrentActions) return adsetCurrentActions.newValue;

        // In case if we don't have any new/pending changes on row => get status from row
        return value;
    };

    const currentStatus = getStatus(
        cellCampaignCurrentAction,
        cellCampaignPendingAction,
        cellCurrentAction,
        cellPendingAction,
        row.status
    );

    const onStatusChange = (row, checked) => {
        const newStatus = checked ? dashboardContext.status.ACTIVE : dashboardContext.status.PAUSED;
        const action = {
            action: 'status',
            item: 'adset',
            business: row.business,
            ad_set_id: row.adsetId,
            ad_set_name: row.adsetName,
            campaign_id: row.campaignId,
            account_id: row.accountId,
            oldAdsetStatus: row.status,
            newAdsetStatus: newStatus,
        };

        const affectedPages = row?.affectedPages || [];
        for (const page of affectedPages) {
            const value = checked ? page.affectValue : page.affectValue * -1;
            dispatch(changeAdsLimits(page.pageId, value));
        }

        const accountChangeValue = checked ? row.affectedAccount.affectValue : row.affectedAccount.affectValue * -1;
        dispatch(changedAccountLimits(row.affectedAccount.id, accountChangeValue));

        dispatch(toggleAction(action, isAdSense)).then((res) => {
            if (res) {
                message.success('Added', 1.5);
            } else {
                message.warn('Cancelled', 1.5);
            }
        });
    };

    const isDisabled = (pagesLimits, accountLimits, data, status, isPending = false) => {
        /* Block changes on pending adsets */
        if (isPending) return true;

        const isActive = status === dashboardContext.status.ACTIVE;
        if (isActive) return false;

        for (const page of data?.affectedPages || []) {
            const changes = pagesLimits[page.pageId] || 0;
            if (page.currentCount + page?.affectValue + changes > page.limit) return true;
        }

        const accountChanges = accountLimits[data.accountId] || 0;
        if (
            data?.affectedAccount &&
            data.affectedAccount.currentCount + data.affectedAccount.affectValue + accountChanges >
                data.affectedAccount.limit
        )
            return true;

        return false;
    };

    const disabled = isDisabled(changedLimits, accountLimits, row, currentStatus, !!cellPendingAction?.length);

    return (
        <Switch
            size="small"
            onChange={!canUserEdit ? null : (checked) => onStatusChange(row, checked)}
            checked={currentStatus === dashboardContext.status.ACTIVE}
            disabled={limitsLoading || disabled}
        />
    );
};

const AdStatusSwitch = (props) => {
    const { row } = props;

    const dashboardContext = useContext(DashboardContext);
    const dispatch = useDispatch();

    const { user } = useSelector((store) => store.auth);
    const changedLimits = useSelector((store) => store.campaignData.adsLimits);
    const accountLimits = useSelector((store) => store.campaignData.accountLimits);
    const limitsLoading = useSelector((store) => store.campaignData.limitsLoading);

    const cellCampaignCurrentAction = useSelector((store) =>
        getActionFromObject(store.userActions.currentActions, 'campaignEffectiveStatus', {
            campaignId: row.campaignId,
        })
    );

    const cellCampaignPendingAction = useSelector((store) =>
        getActionFromObject(store.userActions.pendingActions, 'campaignEffectiveStatus', {
            campaignId: row.campaignId,
        })
    );

    const cellAdsetCurrentAction = useSelector((store) =>
        getActionFromObject(store.userActions.currentActions, 'adsetEffectiveStatus', {
            adsetId: row.adsetId,
        })
    );

    const cellAdsetPendingAction = useSelector((store) =>
        getActionFromObject(store.userActions.pendingActions, 'adsetEffectiveStatus', {
            adsetId: row.adsetId,
        })
    );

    const cellCurrentAction = useSelector((store) =>
        getActionFromObject(store.userActions.currentActions, 'adStatus', {
            adId: row.adId,
        })
    );

    const cellPendingAction = useSelector((store) =>
        getActionFromObject(store.userActions.pendingActions, 'adStatus', {
            adId: row.adId,
        })
    );

    // Render error icon in case if we can't change status of an ad
    if (NotEditableStatuses.includes(row.adStatus)) {
        return (
            <Tooltip title={`Ad status can't be edited: Current status ${row.adStatus}!`}>
                <ExclamationCircleFilled style={{ color: '#FF0000' }} />
            </Tooltip>
        );
    }

    const rowBusiness = row[FIELD.BUSINESS_KEY];
    const canUserEdit = hasUserPermissions('editor', user, rowBusiness ? [rowBusiness] : []);

    const getStatus = (
        campaignCurrentActions,
        campaignPendingActions,
        cellAdsetCurrentAction,
        cellAdsetPendingAction,
        cellCurrentAction,
        cellPendingAction,
        value
    ) => {
        // If we have pending actions on campaign, and we want to change campaign status to PAUSED
        // => we say that status of row is PAUSED
        if (campaignPendingActions && campaignPendingActions.newValue !== dashboardContext.status.ACTIVE)
            return campaignPendingActions.newValue;

        // If we have new actions on campaign, and we want to change campaign status to PAUSED
        // => we say that status of row is PAUSED
        if (campaignCurrentActions && campaignCurrentActions.newValue !== dashboardContext.status.ACTIVE)
            return campaignCurrentActions.newValue;

        // If we have new actions on adset, and we want to change adsets status to PAUSED
        // => we say that new status of adset is status defined in action (PAUSED)
        if (cellAdsetPendingAction && cellAdsetPendingAction.newValue !== dashboardContext.status.ACTIVE)
            return cellAdsetPendingAction.newValue;

        // If we have pending actions on adset, and we want to change adsets status to PAUSED
        // => we say that new status of adset is status defined in action (PAUSED)
        if (cellAdsetCurrentAction && cellAdsetCurrentAction.newValue !== dashboardContext.status.ACTIVE)
            return cellAdsetCurrentAction.newValue;

        // If we have pending action on ad => return status from action
        if (cellPendingAction) return cellPendingAction.newValue;

        // If we have new action on ad => return status from action
        if (cellCurrentAction) return cellCurrentAction.newValue;

        // In case if we don't have any new/pending changes on ad => get status from ad
        return value;
    };

    const currentStatus = getStatus(
        cellCampaignCurrentAction,
        cellCampaignPendingAction,
        cellAdsetCurrentAction,
        cellAdsetPendingAction,
        cellCurrentAction,
        cellPendingAction,
        row.adStatus
    );

    const onStatusChange = (row, checked) => {
        const newStatus = checked ? dashboardContext.status.ACTIVE : dashboardContext.status.PAUSED;
        const action = {
            action: 'status',
            item: 'ad',
            business: row.business,
            ad_id: row.adId,
            ad_name: row.adName,
            ad_set_id: row.adsetId,
            ad_set_name: row.adsetName,
            campaign_id: row.campaignId,
            account_id: row.accountId,
            oldAdStatus: row.adStatus,
            newAdStatus: newStatus,
        };

        const affectedPages = row?.affectedPages || [];
        for (const page of affectedPages) {
            const value = checked ? page.affectValue : page.affectValue * -1;
            dispatch(changeAdsLimits(page.pageId, value));
        }

        const accountChangeValue = checked ? row.affectedAccount.affectValue : row.affectedAccount.affectValue * -1;
        dispatch(changedAccountLimits(row.affectedAccount.id, accountChangeValue));

        dispatch(toggleAction(action)).then((res) => {
            if (res) {
                message.success('Added', 1.5);
            } else {
                message.warn('Cancelled', 1.5);
            }
        });
    };

    const isDisabled = (pagesLimits, accountLimits, data, status, isPending = false) => {
        /* Block changes on pending adsets */
        if (isPending) return true;

        const isActive = status === dashboardContext.status.ACTIVE;
        if (isActive) return false;

        for (const page of data?.affectedPages || []) {
            const changes = pagesLimits[page.pageId] || 0;
            if (page.currentCount + page?.affectValue + changes > page.limit) return true;
        }

        const accountChanges = accountLimits[data.accountId] || 0;
        if (
            data?.affectedAccount &&
            data.affectedAccount.currentCount + data.affectedAccount.affectValue + accountChanges >
                data.affectedAccount.limit
        )
            return true;

        return false;
    };

    const disabled = isDisabled(changedLimits, accountLimits, row, currentStatus, !!cellPendingAction?.length);

    return (
        <Switch
            size="small"
            onChange={!canUserEdit ? null : (checked) => onStatusChange(row, checked)}
            checked={currentStatus === dashboardContext.status.ACTIVE}
            disabled={limitsLoading || disabled}
        />
    );
};

const EditableCell = (props) => {
    const { value = 0, row, action: actionType } = props;
    const dispatch = useDispatch();
    const [isEditing, setEditing] = useState(false);
    const metric = actionType === 'bid' ? 'bidAmount' : 'dailyBudget';

    const { user } = useSelector((store) => store.auth);
    const cellCurrentAction = useSelector((store) =>
        getActionFromObject(store.userActions.currentActions, metric, row)
    );
    const cellPendingAction = useSelector((store) =>
        getActionFromObject(store.userActions.pendingActions, metric, row)
    );

    const rowBusiness = row[FIELD.BUSINESS_KEY];
    const canUserEdit = hasUserPermissions('editor', user, rowBusiness ? [rowBusiness] : []);

    const curValue = cellPendingAction
        ? cellPendingAction.newValue
        : cellCurrentAction
        ? cellCurrentAction.newValue
        : value / 100;

    // const [currentValue, setCurrentValue] = useState();

    const onChange = (event) => {
        event.target.blur();

        const inputValue = parseFloat(event?.target?.value || 0);
        if (_.isNaN(inputValue) || inputValue > maxBudget || inputValue < 0) {
            event.target.value = curValue;
            if (actionType === 'budget') {
                notification.error({
                    message: 'Failed to change budget',
                    description: `Budget has to be an integer value between 0 and ${maxBudget}`,
                });
            }
            return;
        }

        // setCurrentValue(inputValue);
        const action = {
            action: actionType,
            ad_set_id: row.adsetId,
            ad_set_name: row.adsetName,
            account_id: row.accountId,
            campaign_id: row.campaignId,
            item: 'adset',
            business: row.business,
            budget: row.dailyBudget / 100,
            bid: row.bidAmount / 100,
        };

        if (actionType === 'budget') action.new_budget = inputValue;
        if (actionType === 'bid') action.new_bid = inputValue;

        dispatch(toggleAction(action)).then((res) => {
            if (res) {
                message.success('Added', 1.5);
            } else {
                message.warn('Cancelled', 1.5);
            }
        });
    };

    if (isEditing) {
        if (actionType === 'bid') {
            return (
                <InputNumber
                    onPressEnter={onChange}
                    autoFocus
                    min={0}
                    step={0.01}
                    precision={2}
                    onKeyDown={(e) => e.preventDefault()}
                    onBlur={() => {
                        setEditing(false);
                    }}
                    style={{ width: '100%' }}
                    prefix="$"
                    defaultValue={curValue}
                />
            );
        }
        return (
            <InputNumber
                onPressEnter={onChange}
                autoFocus
                min={0}
                max={maxBudget}
                step={1}
                precision={0}
                onBlur={() => {
                    setEditing(false);
                }}
                style={{ width: '100%' }}
                prefix="$"
                defaultValue={curValue}
            />
        );
    }

    const style = cellCurrentAction || cellPendingAction ? { boxShadow: 'inset 0 0 2px 2px #576dff' } : {};

    return (
        <div style={{ padding: 5, ...style }}>
            <span onDoubleClick={!canUserEdit ? null : () => setEditing(true)}>
                {getMetricCellValues(curValue, { prefix: '$', precision: 2, isNumber: true }).value}
            </span>
        </div>
    );
};

const CampaignContentsTable = (props) => {
    const { loading, data, type } = props;

    const adsetsColumns = [
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            fixed: 'left',
            width: 80,
            render: (value, row) => {
                return <StatusSwitch row={row} />;
            },
        },
        {
            title: 'Name',
            dataIndex: 'adsetName',
            key: 'adsetName',
            width: 600,
            fixed: 'left',
            sorter: (a, b) => stringSorter('adsetName')(a, b),
        },
        {
            title: 'Strategy',
            dataIndex: 'bidStrategy',
            key: 'bidStrategy',
            width: 280,
            sorter: (a, b) => stringSorter('bidStrategy')(a, b),
        },
        {
            title: 'IOS%',
            dataIndex: 'iosP',
            key: 'iosP',
            width: 100,
            sorter: (a, b) => numberSorter('iosP')(a, b),
            render: (value) => {
                return (
                    <span>
                        {getMetricCellValues(value, { prefix: '', postfix: '%', precision: 1, isNumber: true }).value}
                    </span>
                );
            },
        },
        {
            title: 'Non US%',
            dataIndex: 'nonUSPercentage',
            key: 'nonUSPercentage',
            width: 130,
            sorter: (a, b) => numberSorter('nonUSPercentage')(a, b),
            render: (value) => {
                return <span>{getMetricCellValues(value, { precision: 1, isNumber: true, postfix: '%' }).value}</span>;
            },
        },
        {
            title: 'Mobile%',
            dataIndex: 'mobileP',
            key: 'mobileP',
            width: 100,
            sorter: (a, b) => numberSorter('mobileP')(a, b),
            render: (value) => {
                return (
                    <span>
                        {getMetricCellValues(value, { prefix: '', postfix: '%', precision: 1, isNumber: true }).value}
                    </span>
                );
            },
        },
        {
            title: 'Revenue',
            dataIndex: 'revenue',
            key: 'revenue',
            width: 130,
            sorter: (a, b) => numberSorter('revenue')(a, b),
            render: (value) => {
                return <span>{getMetricCellValues(value, { precision: 2, prefix: '$', isNumber: true }).value}</span>;
            },
        },
        {
            title: 'Spend',
            dataIndex: 'spend',
            key: 'spend',
            width: 100,
            sorter: (a, b) => numberSorter('spend')(a, b),
            render: (value) => {
                return <span>{getMetricCellValues(value, { precision: 2, prefix: '$', isNumber: true }).value}</span>;
            },
        },
        {
            title: 'Profit',
            dataIndex: 'profit',
            key: 'profit',
            width: 130,
            sorter: (a, b) => numberSorter('profit')(a, b),
            render: (value) => {
                return <span>{getMetricCellValues(value, { precision: 2, prefix: '$', isNumber: true }).value}</span>;
            },
        },
        {
            title: 'ROI',
            dataIndex: 'roi',
            key: 'roi',
            width: 100,
            sorter: (a, b) => numberSorter('roi')(a, b),
            render: (value) => {
                return (
                    <span>
                        {getMetricCellValues(value, { prefix: '', postfix: '%', precision: 1, isNumber: true }).value}
                    </span>
                );
            },
        },
        {
            title: 'ROI-Y',
            dataIndex: 'roiYesterday',
            key: 'roiYesterday',
            width: 100,
            sorter: (a, b) => numberSorter('roiYesterday')(a, b),
            render: (value) => {
                return (
                    <span>
                        {getMetricCellValues(value, { prefix: '', postfix: '%', precision: 1, isNumber: true }).value}
                    </span>
                );
            },
        },
        {
            title: 'CTR%',
            dataIndex: 'ctr',
            key: 'ctr',
            width: 100,
            sorter: (a, b) => numberSorter('ctr')(a, b),
            render: (value) => {
                return (
                    <span>
                        {getMetricCellValues(value, { prefix: '', postfix: '%', precision: 1, isNumber: true }).value}
                    </span>
                );
            },
        },
        {
            title: 'CPC',
            dataIndex: 'cpc',
            key: 'cpc',
            width: 100,
            sorter: (a, b) => numberSorter('cpc')(a, b),
            render: (value) => {
                return <span>{getMetricCellValues(value, { precision: 2, prefix: '$', isNumber: true }).value}</span>;
            },
        },
        {
            title: 'PPS',
            dataIndex: 'pps',
            key: 'pps',
            width: 100,
            sorter: (a, b) => numberSorter('pps')(a, b),
            render: (value) => {
                return <span>{getMetricCellValues(value, { precision: 2, isNumber: true }).value}</span>;
            },
        },
        //IPP
        {
            title: 'IPP',
            dataIndex: 'ipp',
            key: 'ipp',
            width: 100,
            sorter: (a, b) => numberSorter('ipp')(a, b),
            render: (value) => {
                return <span>{getMetricCellValues(value, { precision: 2, isNumber: true }).value}</span>;
            },
        },
        {
            title: 'RPS',
            dataIndex: 'rps',
            key: 'rps',
            width: 100,
            sorter: (a, b) => numberSorter('rps')(a, b),
            render: (value) => {
                return <span>{getMetricCellValues(value, { precision: 2, prefix: '$', isNumber: true }).value}</span>;
            },
        },
        {
            title: 'RPM',
            dataIndex: 'rpm',
            key: 'rpm',
            width: 100,
            sorter: (a, b) => numberSorter('rpm')(a, b),
            render: (value) => {
                return <span>{getMetricCellValues(value, { precision: 2, prefix: '$', isNumber: true }).value}</span>;
            },
        },
        {
            title: 'CPR',
            dataIndex: 'cpr',
            key: 'cpr',
            width: 100,
            sorter: (a, b) => numberSorter('cpr')(a, b),
            render: (value) => {
                return <span>{getMetricCellValues(value, { precision: 2, prefix: '$', isNumber: true }).value}</span>;
            },
        },
        //CVR
        {
            title: 'CVR',
            dataIndex: 'cvrContent',
            key: 'cvrContent',
            width: 100,
            sorter: (a, b) => numberSorter('cvr')(a, b),
            render: (value) => {
                return <span>{getMetricCellValues(value, { postfix: '%', precision: 2, isNumber: true }).value}</span>;
            },
        },
        //FB CPM
        {
            title: 'FB CPM',
            dataIndex: 'fbCpm',
            key: 'fbCpm',
            width: 100,
            sorter: (a, b) => numberSorter('fbCpm')(a, b),
            render: (value) => {
                return <span>{getMetricCellValues(value, { prefix: '$', precision: 2, isNumber: true }).value}</span>;
            },
        },
        {
            title: 'Revenue Yesterday',
            dataIndex: 'revenueYesterday',
            key: 'revenueYesterday',
            width: 130,
            sorter: (a, b) => numberSorter('revenueYesterday')(a, b),
            render: (value) => {
                return <span>{getMetricCellValues(value, { precision: 2, prefix: '$', isNumber: true }).value}</span>;
            },
        },
        {
            title: 'Profit Yesterday',
            dataIndex: 'profitYesterday',
            key: 'profitYesterday',
            width: 130,
            sorter: (a, b) => numberSorter('profitYesterday')(a, b),
            render: (value) => {
                return <span>{getMetricCellValues(value, { precision: 2, prefix: '$', isNumber: true }).value}</span>;
            },
        },
        {
            title: 'Cost Yesterday',
            dataIndex: 'spendYesterday',
            key: 'spendYesterday',
            width: 100,
            sorter: (a, b) => numberSorter('spendYesterday')(a, b),
            render: (value) => {
                return <span>{getMetricCellValues(value, { precision: 2, prefix: '$', isNumber: true }).value}</span>;
            },
        },
        {
            title: 'Budget',
            dataIndex: 'dailyBudget',
            key: 'dailyBudget',
            width: 140,
            sorter: (a, b) => numberSorter('dailyBudget')(a, b),
            editable: true,
            render: (value, row) => {
                return <EditableCell row={row} action={'budget'} value={value} />;
            },
        },
        {
            title: 'Budget Usage',
            dataIndex: 'budgetUsage',
            key: 'budgetUsage',
            width: 140,
            sorter: (a, b) => numberSorter('budgetUsage')(a, b),
            render: (value) => {
                return (
                    <span>
                        {getMetricCellValues(value, { prefix: '', postfix: '%', precision: 1, isNumber: true }).value}
                    </span>
                );
            },
        },
        {
            title: 'Bid',
            dataIndex: 'bidAmount',
            key: 'bidAmount',
            width: 140,
            render: (value, row) => {
                return (
                    <EditableCell
                        row={row}
                        action={'bid'}
                        value={value}
                        displayValue={
                            getMetricCellValues(value / 100, { prefix: '$', precision: 2, isNumber: true }).value
                        }
                    />
                );
            },
            sorter: (a, b) => numberSorter('bidAmount')(a, b),
        },
        {
            title: 'Clicks',
            dataIndex: 'clicks',
            key: 'clicks',
            width: 100,
            sorter: (a, b) => numberSorter('clicks')(a, b),
            render: (value) => {
                return <span>{getMetricCellValues(value, { precision: 0, isNumber: true }).value}</span>;
            },
        },
        {
            title: 'Sessions',
            dataIndex: 'sessions',
            key: 'sessions',
            width: 100,
            sorter: (a, b) => numberSorter('sessions')(a, b),
            render: (value) => {
                return <span>{getMetricCellValues(value, { precision: 0, isNumber: true }).value}</span>;
            },
        },
        {
            title: 'MissingClicks',
            dataIndex: 'missingClicks',
            key: 'missingClicks',
            width: 100,
            sorter: (a, b) => numberSorter('missingClicks')(a, b),
            render: (value) => {
                return (
                    <span>
                        {getMetricCellValues(value, { prefix: '', postfix: '%', precision: 1, isNumber: true }).value}
                    </span>
                );
            },
        },

        // {
        //     title: 'Users',
        //     dataIndex: 'users',
        //     key: 'users',
        //     width: 100,
        //     render: (value) => {
        //         return <span>{getMetricCellValues(value || 0, { precision: 0, isNumber: true }).value}</span>;
        //     },
        //     sorter: (a, b) => numberSorter('users')(a, b),
        // },
        // {
        //     title: 'Ads Type',
        //     dataIndex: 'dynamicCreative',
        //     key: 'dynamicCreative',
        //     width: 120,
        //     render: (value) => <span>{value ? 'Dynamic' : 'Regular'}</span>,
        // },
    ];

    const adsColumns = [
        {
            title: 'AdStatus',
            dataIndex: 'adStatus',
            key: 'adStatus',
            fixed: 'left',
            width: 100,
            render: (value, row) => {
                return <AdStatusSwitch row={row} />;
            },
        },
        {
            title: 'AdName',
            dataIndex: 'adName',
            key: 'adName',
            fixed: 'left',
            width: 400,
        },
        ...adsetsColumns.filter((column) => !['status', 'adsetName'].includes(column.dataIndex)),
    ];

    return (
        <Table
            size={'large'}
            rowKey={'id'}
            dataSource={data || []}
            columns={type === 'adset' ? adsetsColumns : adsColumns}
            loading={loading}
            pagination={false}
            scroll={{ x: '100vw', y: '45vh' }}
        />
    );
};

const CustomModalName = (props) => {
    const { type, name, accountId, campaignId } = props;
    const formattedType = type === 'adset' ? 'Adsets' : 'Ads';

    const url = `https://business.facebook.com/adsmanager/manage/campaigns/edit?act=${accountId}&nav_entry_point=am_local_scope_selector&selected_campaign_ids=${campaignId}`;

    return (
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '98%' }}>
            <Space split={'/'}>
                <Link style={{ cursor: 'pointer' }} href={url} disabled={!accountId || !campaignId} target="_blank">
                    {name}
                </Link>
                <Text type="secondary" strong>
                    {formattedType}
                </Text>
            </Space>
        </div>
    );
};

const PageAdsLimitsDetails = (props) => {
    const { active, scheduled, changed } = props;

    return (
        <Space direction="vertical">
            <Text style={{ color: '#fff' }}>Active Ads: {active}</Text>
            <Text style={{ color: '#fff' }}>Scheduled Ads: {scheduled}</Text>
            <Text style={{ color: '#fff' }}>Changed Ads: {changed}</Text>
        </Space>
    );
};

const AdsVolumeLimitsBoard = (props) => {
    const { pages } = props;

    const {
        adsLimits: changedPageLimits,
        accountLimits: changedAccountLimits,
        preloading: loading,
        loading: limitsLoading,
    } = useSelector((store) => store.campaignData);

    const content = pages.map((page) => {
        const isAccount = !!page?.id;
        const changes = isAccount ? changedAccountLimits[page.id] || 0 : changedPageLimits[page.pageId] || 0;
        const prefix = changes ? (
            changes > 0 ? (
                <ArrowUpOutlined style={{ color: '#3f8600' }} />
            ) : (
                <ArrowDownOutlined style={{ color: '#cf1322' }} />
            )
        ) : (
            ''
        );

        return (
            <Card bordered={false}>
                <Tooltip
                    title={
                        <PageAdsLimitsDetails
                            active={page.activeAds}
                            scheduled={page.pendingAdsCount}
                            changed={changes}
                        />
                    }
                >
                    <Statistic
                        loading={loading}
                        title={`${isAccount ? 'Account' : 'Page'}: ${page.name}`}
                        value={parseInt(page.currentCount, 10) + parseInt(changes, 10)}
                        prefix={prefix}
                        suffix={`/ ${page.limit}`}
                    />
                </Tooltip>
            </Card>
        );
    });
    return <Space wrap>{content}</Space>;
};

const CampaignDetailsModal = () => {
    const dispatch = useDispatch();
    const { modalName, modalType, modalVisible, adsets, ads, loading } = useSelector((state) => state.campaignData);

    const data = modalType === 'adset' ? adsets : ads;

    const allAffectedPages = _.uniqBy(_.flattenDeep(data.map((row) => row?.affectedPages || [])), 'pageId');
    const affectedAccount = _.uniqBy(_.flattenDeep(data.map((row) => row?.affectedAccount || [])), 'accountId');

    const accountId = data?.[0]?.accountId;
    const campaignId = data?.[0]?.campaignId;

    const onClose = () => {
        dispatch(changeCampaignModalVisibility());
    };

    // Добавляем состояние для управления поисковым запросом
    const [searchText, setSearchText] = useState('');

    // Функция для обновления поискового запроса,которую мы будем передавать в CampaignContentsTable
    const onSearchChange = (e) => {
        setSearchText(e.target.value);
    };

    const filterAdsets = (row) => {
        const value = searchText?.trim()?.toLowerCase();

        if (!value || value.length === 0) return true;

        const searchFiled = modalType === 'adset' ? 'adsetName' : 'adName';

        return row?.[searchFiled]?.trim()?.toLowerCase()?.includes(value);
    };

    return (
        <Modal
            title={<CustomModalName name={modalName} type={modalType} accountId={accountId} campaignId={campaignId} />}
            footer={false}
            maskClosable
            width={'85vw'}
            onCancel={onClose}
            visible={modalVisible}
        >
            <AdsVolumeLimitsBoard pages={allAffectedPages.concat(affectedAccount)} />
            <Input
                placeholder="Search name"
                value={searchText}
                onChange={onSearchChange}
                prefix={<SearchOutlined />}
                style={{ flex: 1 }}
            />
            <CampaignContentsTable type={modalType} loading={loading} data={data.filter(filterAdsets)} />
        </Modal>
    );
};

export default CampaignDetailsModal;
