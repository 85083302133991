import request from 'util/request';

export const saveNewTag = (newTag) => {
    return request.post('/tags/createTag', { data: newTag });
};

export const setAdsetTags = (adsetId, accountId, tags) => {
    return request.post('/tags/setTags', { data: { adsetId, accountId, tags } });
};

export const fetchTagsList = () => {
    return request.get('/tags');
};

export default {
    saveNewTag,
    setAdsetTags,
    fetchTagsList,
};
