import React from 'react';
import { Button, Spin } from 'antd';
import { LoginOutlined } from '@ant-design/icons';
import { useAuth } from 'hooks/useAuth';

export default () => {
    const { isLoading, login } = useAuth();

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <Spin size="large" spinning={isLoading}>
                <Button type="primary" shape="round" icon={<LoginOutlined />} size="large" onClick={login}>
                    Log in
                </Button>
            </Spin>
        </div>
    );
};
