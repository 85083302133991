import React, { Component } from 'react';
import { isExternal } from 'util/checkExternal';
import './index.css';

const tips = [
    {
        keyCombination: 'Ctrl + Enter',
        description: <>Run Report</>,
        hidden: false,
    },
    {
        keyCombination: 'Ctrl + Click',
        description: (
            <>
                Filtering with operand <span className="tip-bold">EQUAL</span>
            </>
        ),
        hidden: false,
    },
    {
        keyCombination: 'Alt + Click',
        description: (
            <>
                <span className="tip-bold">Multiple</span> filtering with operand{' '}
                <span className="tip-bold">EQUAL</span>
            </>
        ),
        hidden: false,
    },
    {
        keyCombination: 'Ctrl + Alt + Click',
        description: (
            <>
                Filtering with operand <span className="tip-bold">NOT EQUAL</span>
            </>
        ),
        hidden: false,
    },
    {
        keyCombination: 'DoubleClick Bid',
        description: (
            <>
                Change <span className="tip-bold">bid</span> (for Ad Set only)
            </>
        ),
        hidden: isExternal(),
    },
    {
        keyCombination: 'DoubleClick Budget',
        description: (
            <>
                Change <span className="tip-bold">budget</span> (for Ad Set only)
            </>
        ),
        hidden: isExternal(),
    },
];

export default class FilteringTips extends Component {
    render() {
        return (
            <div className="filtering-tips">
                {tips
                    .filter((t) => !t.hidden)
                    .map((t) => {
                        return (
                            <div key={t.keyCombination} className="tip-line">
                                <span className="tip-bolder">{t.keyCombination}</span>
                                <span>—</span>
                                <span>{t.description}</span>
                            </div>
                        );
                    })}
            </div>
        );
    }
}
