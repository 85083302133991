import request from 'util/request';
import dayjs from 'dayjs';

const formatDate = (date = {}) => {
    return {
        ...date,
        range: [dayjs(date.range[0]).format('YYYY-MM-DD HH:mm:ss'), dayjs(date.range[1]).format('YYYY-MM-DD HH:mm:ss')],
    };
};

const getDimensionsMetrics = () => {
    return request.get('/dashboard/dimensions');
};

const fetchReportData = (params) => {
    const data = {
        date: formatDate(params.date),
        dimensions: params.dimensions,
        metrics: params.metrics,
        filters: params.filters,
    };
    return request.post('/dashboard/taboola-report-query', { data });
};

export default {
    getDimensionsMetrics,
    fetchReportData,
};
