import React from 'react';
import { Tag, Space, Tooltip, Row, Col } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { setAdset } from 'store/tags/actionCreator';
import { PlusOutlined } from '@ant-design/icons';

const AddTag = (props) => {
    const { adset } = props;
    const dispatch = useDispatch();

    const onAddTagClick = () => {
        dispatch(setAdset(adset));
    };

    return <PlusOutlined onClick={onAddTagClick} />;
};

const CustomTag = (props) => {
    const { name, color = '', description } = props;

    return (
        <Tooltip title={description}>
            <Tag color={color || 'geekblue'}>{name}</Tag>
        </Tooltip>
    );
};

const TagsRow = (props) => {
    const { row } = props;
    const { tags = [] } = row;

    const tagsComponents = tags?.map((tag) => (
        <CustomTag name={tag.name} color={tag.color} description={tag.description} />
    ));
    return (
        <Row>
            <Col xs={22}>
                <Space>{tagsComponents}</Space>
            </Col>
            <Col xs={2}>
                <AddTag adset={row} />
            </Col>
        </Row>
    );
};

export default TagsRow;
