import React, { useState } from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Button, Tooltip } from 'antd';
import CurrentSelectedDimensions from 'components/CurrentSelectedDimensions';
import { GroupOutlined } from '@ant-design/icons';
import {
    selectMetrics,
    selectDimensions,
    removeFilters,
    hideDimensions,
    changeHidden,
} from 'store/currentSelection/actionCreators';
import { HIDE_TYPE } from 'data/hideTypes';

export const FieldsControlButton = () => {
    const {
        dimensions: selectedDimensions,
        metrics: selectedMetrics,
        hidden,
        dimensionsOrder = [],
    } = useSelector((store) => store.currentSelection);
    const dispatch = useDispatch();

    const [isVisible, setVisibility] = useState(false);

    const onChangeDimensions = (dimensions = []) => {
        const unchecked = _.difference(selectedDimensions, dimensions);
        if (unchecked.length) {
            dispatch(removeFilters({ fields: unchecked }));
        }
        const toHide = unchecked.map((field) => {
            return {
                field,
                hideType: HIDE_TYPE.MAIN,
            };
        });
        const toShow = dimensions.map((field) => {
            return {
                field,
                hideType: HIDE_TYPE.MAIN,
            };
        });
        dispatch(changeHidden(toShow, toHide));
        dispatch(selectDimensions(dimensions));
    };

    const onChangeMetrics = (metrics = []) => {
        const unchecked = _.difference(selectedMetrics, metrics);
        if (unchecked.length) {
            dispatch(removeFilters({ fields: unchecked }));
        }
        const toHide = unchecked.map((field) => {
            return {
                field,
                hideType: HIDE_TYPE.MAIN,
            };
        });
        const toShow = metrics.map((field) => {
            return {
                field,
                hideType: HIDE_TYPE.MAIN,
            };
        });
        dispatch(changeHidden(toShow, toHide));
        dispatch(selectMetrics(metrics));
    };

    const toggleModal = () => {
        setVisibility(!isVisible);
    };

    const onHide = (key, hideType) => {
        dispatch(hideDimensions({ [hideType]: [key] }));
    };

    return (
        <>
            <Tooltip title="Modify columns">
                <GroupOutlined onClick={toggleModal} style={{
                        fontSize: 25,
                    }} />
            </Tooltip>

            <Modal
                title="Show/hide and order"
                visible={isVisible}
                centered
                closable={true}
                maskClosable
                width={350}
                bodyStyle={{
                    height: 300,
                }}
                footer={[
                    <Button type="primary" onClick={toggleModal}>
                        Ok
                    </Button>,
                ]}
                onOk={() => setVisibility(false)}
                onCancel={() => setVisibility(false)}
            >
                <CurrentSelectedDimensions
                    onChangeDimensions={onChangeDimensions}
                    onChangeMetrics={onChangeMetrics}
                    selectedDimensions={selectedDimensions}
                    selectedMetrics={selectedMetrics}
                    dimensionsOrder={dimensionsOrder}
                    onHide={onHide}
                    hidden={hidden}
                />
            </Modal>
        </>
    );
};
