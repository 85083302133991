import React, { useState } from 'react';
import moment from 'moment-timezone';
import { DatePicker, Tag } from 'antd';
import { quickRanges } from 'util/quickRanges';
import './index.css';

const { RangePicker } = DatePicker;

const DateRange = (props) => {
    const { value, preset, disablePresets } = props;
    const [open, setOpen] = useState(false);

    const onChangeDate = (date, selectedPreset = null) => {
        props.onChange(
            [moment(date[0].toDate()).startOf('day'), moment(date[1].toDate()).endOf('day')],
            selectedPreset
        );
    };

    return (
        <RangePicker
            className="date-range-input"
            value={value.map((v) => moment(v.toDate()))}
            onChange={(date) => onChangeDate(date)}
            disabledDate={(cur) => cur > moment().endOf('day')}
            renderExtraFooter={() => {
                if (disablePresets === true) return;

                const ranges = quickRanges();
                return Object.keys(ranges).map((k) => (
                    <Tag
                        key={k}
                        color={preset === k ? '#7761c9' : '#c4aefc'}
                        onClick={() => {
                            setOpen(false);
                            onChangeDate(ranges[k], k);
                        }}
                    >
                        {k}
                    </Tag>
                ));
            }}
            allowClear={false}
            open={open}
            onOpenChange={setOpen}
            separator="-"
        />
    );
};

export default DateRange;
