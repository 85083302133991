import React, { useState, useContext } from 'react';
import _ from 'lodash';
import {
    Modal,
    Table,
    Input,
    Typography,
    Switch,
    message,
    Statistic,
    Space,
    Card,
    Tooltip,
    InputNumber,
    notification,
} from 'antd';
import { ArrowUpOutlined, ArrowDownOutlined, SearchOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import {
    setCampaignSiteDataRange,
    fetchTaboolaAllActivitySitesDataModal,
    setAllActivitySitesDataRange,
    setAllActivitySitesDataVisible,
} from 'store/taboola/actionCreator';
import getMetricCellValues from 'util/getMetricCellValues';
import { stringSorter, numberSorter } from 'util/tables';
import { hasUserPermissions } from 'util/permissions';
import DashboardContext from 'context/dashboardContext';
import * as FIELD from 'data/fields';
import DateRange from 'components/DateRange';
import formatNumber from 'util/formatNumber';

const { Text, Link } = Typography;

const SiteDataTable = (props) => {
    const { data, loading } = props;
    const columns = [
        {
            title: 'Site Name',
            dataIndex: 'SiteName',
            key: 'SiteName',
            width: 140,
            sorter: (a, b) => stringSorter('SiteName')(a, b),
        },
        {
            title: 'Site',
            dataIndex: 'Site',
            key: 'Site',
            width: 100,
            sorter: (a, b) => stringSorter('Site')(a, b),
        },
        {
            title: 'Clicks',
            dataIndex: 'Clicks',
            key: 'Clicks',
            width: 80,
            sorter: (a, b) => numberSorter('Clicks')(a, b),
        },
        {
            title: 'Impressions',
            dataIndex: 'Impressions',
            key: 'Impressions',
            width: 120,
            sorter: (a, b) => numberSorter('Impressions')(a, b),
        },
        {
            title: 'Spend',
            dataIndex: 'Spend',
            key: 'Spend',
            width: 80,
            sorter: (a, b) => numberSorter('Spend')(a, b),
            render: (value) => {
                return <span>{getMetricCellValues(value, { precision: 2, prefix: '$', isNumber: true }).value}</span>;
            },
        },
        {
            title: 'VisibleImpressions',
            dataIndex: 'VisibleImpressions',
            key: 'VisibleImpressions',
            width: 160,
            sorter: (a, b) => numberSorter('VisibleImpressions')(a, b),
        },
        {
            title: 'Revenue',
            dataIndex: 'Revenue',
            key: 'Revenue',
            width: 100,
            sorter: (a, b) => numberSorter('Revenue')(a, b),
            render: (value) => {
                return <span>{getMetricCellValues(value, { precision: 2, prefix: '$', isNumber: true }).value}</span>;
            },
        },
        {
            title: 'CPC',
            dataIndex: 'CPC',
            key: 'CPC',
            width: 80,
            sorter: (a, b) => numberSorter('CPC')(a, b),
            render: (value) => {
                return <span>{getMetricCellValues(value, { precision: 2, prefix: '$', isNumber: true }).value}</span>;
            },
        },
        {
            title: 'Profit',
            dataIndex: 'Profit',
            key: 'Profit',
            width: 80,
            sorter: (a, b) => numberSorter('Profit')(a, b),
            render: (value) => {
                return <span>{getMetricCellValues(value, { precision: 2, prefix: '$', isNumber: true }).value}</span>;
            },
        },
        {
            title: 'ROI',
            dataIndex: 'ROI',
            key: 'ROI',
            width: 80,
            sorter: (a, b) => numberSorter('ROI')(a, b),
            render: (value) => {
                return <span>{getMetricCellValues(value, { postfix: '%', precision: 1, isNumber: true }).value}</span>;
            },
        },
        {
            title: 'RPS',
            dataIndex: 'RPS',
            key: 'RPS',
            width: 80,
            sorter: (a, b) => numberSorter('RPS')(a, b),
            render: (value) => {
                return <span>{getMetricCellValues(value, { precision: 2, prefix: '$', isNumber: true }).value}</span>;
            },
        },
        {
            title: 'CPA',
            dataIndex: 'CPA',
            key: 'CPA',
            width: 80,
            sorter: (a, b) => numberSorter('CPA')(a, b),
            render: (value) => {
                return <span>{getMetricCellValues(value, { precision: 2, prefix: '$', isNumber: true }).value}</span>;
            },
        },
        {
            title: 'CVR',
            dataIndex: 'CVR',
            key: 'CVR',
            width: 80,
            sorter: (a, b) => numberSorter('CVR')(a, b),
            render: (value) => {
                return <span>{getMetricCellValues(value, { postfix: '%', precision: 1, isNumber: true }).value}</span>;
            },
        },
        {
            title: 'CTR',
            dataIndex: 'CTR',
            key: 'CTR',
            width: 80,
            sorter: (a, b) => numberSorter('CTR')(a, b),
            render: (value) => {
                return <span>{getMetricCellValues(value, { postfix: '%', precision: 1, isNumber: true }).value}</span>;
            },
        },
        {
            title: 'PPS',
            dataIndex: 'PPS',
            key: 'PPS',
            width: 80,
            sorter: (a, b) => numberSorter('PPS')(a, b),
            render: (value) => {
                return <span>{getMetricCellValues(value, { precision: 2, prefix: '', isNumber: true }).value}</span>;
            },
        },
        {
            title: 'RPM',
            dataIndex: 'RPM',
            key: 'RPM',
            width: 80,
            sorter: (a, b) => numberSorter('RPM')(a, b),
            render: (value) => {
                return <span>{getMetricCellValues(value, { precision: 2, prefix: '$', isNumber: true }).value}</span>;
            },
        },
    ];

    return (
        <Table
            size={'large'}
            rowKey={'Site'}
            dataSource={data || []}
            columns={columns}
            loading={loading}
            pagination={false}
            scroll={{ x: '100vw', y: '45vh' }}
        />
    );
};

const TaboolaAllActivitySitesDataModal = (date) => {
    const dispatch = useDispatch();
    const {
        allActivitySitesData,
        allActivitySitesDataVisibile,
        allActivitySitesDataLoading,
        allActivitySitesDataRange,
    } = useSelector((state) => state.taboola);

    const onClose = () => {
        dispatch(setAllActivitySitesDataVisible());
    };

    const onDateRangeChange = (date) => {
        dispatch(setAllActivitySitesDataRange(date));
        dispatch(fetchTaboolaAllActivitySitesDataModal(date));
    };

    return (
        <Modal
            title={'Sites Data'}
            footer={false}
            maskClosable
            width={'85vw'}
            onCancel={onClose}
            visible={allActivitySitesDataVisibile}
        >
            <div style={{ width: 240 }}>
                <DateRange value={allActivitySitesDataRange} disablePresets={true} onChange={onDateRangeChange} />
            </div>
            <SiteDataTable data={allActivitySitesData} loading={allActivitySitesDataLoading} />
        </Modal>
    );
};

export default TaboolaAllActivitySitesDataModal;
