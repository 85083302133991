import { combineReducers } from 'redux';
import dashboard from './dashboard/reducer';
import userActions from './userActions/reducer';
import auth from './auth/reducer';
import currentSelection from './currentSelection/reducer';
import campaignData from './campaignData/reducer';
import tags from './tags/reducer';
import searchCampaignStatistics from './searchCampaignStatistics/reducer';
import taboola from './taboola/reducer';
import adsense from './adsense/reducer';

export default combineReducers({
    dashboard,
    userActions,
    currentSelection,
    auth,
    campaignData,
    tags,
    searchCampaignStatistics,
    taboola,
    adsense,
});
