import request from 'util/request';
import dayjs from 'dayjs';

const formatDate = (date = {}) => {
    return {
        ...date,
        range: [dayjs(date.range[0]).format('YYYY-MM-DD HH:mm:ss'), dayjs(date.range[1]).format('YYYY-MM-DD HH:mm:ss')],
    };
};

export const fetchAdsetAdsData = (adsetName, date) => {
    return request.post('/campaign/ads', { data: { adsetName, date: formatDate(date) } });
};

export const preloadAdsetAdsData = (adsetName, date) => {
    return request.post('/campaign/ads-preload', { data: { adsetName, date: formatDate(date) } });
};

export const fetchCampaignAdSetsData = (campaignName, date) => {
    return request.post('/campaign/adsets', { data: { campaignName, date: formatDate(date) } });
};

export const preloadCampaignAdSetsData = (campaignName, date) => {
    return request.post('/campaign/adsets-preload', { data: { campaignName, date: formatDate(date) } });
};
