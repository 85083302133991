import React from 'react';
import { Button, Result } from 'antd';
import { useAuth } from 'hooks/useAuth';

import './NoAccess.css';

const NoAccess = () => {
    const { logout } = useAuth();

    return (
        <div className="noAccessContainer">
            <Result
                status="warning"
                title={
                    <>
                        <div>You don't have access to this application.</div>
                        <div>Please contact Administrator.</div>
                    </>
                }
                extra={
                    <>
                        <Button type="primary" onClick={() => window.location.reload()}>
                            Refresh Page
                        </Button>
                        <Button type="primary" danger onClick={() => logout({ returnTo: window.location.origin })}>
                            Logout
                        </Button>
                    </>
                }
            />
        </div>
    );
};

export default NoAccess;
