import * as FIELD from 'data/fields';

export const pinnedDimensions = [
    FIELD.CAMPAIGN_NAME_KEY,
    FIELD.ADSET_NAME_KEY,
    FIELD.ADSET_STATUS_KEY,
    FIELD.CAMPAIGN_STATUS_KEY,
];

const pinnedDimensionsSet = new Set(pinnedDimensions);

export const isDimensionPinned = (field) => pinnedDimensionsSet.has(field);
