import React, { useState, useEffect, useContext } from 'react';
import _ from 'lodash';
import { message, Popover, Spin, Space, Button } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';

import FilteringTips from 'components/FilteringTips';
import { FieldsControlButton } from 'components/ReportDashboard/DashboardFieldsControlButton';
import { ExportCSVButton } from 'components/ReportDashboard/ExportCSV';
import ResultTableComponent from 'components/ReportDashboard/DashboardResult/components/ResultTable';

import {
    addFilter,
    addFilters,
    removeEmptyFilters,
    setDimensionOrder,
    updateUserColumnsWidth,
} from 'store/currentSelection/actionCreators';
import { toggleAction } from 'store/userActions/actionCreators';
import {
    fetchTaboolaAllActivitySitesDataModal,
    setAllActivitySitesDataVisible,
} from '../../../store/taboola/actionCreator';
import {
    setSorting,
    setPagination,
    setDashboardDimensionOrder,
    updateColumnsWidth,
} from 'store/dashboard/actionCreators';
import { getActionFromRow } from 'util/actionsHelper';
import { runReports } from 'util/runReports';
import { FILTER_OPERAND } from 'data/filterOperands';
import DashboardContext from 'context/dashboardContext';

import './index.css';

const DashboardResult = () => {
    const dashboardContext = useContext(DashboardContext);

    const isTaboola = dashboardContext?.name === 'Taboola';
    const isAdSense = dashboardContext?.name === 'AdSense';

    const [selectedCells, setSelectedCells] = useState({});
    const {
        isDataFetching,
        actionItem,
        runFlag,
        filteredData = [],
        totalRow = {},
        columnsWidth = {},
        dimensions: selectedDimensions = [],
        metrics: selectedMetrics = [],
        order = [],
        hidden = {},
        sorting = {},
        pagination = {},
    } = useSelector((store) => store.dashboard);

    const dimensions = selectedDimensions.filter((d) => !hidden[d]);
    const metrics = selectedMetrics.filter((m) => !hidden[m]);

    const dispatch = useDispatch();

    const onSelectCell = (cellKey, field, value) => {
        console.info(field, cellKey, value);
        const { [cellKey]: existCell, ...restSelectedCells } = selectedCells || {};
        const newSelectedCell = existCell ? {} : { [cellKey]: { field, value } };
        const cell = document.querySelector(`.filterable-cell-content.${cellKey}`);
        if (cell) cell.style.background = existCell ? '' : '#3ebbd233';
        setSelectedCells({ ...restSelectedCells, ...newSelectedCell });
    };

    const onFilterCellClick = (e, field, value = '', cellKey) => {
        if (e.ctrlKey || e.metaKey) {
            const operand = e.altKey ? FILTER_OPERAND.NOT_EQUAL : FILTER_OPERAND.EQUAL;
            dispatch(removeEmptyFilters());
            dispatch(addFilter({ field, items: [{ operand, value }] }));
            dispatch(runReports(dashboardContext));
        } else if (e.altKey) {
            onSelectCell(cellKey, field, value);
        }
    };

    const onToggleRowAction = (action, row, params = {}) => {
        const actionElement = getActionFromRow(actionItem, action, row, params);
        dispatch(toggleAction(actionElement, isAdSense)).then((res) => {
            if (res) {
                message.success('Added', 1.5);
            } else {
                message.warn('Cancelled', 1.5);
            }
        });
    };

    const onTableChange = (p, f, newSorting = {}, { action }) => {
        if (action === 'sort') {
            const sortingObject = (Array.isArray(newSorting) ? newSorting : [newSorting]).reduce(
                (acc, s) => (s.field && s.order ? { ...acc, [s.field]: s.order } : acc),
                {}
            );
            dispatch(setSorting(sortingObject));
        }
    };

    const onPaginationChange = (page, pageSize) => {
        dispatch(setPagination({ pageSize }));
    };

    const onColumnsOrderChange = (newOrder = []) => {
        dispatch(setDimensionOrder(newOrder));
        dispatch(setDashboardDimensionOrder(newOrder));
    };

    const onColumnsSizeChange = (columnKey, width) => {
        if (columnKey) {
            dispatch(updateUserColumnsWidth({ [columnKey]: width }));
            dispatch(updateColumnsWidth({ [columnKey]: width }));
        }
    };

    useEffect(() => {
        const handleKeyUp = (e) => {
            if (e.key === 'Alt') {
                // workaroung to get current state
                setSelectedCells((freshSelectedCells) => {
                    if (Object.keys(freshSelectedCells).length) {
                        const groupedFilters = _.groupBy(Object.values(freshSelectedCells), 'field');

                        const filters = [];
                        for (const field in groupedFilters) {
                            const items = groupedFilters[field].map((itm) => ({
                                operand: FILTER_OPERAND.EQUAL,
                                value: itm.value,
                            }));
                            filters.push({
                                field,
                                items,
                            });
                        }

                        dispatch(addFilters(filters));
                        dispatch(runReports(dashboardContext));

                        for (const k in freshSelectedCells) {
                            const cell = document.querySelector(`.filterable-cell-content.${k}`);
                            if (cell) cell.style.background = '';
                        }
                    }
                    // clear selected cells
                    return {};
                });
            }
        };

        window.addEventListener('keyup', handleKeyUp);
        return () => {
            window.removeEventListener('keyup', handleKeyUp);
        };
    }, []);

    const onAllActivityData = () => {
        dispatch(setAllActivitySitesDataVisible());
        dispatch(fetchTaboolaAllActivitySitesDataModal());
    };
    return (
        <div
            className="dashboard-result"
            style={{ border: 'thin solid #f0f0f0', borderRadius: 5, backgroundColor: 'white', padding: 10 }}
        >
            <Spin spinning={isDataFetching}>
                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginTop: 10,
                        marginBottom: 5,
                    }}
                    className="shortcuts-block"
                >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ paddingRight: 5, fontSize: 18 }} className="top-title dashboard-result-title">
                            Shortcuts
                        </div>
                        <Popover
                            placement="topLeft"
                            title="Shortcut tips"
                            content={<FilteringTips />}
                            trigger="click"
                            zIndex={999999999}
                        >
                            <InfoCircleOutlined style={{ fontSize: 18 }} />
                        </Popover>
                    </div>
                    <div>{isTaboola && <Button onClick={onAllActivityData}> Site data per all activity </Button>}</div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Space align="center">
                            <ExportCSVButton />
                            <FieldsControlButton />
                        </Space>
                    </div>
                </div>
                <ResultTableComponent
                    data={filteredData}
                    totalRow={totalRow}
                    order={order}
                    actionItem={actionItem}
                    dimensions={dimensions}
                    metrics={metrics}
                    sorting={sorting}
                    pagination={pagination}
                    runFlag={runFlag}
                    columnsWidth={columnsWidth}
                    onFilterCellClick={onFilterCellClick}
                    onToggleRowAction={onToggleRowAction}
                    onChange={onTableChange}
                    onPaginationChange={onPaginationChange}
                    onColumnsOrderChange={onColumnsOrderChange}
                    onColumnsSizeChange={onColumnsSizeChange}
                />
            </Spin>
        </div>
    );
};

export default DashboardResult;
