import React, { useContext } from 'react';
import { Tooltip, Button, Space, Typography } from 'antd';
import {
    StockOutlined,
    UnorderedListOutlined,
    GlobalOutlined,
    BarChartOutlined,
    FileImageOutlined,
} from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { stringSorter } from 'util/tables';
import {
    changeCampaignModalVisibility,
    getCampaignAdSetsData,
    getCampaignAdsData,
    changeCampaignModalType,
    getAdsetAdsData,
} from 'store/campaignData/actionCreator';

import {
    changeModalVisibility,
    setTargetCampaignId,
    setTargetAdsetId,
    loadCampaignStatisticsData,
    loadAdsetStatisticsData,
} from 'store/searchCampaignStatistics/actionCreator';

import TagsRow from 'components/ReportDashboard/DashboardResult/components/TagsRow';
import * as FIELD from 'data/fields';
import DashboardContext from 'context/dashboardContext';
import { isDimensionPinned } from 'data/pinnedDimensions';

import {
    fetchCampaignSiteData,
    fetchTaboolaCampaignLast7DaysData,
    fetchTaboolaCampaignAdsData,
    setSelectedCampaignId,
    setCampaignSitePopupVisible,
    setCampaignAdsPopupVisible,
} from 'store/taboola/actionCreator';

import * as AdSenseActions from 'store/adsense/actionCreator';
import moment from 'moment';

const { Text } = Typography;

const onLinkClick = (e) => {
    console.log(e.ctrlKey, e.metaKey, e.altKey);
    if (e.ctrlKey || e.metaKey || e.altKey) {
        e.preventDefault();
    }
};

const CampaignLink = (props) => {
    const { row = {}, value, isTikTok, isTaboola } = props;
    const accountId = row[FIELD.ACCOUNT_ID_KEY];

    /** Facebook search is based on accountId */
    if (!accountId) return value;

    if (isTaboola) {
        const campaignId = row[FIELD.CAMPAIGN_ID_KEY];

        if (!campaignId) return value;

        const url = `https://ads.taboola.com/campaigns/campaign/edit/${accountId}/${campaignId}?locale=en&accountId=1501470&campaignId=${campaignId}&reportId=campaign&dimension=SPONSORED&datePreset=SEVEN_DAYS&filters_active=true&reportViewName=All&filters=%5B%7B%22type%22%3A%22campaignStatus%22%2C%22values%22%3A%5B%7B%22label%22%3A%22Recent%22%2C%22value%22%3A%22RECENT%22%7D%5D%7D%5D&page=1&autoFiltersTrigger=%257B%2522reportId%2522%253A%2522campaign%2522%252C%2522accountName%2522%253A%2522kodigital-network%2522%257D`;
        return (
            <a href={url} style={{ cursor: 'pointer' }} rel="noopener noreferrer" target="_blank" onClick={onLinkClick}>
                {value}
            </a>
        );
    }

    /** If adsetId is available - use it to find adsets in facebook */
    const adsetId = row[FIELD.ADSET_ID_KEY];
    if (adsetId) {
        const url = isTikTok
            ? `https://ads.tiktok.com/i18n/perf/adgroup?aadvid=${accountId}&keyword=${adsetId}&search_type=2`
            : `https://business.facebook.com/adsmanager/manage/adsets/edit?act=${accountId}&columns=name%2Cdelivery%2Ccampaign_name%2Cbid%2Cbudget%2Clast_significant_edit%2Cresults%2Creach%2Cimpressions%2Ccost_per_result%2Cquality_score_organic%2Cquality_score_ectr%2Cquality_score_ecvr%2Cspend%2Cend_time%2Cschedule%2Cactions%3Alink_click%2Cactions%3Aoffsite_conversion.fb_pixel_purchase%2Cactions%3Aonsite_conversion.purchase&attribution_windows=default&selected_adset_ids=${adsetId}`;
        return (
            <a href={url} style={{ cursor: 'pointer' }} rel="noopener noreferrer" target="_blank" onClick={onLinkClick}>
                {value}
            </a>
        );
    }

    /** If campaignName is available - use it to find campaign in facebook */
    const campaignName = row[FIELD.CAMPAIGN_NAME_KEY];
    if (campaignName) {
        const url = isTikTok
            ? `https://ads.tiktok.com/i18n/perf/adgroup?aadvid=${accountId}&keyword=${campaignName}`
            : `https://business.facebook.com/adsmanager/manage/campaigns?act=${accountId}&filter_set=SEARCH_BY_CAMPAIGN_GROUP_NAME-STRING%1ECONTAIN%1E%22${campaignName}%22`;
        return (
            <a href={url} style={{ cursor: 'pointer' }} rel="noopener noreferrer" target="_blank" onClick={onLinkClick}>
                {value}
            </a>
        );
    }

    return value;
};

const AdsetDetailsButton = (props) => {
    const { adsetName } = props;
    const dispatch = useDispatch();

    const date = useSelector((store) => store.dashboard.date);

    const changeModalVisibility = (modalType, adsetName) => {
        dispatch(changeCampaignModalType(modalType));

        if (modalType === 'ad') dispatch(getAdsetAdsData(adsetName, date));

        dispatch(changeCampaignModalVisibility());
    };

    return (
        <Space align="start" style={{ width: '100%' }}>
            <Button
                type="link"
                size="small"
                onClick={() => {
                    changeModalVisibility('ad', adsetName);
                }}
            >
                <Text>
                    <UnorderedListOutlined /> Ads
                </Text>
            </Button>
        </Space>
    );
};

const CampaignDetailsButtons = (props) => {
    const { campaignName, isAdsense } = props;
    const dispatch = useDispatch();

    const date = useSelector((store) => store.dashboard.date);

    const changeModalVisibility = (modalType, campaignName) => {
        dispatch(changeCampaignModalType(modalType));

        if (modalType === 'adset') dispatch(getCampaignAdSetsData(campaignName, date));
        if (modalType === 'ad') dispatch(getCampaignAdsData(campaignName, date));

        dispatch(changeCampaignModalVisibility());
    };

    return (
        <Space align="start" style={{ width: '100%' }}>
            <Button
                type="link"
                size="small"
                onClick={() => {
                    changeModalVisibility('adset', campaignName);
                }}
            >
                <Text>
                    <UnorderedListOutlined /> AdSets
                </Text>
            </Button>
            {/* <Button
                type="link"
                size="small"
                // onClick={() => {
                //     changeModalVisibility('ad', campaignName);
                // }}
            >
                <Text>
                    <UnorderedListOutlined /> Ads
                </Text>
            </Button> */}
        </Space>
    );
};

const SearchCampaignStatisticsButton = (props) => {
    const { campaignId, adsetId } = props;
    const dispatch = useDispatch();

    const openStatisticsModal = (campaignId, adsetId) => {
        if (campaignId) {
            dispatch(setTargetCampaignId(campaignId));
            dispatch(changeModalVisibility());
            dispatch(loadCampaignStatisticsData());
        }

        if (adsetId) {
            dispatch(setTargetAdsetId(adsetId));
            dispatch(changeModalVisibility());
            dispatch(loadAdsetStatisticsData());
        }
    };

    return (
        <Space align="start" style={{ width: '100%' }}>
            <Button
                type="link"
                size="small"
                onClick={() => {
                    openStatisticsModal(campaignId, adsetId);
                }}
            >
                <Text>
                    <StockOutlined /> Last 7 Days
                </Text>
            </Button>
        </Space>
    );
};

const TaboolaCampaignDetailsButtons = (props) => {
    const { campaignId } = props;
    const dispatch = useDispatch();

    const sitesDataRange = useSelector((store) => store.taboola.campaignSitesDataSelectedRange);
    const adsDataRange = useSelector((store) => store.taboola.campaignAdsDataSelectedRange);

    const showSiteDataModal = () => {
        dispatch(setSelectedCampaignId(campaignId));
        dispatch(fetchCampaignSiteData(campaignId, sitesDataRange));
        dispatch(setCampaignSitePopupVisible());
    };

    const showAdsDataModal = () => {
        dispatch(setSelectedCampaignId(campaignId));
        dispatch(fetchTaboolaCampaignAdsData(campaignId, adsDataRange));
        dispatch(setCampaignAdsPopupVisible());
    };

    return (
        <Space align="start" style={{ width: '100%' }}>
            <Button type="link" size="small" onClick={showSiteDataModal}>
                <Text>
                    <GlobalOutlined /> Site Data
                </Text>
            </Button>
            <Button
                type="link"
                size="small"
                onClick={() => {
                    dispatch(fetchTaboolaCampaignLast7DaysData(campaignId));
                }}
            >
                <Text>
                    <BarChartOutlined /> Last 7 days data
                </Text>
            </Button>
            <Button type="link" size="small" onClick={showAdsDataModal}>
                <Text>
                    <FileImageOutlined /> Ads data
                </Text>
            </Button>
        </Space>
    );
};

const AdSenseAdsDetailsButton = (props) => {
    const { campaignId } = props;
    const dispatch = useDispatch();

    const showAdsDataModal = () => {
        dispatch(AdSenseActions.setAdSenseAdsTargetCampaignId(campaignId));
        dispatch(AdSenseActions.setAdSenseAdsDateRange([moment(), moment()]));
        dispatch(AdSenseActions.fetchAdSenseCampaignAdsData(campaignId, [moment(), moment()]));
        dispatch(AdSenseActions.setAdSenseAdsPopupVisible());
    };

    return (
        <Button type="link" size="small" onClick={showAdsDataModal}>
            <Text>
                <GlobalOutlined /> Ads Data
            </Text>
        </Button>
    );
};

const useDimensionColumns = (props) => {
    const dashboardContext = useContext(DashboardContext);
    const isTikTok = dashboardContext.name === 'TikTok';
    const isSearch = dashboardContext.name === 'Explorads';
    const isAdsense = dashboardContext.name === 'AdSense';
    const isTaboola = dashboardContext.name === 'Taboola';

    const { dimensions = [], columnsWidth = {}, sorting = {}, onCellClick, isTotalRow } = props;
    return dimensions.map((key) => {
        const dimension = dashboardContext.dimensionsListHash[key];
        if (!dimension || key === 'date') return null;
        return {
            dataIndex: key,
            title: dimension.title,
            fixed: isDimensionPinned(key) ? 'left' : false,
            className: 'grid-cell',
            width: columnsWidth[key] || 200,
            ellipsis: true,
            render: (value, row, ind) => {
                const cellKey = key + ind;
                if (isTotalRow) {
                    return <div className="filterable-cell-content">{value}</div>;
                }

                if (key === FIELD.CAMPAIGN_NAME_KEY) {
                    return (
                        <div
                            className={'filterable-cell-content ' + cellKey}
                            style={{ lineHeight: 1, textAlign: 'left' }}
                            onClick={(e) => onCellClick(e, key, value, cellKey)}
                        >
                            <Tooltip placement="top" title={value} trigger="hover" zIndex={999999999}>
                                <Space direction="vertical" size={4} style={{ width: '100%', alignItems: 'baseline' }}>
                                    <CampaignLink value={value} row={row} isTikTok={isTikTok} isTaboola={isTaboola} />
                                    {!isSearch && !isTaboola && !isAdsense && (
                                        <CampaignDetailsButtons campaignName={value} />
                                    )}
                                    {isSearch && row?.[FIELD.CAMPAIGN_ID_KEY] && (
                                        <SearchCampaignStatisticsButton campaignId={row[FIELD.CAMPAIGN_ID_KEY]} />
                                    )}
                                    {isTaboola && row?.[FIELD.CAMPAIGN_ID_KEY] && (
                                        <TaboolaCampaignDetailsButtons campaignId={row[FIELD.CAMPAIGN_ID_KEY]} />
                                    )}
                                    {isAdsense && row?.[FIELD.CAMPAIGN_ID_KEY] && (
                                        <AdSenseAdsDetailsButton campaignId={row[FIELD.CAMPAIGN_ID_KEY]} />
                                    )}
                                </Space>
                            </Tooltip>
                        </div>
                    );
                }

                if (key === 'adsetName') {
                    const regexp = /-et\d+-/;
                    const isAscTask = regexp.test(value);

                    return (
                        <div
                            className={'filterable-cell-content ' + cellKey}
                            style={{ lineHeight: 1, textAlign: 'left' }}
                            onClick={(e) => onCellClick(e, key, value, cellKey)}
                        >
                            <Tooltip placement="top" title={value} trigger="hover" zIndex={999999999}>
                                <Space direction="vertical" size={4} style={{ width: '100%', alignItems: 'baseline' }}>
                                    <CampaignLink value={value} row={row} isTikTok={isTikTok} />
                                    {isAscTask && <AdsetDetailsButton adsetName={value} />}
                                    {isAdsense && row?.[FIELD.ADSET_ID_KEY] && (
                                        <SearchCampaignStatisticsButton adsetId={row?.[FIELD.ADSET_ID_KEY]} />
                                    )}
                                </Space>
                            </Tooltip>
                        </div>
                    );
                }

                if (key === 'tags') {
                    return (
                        <div
                            className={'filterable-cell-content ' + cellKey}
                            style={{ lineHeight: 1, textAlign: 'left' }}
                            onClick={(e) => onCellClick(e, key, value, cellKey)}
                        >
                            <div className={'filterable-cell-content tags ' + cellKey}>
                                <TagsRow row={row} />
                            </div>
                        </div>
                    );
                }

                return (
                    <div
                        className={'filterable-cell-content ' + cellKey}
                        onClick={(e) => onCellClick(e, key, value, cellKey)}
                    >
                        {value}
                    </div>
                );
            },
            sorter: stringSorter(key),
            sortOrder: sorting[key] || false,
        };
    });
};

export default useDimensionColumns;
