import React from 'react';
import { useSelector } from 'react-redux';
import BulkActions from 'components/BulkActions';
import { Divider, Spin } from 'antd';

const DashboardActions = ({ style }) => {
    const actionItem = useSelector((store) => store.dashboard.actionItem);
    const isDataFetching = useSelector((store) => store.dashboard.isDataFetching);

    return (
        <div className="dashboard-result-header" style={style}>
            <Spin spinning={isDataFetching}>
                <Divider>Actions</Divider>
                {!!actionItem && <BulkActions />}
            </Spin>
        </div>
    );
};

export default DashboardActions;
