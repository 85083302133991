import * as actions from 'store/actions';
import AuthService from 'service/auth.service';
import { getAppVersionFromStorage } from 'util/localStorage';
import { showVersionUpdateModal } from 'util/versionUpdate';

export const authenticate = () => async (dispatch) => {
    try {
        dispatch({
            type: actions.USER_SET,
            user: null,
            loading: true,
            finished: false,
        });

        const resp = await AuthService.authorize();
        if (resp.error) throw new Error(resp.error);

        // check version
        if (resp?.data?.version && resp.data.version !== getAppVersionFromStorage()) {
            showVersionUpdateModal(resp.data.version);
            return;
        }

        dispatch({
            type: actions.USER_SET,
            user: resp.data.user,
            loading: false,
            finished: true,
        });
    } catch (err) {
        console.error('auth error', err);
        dispatch({
            type: actions.USER_SET,
            user: null,
            loading: false,
            finished: true,
        });
    }
};
