import * as actions from 'store/actions';

const ACTION_HANDLERS = {
    [actions.USER_SET]: (state, { user, loading, finished }) => {
        return {
            ...state,
            user,
            isLoading: !!loading,
            isFinished: !!finished,
        };
    },
};

const initialState = {
    user: null,
    isLoading: false,
    isFinished: false,
};

export default function (state = initialState, action) {
    const handler = ACTION_HANDLERS[action.type];

    return handler ? handler(state, action) : state;
}
