import _ from 'lodash';
import { notification } from 'antd';
import store from 'store/store';
import { runMainReport } from 'store/dashboard/actionCreators';

export const canRunReport = (allDimensionsHash) => {
    const { filters = {} } = store.getState().currentSelection;

    for (const key in filters) {
        const { field, items = [] } = filters[key];
        if (!field) throw new Error(`Filter field is empty`);
        const { title } = allDimensionsHash[field] || {};
        if (!items.length) throw new Error(`Filter for '${title}' is incorrect - no conditions`);
        for (const item of items) {
            if (!item.operand) throw new Error(`Filter for '${title}' is incorrect - no operand`);
            if (_.isNil(item.value) || !item.value.toString().length)
                throw new Error(`Filter for '${title}' is incorrect - no value`);
        }
    }
};

export const runReports =
    (dashboardConfig, forceUpdate = false) =>
    (dispatch) => {
        try {
            canRunReport(dashboardConfig.allDimensionsHash);
            dispatch(runMainReport(dashboardConfig, forceUpdate));
        } catch (err) {
            console.error('runReports error', err.message);
            notification.error({
                message: 'Error',
                description: err.message,
            });
        }
    };
