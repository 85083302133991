import React from 'react';
import { Image } from 'antd';

import logoFull from 'source/logo.svg';
import logo from 'source/logoIcon.png';

const Logo = ({ fullLogo = false }) => {
    const logoSrc = fullLogo ? logoFull : logo;
    const style = fullLogo
        ? {
              width: '100%',
          }
        : {
              width: '300%',
              position: 'relative',
              right: '90%',
          };
    return <Image src={logoSrc} width={'100%'} style={style} alt="logo" preview={false} />;
};

export default Logo;
