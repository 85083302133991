import React, { useContext } from 'react';
import _ from 'lodash';
import { Table, Select, Button, Input, InputNumber, DatePicker } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { addFilter, changeFilter, removeFilters } from 'store/currentSelection/actionCreators';
import { FILTER_OPERAND, operandsList } from 'data/filterOperands';
import DashboardContext from 'context/dashboardContext';

import './index.css';

const { RangePicker } = DatePicker;

const SelectionItem = (props) => {
    const dashboardContext = useContext(DashboardContext);
    const { filter, onChange, allDimensions = [], filterOptions = {} } = props;
    const { field, items = [] } = filter;
    const dimension = dashboardContext.allDimensionsHash[field] || {};
    const { title, isNumber, isDate, isMultipleSelect } = dimension;

    const { interval } = useSelector((store) => store.currentSelection.date);

    const onChangeField = (newField) => {
        const newDimension = dashboardContext.allDimensionsHash[newField];

        // if previous or new field is combobox - clear filters
        // OR if field type (string/number) changed - clear filters
        const newItems =
            newDimension.isMultipleSelect || isMultipleSelect || newDimension.isNumber !== isNumber
                ? [
                      {
                          ...items[0],
                          operand: newDimension.isMultipleSelect ? FILTER_OPERAND.ONE_OF : FILTER_OPERAND.EQUAL,
                          value: newDimension.isMultipleSelect ? [] : newDimension.isNumber ? 0 : '',
                      },
                  ]
                : items;

        onChange(newField, newItems);
    };

    const onChangeOperand = (ind, op) => {
        onChange(
            field,
            items.map((item, i) => ({ ...item, operand: i === ind ? op : item.operand }))
        );
    };

    const onChangeValue = (ind, val = '') => {
        onChange(
            field,
            items.map((item, i) => ({ ...item, value: i === ind ? val : item.value }))
        );
    };

    const onDeleteItem = (ind) => {
        onChange(field, ind === 0 ? [] : _.reject(items, (v, i) => i === ind));
    };

    const onAddOr = () => {
        onChange(field, [...items, { operand: FILTER_OPERAND.EQUAL }]);
    };

    const operands = operandsList.filter((op) => {
        if (title === 'Tags' && op.key === FILTER_OPERAND.ONE_OF) return true;
        else if (title === 'Tags' && op.key !== FILTER_OPERAND.ONE_OF) return false;

        if (!isMultipleSelect && op.key === FILTER_OPERAND.ONE_OF) return false;
        if (!isMultipleSelect && op.key === FILTER_OPERAND.NOT_ONE_OF) return false;
        if (isDate && op.key === FILTER_OPERAND.IN_RANGE) return true;
        if (isNumber) return op.number;
        return op.string;
    });

    const columns = [
        {
            dataIndex: 'title',
            width: 210,
            render: (v, r, ind) => {
                if (ind === 0) {
                    return (
                        <Select
                            style={{ width: '100%' }}
                            value={title}
                            onChange={onChangeField}
                            filterOption={(input, option) =>
                                (option.children || '').toLowerCase().includes((input || '').toLowerCase())
                            }
                            showSearch
                        >
                            {allDimensions.map((dimension) => (
                                <Select.Option key={dimension.key} value={dimension.key}>
                                    {dimension.title}
                                </Select.Option>
                            ))}
                        </Select>
                    );
                }
            },
        },
        {
            dataIndex: 'operand',
            width: 120,
            render: (val, row, ind) => (
                <Select style={{ width: '100%' }} value={val} onChange={(v) => onChangeOperand(ind, v)}>
                    {operands.map((op) => (
                        <Select.Option key={op.key} value={op.key}>
                            {op.label}
                        </Select.Option>
                    ))}
                </Select>
            ),
        },
        {
            dataIndex: 'value',
            render: (val, row, ind) => {
                if (isNumber) {
                    return (
                        <InputNumber style={{ width: '100%' }} value={val} onChange={(v) => onChangeValue(ind, v)} />
                    );
                }
                if (
                    isMultipleSelect &&
                    (row.operand === FILTER_OPERAND.ONE_OF || row.operand === FILTER_OPERAND.NOT_ONE_OF)
                ) {
                    return (
                        <Select
                            style={{ width: '100%' }}
                            value={val}
                            onChange={(newVal) => onChangeValue(ind, newVal)}
                            mode="multiple"
                            filterOption={(input, option) =>
                                (option.children || '').toLowerCase().includes((input || '').toLowerCase())
                            }
                            showSearch
                            allowClear
                        >
                            {(filterOptions[dimension.key] || []).map((opt) => (
                                <Select.Option key={opt} value={opt}>
                                    {opt}
                                </Select.Option>
                            ))}
                        </Select>
                    );
                }

                if (isDate && row.operand === FILTER_OPERAND.EQUAL) {
                    return (
                        <DatePicker
                            showTime={interval === 'hour' ? { format: 'HH' } : false}
                            format={interval === 'hour' ? 'YYYY-MM-DD HH:00:00' : 'YYYY-MM-DD'}
                            onChange={(date, dateString) => onChangeValue(ind, dateString)}
                            style={{ width: '100%' }}
                        />
                    );
                }

                if (isDate && row.operand === FILTER_OPERAND.IN_RANGE) {
                    return (
                        <RangePicker
                            showTime={interval === 'hour' ? { format: 'HH' } : false}
                            format={interval === 'hour' ? 'YYYY-MM-DD HH:00:00' : 'YYYY-MM-DD'}
                            onChange={(date, dateString) => onChangeValue(ind, dateString)}
                            style={{ width: '100%' }}
                        />
                    );
                }

                return (
                    <Input style={{ width: '100%' }} value={val} onChange={(e) => onChangeValue(ind, e.target.value)} />
                );
            },
        },
        {
            dataIndex: 'action',
            width: 40,
            render: (v, r, ind) => {
                if (ind !== 0) return null;
                return (
                    <div style={{ textAlign: 'center' }}>
                        <Button style={{ padding: '0 4px' }} size="small" type="link" onClick={onAddOr}>
                            OR
                        </Button>
                    </div>
                );
            },
        },
        {
            dataIndex: 'action',
            width: 50,
            render: (v, r, ind) => (
                <div style={{ textAlign: 'center' }}>
                    <Button size="small" icon={<DeleteOutlined />} type="text" onClick={() => onDeleteItem(ind)} />
                </div>
            ),
        },
    ];
    return (
        <div className="selection-list-item">
            <Table
                className="selection-list-item-table"
                rowKey="key"
                columns={columns}
                dataSource={items}
                size="small"
                pagination={false}
                showHeader={false}
                bordered={false}
                tableLayout="auto"
                style={{ zIndex: 99999999 }}
                scroll={{ x: 'max-content' }}
            />
        </div>
    );
};

const SelectionItemsList = () => {
    const dashboardContext = useContext(DashboardContext);
    const dispatch = useDispatch();
    const { filters = {}, filterOptions = {} } = useSelector((store) => store.currentSelection);
    const allDimensions = dashboardContext.allDimensionsList;
    const onChange = (key, newField, newItems) => {
        if (newItems.length) {
            const newFilter = {
                ...filters[key],
                field: newField,
                items: newItems,
            };
            dispatch(changeFilter(key, newFilter));
        } else {
            dispatch(removeFilters({ keys: [key] }));
        }
    };

    const onAddFilter = () => {
        dispatch(addFilter());
    };

    const filterItems = Object.keys(filters).map((k) => (
        <SelectionItem
            key={k}
            filter={filters[k]}
            onChange={(newField, newItems) => onChange(k, newField, newItems)}
            allDimensions={allDimensions}
            filterOptions={filterOptions}
        />
    ));

    return (
        <>
            {filterItems}
            <Button style={{ width: '100%', fontWeight: 'bold' }} shape="default" type="text" onClick={onAddFilter}>
                + Add filter
            </Button>
        </>
    );
};

const CurrentSelection = () => {
    return (
        <div className="current-selection-wrapper">
            <div className="current-selection">
                <div className="current-selection-scroller">
                    <SelectionItemsList />
                </div>
            </div>
        </div>
    );
};

export default CurrentSelection;
