import request from 'util/request';

export const fetchAllPresets = (dashboardName) => {
    return request.get(`/presets/${dashboardName}`);
};

export const savePreset = (preset) => {
    return request.post('/presets/save', { data: preset });
};

export const deletePreset = (id) => {
    return request.get(`/presets/delete/${id}`);
};
