import React, { useEffect, useContext } from 'react';
import _ from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { Col, Row } from 'antd';
import DashboardHeader from 'components/ReportDashboard/DashboardHeader';
import DashboardResult from 'components/ReportDashboard/DashboardResult';
import DashboardFilters from 'components/ReportDashboard/DashboardFilters';
import DashboardActions from 'components/ReportDashboard/DashboardActions';
import DashboardDimensionsMetrics from 'components/ReportDashboard/DashboardDimensionsMetrics';
import AccessByRole from 'components/Permission/AccessByRole';
import RefreshDataModal from 'components/RefreshDataModal';
import { runReports } from 'util/runReports';
import { fetchTagsList } from 'store/tags/actionCreator';
import CampaignDetailsModal from 'components/CampaignDetailsModal';
import TaboolaSitesDataModal from 'components/TaboolaSitesDataModal';
import Taboola7DaysDataModal from 'components/Taboola7DaysDataModal';
import TaboolaAdsData from 'components/TaboolaAdsData';
import AdsetHistoryModal from 'components/AdsetHistoryModal';
import SearchCampaignStatisticsModal from 'components/SearchCampaignStatisticsModal';
import TaboolaAllActivitySitesDataModal from 'components/TaboolaAllActivitySitesDataModal';
import AdSenseAdsDataModal from 'components/AdSenseAdsDataModal';

import DashboardContext from 'context/dashboardContext';
import { HIDE_TYPE } from 'data/hideTypes';

import { setInitialSelection, removeEmptyFilters } from 'store/currentSelection/actionCreators';
import TagsModal from 'components/TagsModal';

const ReportDashboard = () => {
    const dispatch = useDispatch();
    const dashboardContext = useContext(DashboardContext);

    const { hidden: currentHidden } = useSelector((store) => store.currentSelection);

    const toHide = [];
    const toShow = [];
    for (const type in dashboardContext.hidden) {
        if (type === 'undefined' || !type) continue;
        for (const field in dashboardContext.hidden[type]) {
            if (!field || field === '[object Object]') continue;

            const hideItem = {
                hideType: type,
                field,
            };

            if (dashboardContext.hidden[type][field] === true) toHide.push(hideItem);
            else toShow.push(hideItem);
        }
    }

    const remaining = _.difference(Object.keys(currentHidden.main), Object.keys(dashboardContext.hidden.main));
    for (const rem of remaining) {
        toShow.push({
            hideType: HIDE_TYPE.MAIN,
            field: rem,
        });
    }

    useEffect(() => {
        /** Set dashboard props */
        dispatch(
            setInitialSelection(
                toShow,
                toHide,
                dashboardContext.selectedDimensions,
                dashboardContext.selectedMetrics,
                dashboardContext.dimensionsOrder
            )
        );
    }, []);

    useEffect(() => {
        dispatch(removeEmptyFilters());
        dispatch(runReports(dashboardContext, true));
        dispatch(fetchTagsList());

        const keydownHandler = (e) => {
            // ctrl/cmd + Enter
            if ((e.ctrlKey || e.metaKey) && e.code === 'Enter') {
                dispatch(runReports(dashboardContext));
            }
        };
        window.addEventListener('keydown', keydownHandler);
        return () => {
            window.removeEventListener('keydown', keydownHandler);
        };
    }, []);

    return (
        <div className="report-dashboard">
            <DashboardHeader />
            <Row
                style={{
                    marginTop: 20,
                    marginBottom: 10,
                }}
                gutter={[20, 20]}
                align="top"
                justify="center"
            >
                <DashboardDimensionsMetrics />
            </Row>
            <Row
                style={{
                    marginTop: 20,
                    marginBottom: 10,
                }}
                gutter={[20, 20]}
                align="top"
            >
                <Col xs={24} sm={24} md={24} lg={12}>
                    <DashboardFilters
                        style={{
                            display: 'block',
                            border: 'thin solid #f0f0f0',
                            borderRadius: 5,
                            padding: 15,
                            backgroundColor: 'white',
                        }}
                    />
                </Col>
                <AccessByRole role="editor">
                    <Col xs={24} sm={24} md={24} lg={12}>
                        <DashboardActions
                            style={{
                                border: 'thin solid #f0f0f0',
                                borderRadius: 5,
                                padding: 15,
                                backgroundColor: 'white',
                            }}
                        />
                    </Col>
                </AccessByRole>
            </Row>
            <DashboardResult />
            <RefreshDataModal />
            <CampaignDetailsModal />
            <TaboolaSitesDataModal />
            <TaboolaAllActivitySitesDataModal />
            <TaboolaAdsData />
            <Taboola7DaysDataModal />
            <SearchCampaignStatisticsModal />
            <AdsetHistoryModal />
            <TagsModal />
            <AdSenseAdsDataModal />
        </div>
    );
};

export default ReportDashboard;
