import React, { useState, useContext } from 'react';
import { Modal, Tabs, Table, Radio, Space, Row } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import { changeModalVisibility, fetchPerCountryData, reset } from 'store/searchCampaignStatistics/actionCreator';
import getMetricCellValues from 'util/getMetricCellValues';
import { stringSorter, numberSorter } from 'util/tables';

const Last7DaysDataTable = (props) => {
    const { campaignView } = props;

    const { last7DaysDataLoading: loading, last7DaysData: data } = useSelector(
        (state) => state.searchCampaignStatistics
    );

    const getNameColumn = (campaignView) => {
        if (campaignView) {
            return {
                title: 'Campaign Name',
                dataIndex: 'campaignName',
                key: 'campaignName',
                fixed: 'left',
                width: 180,
            };
        }

        return {
            title: 'Adset Name',
            dataIndex: 'adsetName',
            key: 'adsetName',
            fixed: 'left',
            width: 300,
        };
    };

    const columns = [
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            fixed: 'left',
            width: 120,
        },
        getNameColumn(campaignView),
        {
            title: 'ActClicks',
            dataIndex: 'exploradsClicks',
            key: 'exploradsClicks',
            width: 100,
            sorter: (a, b) => numberSorter('exploradsClicks')(a, b),
        },
        {
            title: 'Cost',
            dataIndex: 'actualSpend',
            key: 'cost',
            width: 100,
            render: (value) => {
                return (
                    <span>
                        {getMetricCellValues(value, { prefix: '€', postfix: '', precision: 1, isNumber: true }).value}
                    </span>
                );
            },
        },
        {
            title: 'Revenue',
            dataIndex: 'actualRevenue',
            key: 'revenue',
            width: 100,
            render: (value) => {
                return (
                    <span>
                        {getMetricCellValues(value, { prefix: '€', postfix: '', precision: 1, isNumber: true }).value}
                    </span>
                );
            },
        },
        {
            title: 'Profit',
            dataIndex: 'actualProfit',
            key: 'profit',
            width: 100,
            render: (value) => {
                return (
                    <span>
                        {getMetricCellValues(value, { prefix: '€', postfix: '', precision: 1, isNumber: true }).value}
                    </span>
                );
            },
        },
        {
            title: 'CPR',
            dataIndex: 'cpr',
            key: 'cpr',
            width: 100,
            render: (value) => {
                return (
                    <span>
                        {getMetricCellValues(value, { prefix: '€', postfix: '', precision: 2, isNumber: true }).value}
                    </span>
                );
            },
        },
        {
            title: 'RPC',
            dataIndex: 'rpc',
            key: 'rpc',
            width: 100,
            render: (value) => {
                return (
                    <span>
                        {getMetricCellValues(value, { prefix: '€', postfix: '', precision: 2, isNumber: true }).value}
                    </span>
                );
            },
        },
        {
            title: 'ROI',
            dataIndex: 'actualRoi',
            key: 'actualRoi',
            width: 100,
            render: (value) => {
                return (
                    <span>
                        {getMetricCellValues(value, { prefix: '', postfix: '%', precision: 1, isNumber: true }).value}
                    </span>
                );
            },
        },
    ];

    return (
        <Table
            rowKey={(row) => `${row.adsetName}-${row.date}`}
            loading={loading}
            columns={columns}
            dataSource={data}
            pagination={false}
            scroll={{ x: 1300 }}
        />
    );
};

const PerCountryDataTable = () => {
    const dispatch = useDispatch();
    const { perCountryDataLoading: loading, perCountryData: data } = useSelector(
        (state) => state.searchCampaignStatistics
    );

    const [selectedPreset, setSelectedPreset] = useState('Last 7 Days');

    const onPresetChange = (e) => {
        const newPreset = e.target.value;
        setSelectedPreset(newPreset);

        dispatch(fetchPerCountryData(newPreset));
    };

    const columns = [
        {
            title: 'Campaign Name',
            dataIndex: 'campaignName',
            key: 'campaignName',
            fixed: 'left',
            width: 180,
        },
        {
            title: 'Country',
            dataIndex: 'country',
            key: 'country',
            fixed: 'left',
            width: 120,
            sorter: (a, b) => stringSorter('country')(a, b),
            render: (value) => {
                return value.toUpperCase();
            },
        },
        {
            title: 'Conversions',
            dataIndex: 'conversions',
            key: 'conversions',
            width: 100,
            sorter: (a, b) => numberSorter('conversions')(a, b),
        },
        {
            title: 'Cost',
            dataIndex: 'actualSpend',
            key: 'cost',
            width: 100,
            sorter: (a, b) => numberSorter('actualSpend')(a, b),
            render: (value) => {
                return (
                    <span>
                        {getMetricCellValues(value, { prefix: '€', postfix: '', precision: 1, isNumber: true }).value}
                    </span>
                );
            },
        },
        {
            title: 'Revenue',
            dataIndex: 'actualRevenue',
            key: 'revenue',
            width: 100,
            sorter: (a, b) => numberSorter('actualRevenue')(a, b),
            render: (value) => {
                return (
                    <span>
                        {getMetricCellValues(value, { prefix: '€', postfix: '', precision: 1, isNumber: true }).value}
                    </span>
                );
            },
        },
        {
            title: 'Profit',
            dataIndex: 'actualProfit',
            key: 'profit',
            width: 100,
            sorter: (a, b) => numberSorter('actualProfit')(a, b),
            render: (value) => {
                return (
                    <span>
                        {getMetricCellValues(value, { prefix: '€', postfix: '', precision: 1, isNumber: true }).value}
                    </span>
                );
            },
        },
        {
            title: 'CPR',
            dataIndex: 'cpr',
            key: 'cpr',
            width: 100,
            sorter: (a, b) => numberSorter('cpr')(a, b),
            render: (value) => {
                return (
                    <span>
                        {getMetricCellValues(value, { prefix: '€', postfix: '', precision: 2, isNumber: true }).value}
                    </span>
                );
            },
        },
        {
            title: 'RPC',
            dataIndex: 'rpc',
            key: 'rpc',
            width: 100,
            sorter: (a, b) => numberSorter('rpc')(a, b),
            render: (value) => {
                return (
                    <span>
                        {getMetricCellValues(value, { prefix: '€', postfix: '', precision: 2, isNumber: true }).value}
                    </span>
                );
            },
        },
        {
            title: 'ROI',
            dataIndex: 'roi',
            key: 'roi',
            width: 100,
            sorter: (a, b) => numberSorter('roi')(a, b),
            render: (value) => {
                return (
                    <span>
                        {getMetricCellValues(value, { prefix: '', postfix: '%', precision: 1, isNumber: true }).value}
                    </span>
                );
            },
        },
    ];

    return (
        <Space direction="vertical">
            <Radio.Group value={selectedPreset} onChange={onPresetChange}>
                <Radio.Button value="Yesterday">Yesterday</Radio.Button>
                <Radio.Button value="Last 3 Days">Last 3 Days</Radio.Button>
                <Radio.Button value="Last 7 Days">Last 7 Days</Radio.Button>
                <Radio.Button value="Month to Date">Month to Date</Radio.Button>
                <Radio.Button value="Last Month">Last Month</Radio.Button>
            </Radio.Group>
            <Table
                loading={loading}
                columns={columns}
                dataSource={_.orderBy(data || [], 'actualSpend', 'desc')}
                pagination={false}
                scroll={{ x: 1300 }}
            />
        </Space>
    );
};

const SearchCampaignStatisticsModal = () => {
    const dispatch = useDispatch();
    const visible = useSelector((state) => state.searchCampaignStatistics.modalVisible);

    const { selectedCampaignId, selectedAdsetId } = useSelector((state) => state.searchCampaignStatistics);

    const isCampaignView = !!selectedCampaignId;

    const onClose = () => {
        dispatch(changeModalVisibility());
        dispatch(reset());
    };

    return (
        <Modal
            onCancel={onClose}
            maskClosable
            visible={visible}
            footer={false}
            title={`Search ${isCampaignView ? 'Campaign' : 'Adset'} Statistics`}
            width={'85vw'}
        >
            <Tabs defaultActiveKey={'item-1'}>
                <Tabs.TabPane tab="Last 7 days data" key="item-1">
                    <Last7DaysDataTable campaignView={isCampaignView} />
                </Tabs.TabPane>
                {isCampaignView && (
                    <Tabs.TabPane tab="Per country data" key="item-2">
                        <PerCountryDataTable />
                    </Tabs.TabPane>
                )}
            </Tabs>
        </Modal>
    );
};

export default SearchCampaignStatisticsModal;
